import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { rgb } from 'pdf-lib';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';

@Component({
  selector: 'app-page8-drive-certification',
  templateUrl: './page8-drive-certification.component.html',
  styleUrls: ['../shared.scss', './page8-drive-certification.component.scss']
})
export class Page8DriveCertificationComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() driverLicenseNoCtrl: FormControl;
  @Input() expiryDateCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;
  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }
  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Always create a new page for Page 8 content
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(newPage, position, formValues, fonts, formValues.currentPageCount);
    }

    let currentY = position.headerOffset || pageHeight - margin;
    let currentPage = newPage;

    const maxWidth = pageWidth - 2 * margin;

    // Helper function to check if we need a new page
    const checkPageBreak = (requiredSpace: number = margin * 2) => {
      if (currentY < requiredSpace) {
        // Create a new page when we're close to the bottom
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);

        // Add header to the new page if the function is available
        if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
          formValues.currentPageCount = (formValues.currentPageCount || 0) + 1;
          formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
        }

        // Use headerOffset if provided, otherwise default to page top
        currentY = position.headerOffset || pageHeight - margin;

        return true;
      }
      return false;
    };

    //title
    const titleText1 = "MOTOR VEHICLE DRIVER'S CERTIFICATION OF";
    const titleText2 = 'COMPLIANCE WITH DRIVER LICENSE REQUIREMENTS';

    const titleWidth1 = fonts.bold.widthOfTextAtSize(titleText1, 14);
    const titleWidth2 = fonts.bold.widthOfTextAtSize(titleText2, 14);

    const centerX1 = x + (maxWidth - titleWidth1) / 2;
    const centerX2 = x + (maxWidth - titleWidth2) / 2;

    currentPage.drawText(titleText1, {
      x: centerX1,
      y: currentY,
      size: 14,
      font: fonts.bold
    });

    currentY -= 20;

    currentPage.drawText(titleText2, {
      x: centerX2,
      y: currentY,
      size: 14,
      font: fonts.bold
    });

    currentY -= 30;

    // Paragraph 1
    const paragraph1 =
      'MOTOR CARRIER INSTRUCTIONS: The requirements in Part 383 apply to every driver who operates in intrastate, ' +
      'interstate, or foreign commerce and operates a vehicle weighing 26,001 pounds or more, can transport more than ' +
      '15 people, or transports hazardous materials that require placarding.';

    currentY = this.drawWrappedText(currentPage, paragraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 15;

    // Check if we need a page break
    checkPageBreak();

    // Paragraph 2
    const paragraph2 =
      'The requirements in Part 391 apply to every driver who operates in interstate commerce and operates a vehicle ' +
      'weighing 10,001 pounds or more, can transport more than 15 people, or transports hazardous materials that ' +
      'require placarding.';

    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 15;

    // Check if we need a page break
    checkPageBreak();

    // Paragraph 3
    const paragraph3 =
      'DRIVER REQUIREMENTS: Parts 383 and 391 of the Federal Motor Carrier Safety Regulations contain some requirements ' +
      'that you as a driver must comply with. These requirements are in effect as of July 1, 1987. They are as follows:';

    currentY = this.drawWrappedText(currentPage, paragraph3, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 15;

    // Check if we need a page break
    checkPageBreak();

    // List item 1
    currentPage.drawText('1.', {
      x: x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    const listItem1Title = 'POSSESS ONLY ONE LICENSE:';
    currentPage.drawText(listItem1Title, {
      x: x + 20,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    const listItem1Text =
      'You, as a commercial vehicle driver, may not possess more than one ' +
      `motor vehicle operator's license. If you have more than one license, keep the license from your state of ` +
      'residence and return the additional licenses to the states that issued them. DESTROYING a license does not ' +
      'close the record in the state that issued it; you must notify the state. If a multiple license has been lost, ' +
      'stolen, or destroyed, close your record by notifying the state of issuance that you no longer want to be ' +
      'licensed by that state.';

    currentY = this.drawWrappedText(currentPage, listItem1Text, x + 20, currentY - 15, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - 20
    });
    currentY -= 15;

    // Check if we need a page break - need more space for next section
    checkPageBreak(margin * 2 + 100);

    // List item 2
    currentPage.drawText('2.', {
      x: x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    const listItem2Title = 'NOTIFICATION OF LICENSE SUSPENSION, REVOCATION OR CANCELLATION:';
    currentPage.drawText(listItem2Title, {
      x: x + 20,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    const listItem2Text =
      'Sections 391.15(b)(2) and ' +
      '383.33 of the Federal Motor Carrier Safety Regulations require that you notify your employer the NEXT BUSINESS ' +
      `DAY of any revocation or suspension of your driver's license. In addition, Section 383.31 requires that any ` +
      'time you violate a state or local traffic law (other than parking), you must report it within 30 days to: 1) ' +
      'your employing motor carrier, and 2) the state that issued your license (If the violation occurs in a state ' +
      'other than the one which issued your license). The notification to both the employer and state must be in ' +
      'writing.';

    currentY = this.drawWrappedText(currentPage, listItem2Text, x + 20, currentY - 15, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - 20
    });
    currentY -= 20;

    // Check if we need a page break - need more space for form fields
    checkPageBreak(margin * 2 + 150);

    // License statement
    const licenseText = 'The following license is the only one I will possess: -';
    currentY = this.drawWrappedText(currentPage, licenseText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw License Number field
    currentPage.drawText("Driver's License No.:", {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverLicenseNo || '', {
      x: x + 150,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x: x + 150, y: currentY - 3 },
      end: { x: x + 350, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 30;

    // Check if we need a page break
    checkPageBreak();

    // Draw Expiry Date field
    currentPage.drawText('Expiry Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.expiryDate || '', {
      x: x + 150,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x: x + 150, y: currentY - 3 },
      end: { x: x + 350, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 30;

    // Check if we need a page break - need more space for signature
    checkPageBreak(margin * 2 + 100);

    // Certification statement
    const certText = 'DRIVER CERTIFICATION: I certify that I have read and understood the above requirements.';
    currentY = this.drawWrappedText(currentPage, certText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw Driver Name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 100,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x: x + 100, y: currentY - 3 },
      end: { x: x + 350, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 30;

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage8');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

<h2 id="drug-alcohol">New Employee's Drug and Alcohol Statement</h2>
<p class="italic">
  In accordance with 49 CFR 40.25 (j), as the employer, you must ask any prospective employee, whether he or she has
  tested positive, or refused to test, on any pre-employment drug or alcohol test administered by an employer to which
  the employee applied for, but did not obtain, safety-sensitive transportation work covered by DOT agency drug and
  alcohol testing rules during the past two years.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Prospective Employer Name *</mat-label>
    <input matInput [formControl]="prospectiveEmployerNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Address</mat-label>
    <input matInput [formControl]="prospectiveEmployerAddressCtrl" />
  </mat-form-field>
  <p>
    Have you tested positive, or refused to test, including any preemployment drug or alcohol test administrated by an
    employer to which you applied for, but did not obtain, safety sensitive transportation work covered by DOT agency
    drug and alcohol testing rules during the past two years?
  </p>
  <mat-radio-group
    [formControl]="drugAlcoholAnswerCtrl"
    required
    class="radio-group"
    [ngClass]="{ error: drugAlcoholAnswerCtrl.hasError('required') }"
  >
    <mat-radio-button value="YES">YES</mat-radio-button>
    <mat-radio-button value="NO">NO</mat-radio-button>
  </mat-radio-group>
</div>
<div>
  <button type="button" mat-raised-button class="btn-next" (click)="moveToDriverRequirement()">Next</button>
</div>
<div *ngIf="drugAlcoholAnswerCtrl.value === 'YES'">
  <mat-form-field appearance="outline" class="full-width error">
    <mat-label>Explanation</mat-label>
    <textarea
      matInput
      [formControl]="drugExplanation"
      maxlength="100"
      data-test="drugExplanation-form-field"
      matTextareaAutosize
      matAutosizeMinRows="6"
    ></textarea>
  </mat-form-field>
</div>
<p class="italic">
  If the employee admits that he or she had a positive test or refusal to test, you must not use the employee to perform
  safety-sensitive functions for you, until and unless the employee documents successful completion of the
  return-to-duty process (see 40.25(b)(5) and 40.25(e). [The return-to-duty process is outlined in Subpart O of Part 40.
</p>
<div class="form-group">
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 7 of 30</p>

<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div>
    <div class="header-navigation-back">
      <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title">Accident Records</span>
    </div>
    <div *ngIf="!isContentReady">
      <app-skeleton-accident-traffic-records [isAccidentRecord]="true"></app-skeleton-accident-traffic-records>
    </div>
    <div *ngIf="isContentReady">
      <div>
        <form
          [formGroup]="noAccidentRecordsForm"
          [ngClass]="{ disabled: disableNoAccidents }"
          (ngSubmit)="onSubmitAccidentRecord('noAccident')"
          fxLayout="column"
          class="no-accidents-container"
        >
          <span>I have no accident in the last <strong>3 years</strong></span>
          <mat-checkbox
            [disabled]="disableNoAccidents"
            formControlName="noAccidents"
            (change)="changeNoAccident()"
          ></mat-checkbox>
        </form>
      </div>

      <div fxLayout class="add-new-container">
        <button
          mat-button
          [disabled]="allowEdit || loading"
          (click)="onAddNewAccidentRecord()"
          data-test="add-accident-record-button"
        >
          <i class="material-icons">add</i> Add New Record
        </button>

        <div class="action-buttons" *ngIf="openAccidentForm">
          <button
            mat-button
            class="save-button"
            form="accidentForm"
            [disabled]="!(accidentForm.dirty && accidentForm.valid) || loading"
            data-test="save-new-record"
          >
            Save
          </button>
          <button mat-button class="cancel-button" (click)="onCancel()" [disabled]="loading">
            Cancel
          </button>
        </div>
      </div>

      <mat-error *ngIf="errorMessage" class="error padding-left-md">
        {{ errorMessage }}
      </mat-error>

      <div class="content" fxLayout="column">
        <div *ngIf="openAccidentForm" fxLayout="column" class="component-container">
          <form
            id="accidentForm"
            [formGroup]="accidentForm"
            fxLayout="column"
            (ngSubmit)="onSubmitAccidentRecord('addNewRecord')"
          >
            <mat-form-field>
              <input
                matInput
                readonly
                placeholder="Date"
                [matDatepicker]="accidentDate"
                formControlName="accidentDate"
                [max]="maxDate"
                (click)="accidentDate.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="accidentDate">
                <mat-icon matDatepickerToggleIcon data-test="from-date-form-field">calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #accidentDate touchUi="true"> </mat-datepicker>
              <mat-error *ngIf="accidentDate.touched && accidentDate.invalid">
                {{ getAccidentDateErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Nature of Accident</mat-label>
              <mat-select formControlName="natureOfAccident" data-test="nature-of-accident-selector">
                <mat-option
                  *ngFor="let accident of accidentNatures"
                  [value]="accident.value"
                  data-test="accident-value"
                  >{{ accident.name }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="natureOfAccident.touched && natureOfAccident.invalid">
                {{ getNatureOfAccidentErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Number of Fatalities</mat-label>
              <mat-select formControlName="numOfFatalities" data-test="number-of-fatalities-selector">
                <mat-option *ngFor="let num of numInvolved" value="{{ num }}" data-test="fatalities-value">{{
                  num
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="numOfFatalities.touched && numOfFatalities.invalid">
                {{ getNumOfFatalitiesErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Number of Injuries</mat-label>
              <mat-select formControlName="numOfInjuries" data-test="number-of-injuries-selector">
                <mat-option *ngFor="let num of numInvolved" value="{{ num }}" data-test="injuries-value">{{
                  num
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="numOfInjuries.touched && numOfInjuries.invalid">
                {{ getNumOfInjuriesErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Chemical Spills</mat-label>
              <mat-select formControlName="chemicalsSpilt" data-test="chemicals-spilt-selector">
                <mat-option value="false" data-test="no-button">No</mat-option>
                <mat-option value="true">Yes</mat-option>
              </mat-select>
              <mat-error *ngIf="chemicalsSpilt.touched && chemicalsSpilt.invalid">
                {{ getChemicalsSpiltErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Notes (Optional)</mat-label>
              <textarea matInput formControlName="notes" maxlength="140" placeholder="Enter any additional notes"
                data-test="notes-field"></textarea>
              <mat-hint align="end">{{ accidentForm.get('notes').value?.length || 0 }}/140</mat-hint>
              <mat-error *ngIf="accidentForm.get('notes').hasError('pattern')">
                Invalid characters used.
              </mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="accident-record-history">
          <app-accident-view
            *ngFor="let accident of accidentRecords"
            [model]="accident"
            (updateAccidentPressed)="onUpdateAccidentPressed($event)"
            (deleteAccidentPressed)="onDeleteAccidentPressed($event)"
            class="app-accident-view"
          >
          </app-accident-view>
        </div>
      </div>
    </div>
  </div>
</div>

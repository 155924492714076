import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileOutletComponent } from './profile-outlet/profile-outlet.component';
import { ProfileUnderReviewComponent } from './profile-under-review/profile-under-review.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfileLicenseImageViewComponent } from './profile-license-image-view/profile-license-image-view.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ImageCompressModule } from '../shared/utils/image-compress.module';
import { ToastModule } from '../shared/toast/toast.module';

@NgModule({
  declarations: [ProfileOutletComponent, ProfileUnderReviewComponent, ProfileLicenseImageViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ImageCropperModule,
    MatButtonModule,
    MatMenuModule,
    ImageCompressModule,
    ToastModule
  ]
})
export class ProfileModule {}

<h2>Declaration of License Status Change</h2>
<p class="mb-1">
  The Ontario Government has implemented Bill 138, which includes a provision that allows the impoundment of a vehicle
  if the driver is found to be driving while his or her license is under suspension for a Canada Criminal Code
  conviction. This impoundment provision applies even if the driver is not the owner of the vehicle.
</p>
<p class="mb-1">
  The person caught driving while under suspension will face fines of $5000.00 to $50,000.00 (10 times the current
  rate). In addition, the vehicle being operated at the time will be impounded for 45 to 180 days.
</p>
<p class="mb-1">
  Because of this provision, we are asking that all drivers sign the declaration below and return this to the safety
  department A.S.A.P.
</p>
<p>
  I,
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  shall provide notification immediately to
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  of any violations, citations or offense notices and suspensions or changes to my driver's license status. I have also
  read the information above and understand the possible consequences, for myself as a driver and for
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required /> </mat-form-field
  >, as the said operator of the vehicle, if I am found to be driving while my driver's license is under suspension.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 21 of 30</p>

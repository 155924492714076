<h2>Reefer Policy</h2>
<p><strong>Purpose:</strong></p>
<p>
  To reduce claims related to temperature sensitive freight and provide guidance to drivers who transport refrigerated
  products.
</p>
<p><strong>Responsibilities:</strong></p>
<p>
  It is the responsibility of the Company management to enforce and maintain this policy. It is the responsibility of
  the Company drivers to follow these procedures thus reducing the potential for cargo claims.
</p>
<p><strong>Policy and Procedures:</strong></p>
<p>
  When the Company dispatch accepts a refrigerated load, they must inform the driver if they are picking up and empty
  trailer to load live load or a preloaded trailer. Dispatch must also include the set point temperature in their
  dispatch instructions to the driver(s).
</p>
<p><strong>Live Loading:</strong></p>
<p>When a driver picks up an empty trailer for live load he/she must:</p>
<ul>
  <li>
    Conduct a pre-trip inspection on the trailer or reefer.
    <ol>
      <li>The reefer display will have a button for conducting a pre-trip inspection.</li>
      <li>
        Once the pre-trip is activated it will run through a series of checks to confirm the reefer is working properly.
      </li>
      <li>
        The driver must also check the oil, antifreeze, belts or hoses to ensure fluid levels are good and there is no
        cuts or holes in the belts or hoses.
      </li>
      <li>If there is a reefer fuel tank this needs to be checked and fuel added if below ¾ full.</li>
    </ol>
  </li>
</ul>
<p>
  A reefer pre-trip must be completed every day. Any "reefer codes" will show on the reefer display screen. The driver
  must write the codes down and report them to the Company dispatch immediately.
</p>
<ul>
  <li>Make sure the trailer is clean and free of any debris or odor/smell.</li>
  <li>If dirty the driver must sweep or wash the trailer.</li>
  <li>
    If the trailer has an odor/smell it will often be refused for loading by the shipper so the trailer must be washed.
    Washing however, does not always remove the odor/smell.
  </li>
  <li>
    If the odor persists coffee works very well to remove odors.
    <ol>
      <li>Sprinkle a generous amount of coffee directly onto the metal floor of the reefer trailer.</li>
      <li>Close the trailer doors and leave for at least few hours.</li>
      <li>
        Open the trailer doors and check if the odor remains If it does, then leave the coffee in the trailer for
        longer.
      </li>
      <li>
        Once the odor is no longer present in the trailer can be swept or washed to remove all traces of coffee.
      </li>
      <li>
        Keep in mind coffee has a strong odor as well so you must regularly check the trailer to determine if the
        original odor is gone. If coffee is left too long the trailer will now smell like coffee and can be refused for
        loading.
      </li>
    </ol>
  </li>
</ul>
<p>
  If the temperature inside the trailer is a lot higher or lower than the "set point" temperature the trailer must be
  cooled and warmed before loading any product. If product is loaded onto a hot trailer the reefer will not be able to
  cool down the freight properly and it will often result in a claim. If loaded onto a trailer that is too cold, it then
  damage or freeze the product.
</p>
<p>
  Once at the shipper turn off the reefer if not backed into a sealed and temperature-controlled warehouse. If the
  trailer is backed into the sealed door and temperature-controlled warehouse the trailer must be left on.
</p>
<p>
  When loading products such as produced the product should be probed with the temperature probe or laser gun to confirm
  what the loading temperature of the product is. This should be done while loading, whenever possible, and at least 3
  times during the loading process. If the probe temperature is higher or lower than the "set point" temperature the
  Company dispatch must be called. Probe temperatures must be noted on the shipper BOL (bill of lading).
</p>
<p>
  Many shippers use a "template" which is a recorder put in the trailer after loading to record the temperature of the
  load while in transit. Upon delivery the customer will download the template information. If the reefer "set point"
  has not been maintained the load can be refused.
</p>
<p>
  Once loaded, ALL shipping documents must be reviewed to confirm what temperature the reefer "set point" is. Make sure
  you check if the temperature is to be in Celsius "C" or Fahrenheit "F". Also check what reefer cycle needs to be set –
  "continuous" or "cycle sentry". Continuous cycle the reefer does not usually stop because the freight is very
  temperature sensitive and the "set point" must be maintained. On Cycle sentry the reefer will stop and then restart
  once the temperature goes either too high or too low from the "set point". If driver is not sure check will the
  shipper and then they must call the Company dispatch for confirmation.
</p>
<p>
  <strong>
    Once the driver is loaded and paperwork is checked a picture of the reefer temperature on the display screen must be
    taken and sent to the Company dispatch. At least once per day, except while off duty/sleeping, a picture of the
    reefer temperature must be sent to the Company dispatch. Failure to follow these procedures will result in
    disciplinary action per the company policy.
  </strong>
</p>
<p>
  Once the driver has arrived at the customer if any cargo issues are reported verbally or on the BOL the driver must
  stay at the customer's yard and call the Company dispatch immediately.
</p>
<p>
  The procedures are the same and when picking up a pre-loaded trailer except sweeping, washing and precooling/warming
  the trailer. If the trailer is pre-sealed, then probing of the product would not be possible.
</p>
<p>
  Failure to follow this policy and the procedures will result in disciplinary action per our company policy. When in
  doubt check with dispatch for clarification and/or instructions.
</p>
<p>
  <strong
    >Driver needs to contact dispatch when there are any temperature discrepancies, cargo claims, the reefer stops
    working or any other issues that will affect the temperature-controlled freight.</strong
  >
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>

<div fxLayout="column" class="main-container">
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <div class="title margin-top-lg nargin-bottom-lg">
    License Upload
  </div>
  <div class="options margin-top-md margin-left-sm">
    <div class="margin-bottom-sm">
      <button mat-button (click)="navigateToLicenseScan()">
        <mat-icon svgIcon="image-upload"></mat-icon>
        <span>Upload Image For Data Extraction</span>
      </button>
    </div>
    <!-- <div class="margin-bottom-sm">
      <button mat-button (click)="navigateToLicenseFileUpload()">
        <mat-icon svgIcon="icon-file-blue"></mat-icon>
        <span>Upload File</span>
      </button>
    </div> -->
  </div>
  <span class="center margin-top-sm margin-bottom-sm disclaimer-text"
    >Data extraction will be performed on the JPEG, JPG and PNG document types.</span
  >
</div>

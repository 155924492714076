<h2>Safe Driving Policy</h2>
<p>
  Driver's Name:
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
</p>
<p class="mt-1 mb-1">
  By signing below, I hereby acknowledge to establish and maintain a standard for safe driving. I am responsible for
  operating any vehicle in a safe manner and to always comply with all Federal and Provincial requirements ie: all
  posted <span class="underline">speed signs</span> and <span class="underline">seat belt regulations</span>. I will
  also follow the <span class="underline">Hours-of-Service Rules and not use any Hand-held instrument</span>.
</p>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  is responsible to record all accidents and is the property of the assigned insurance company at that time.
</p>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  will establish remedial training, corrective action, and discipline procedures for drivers involved in accidents. This
  will be at the driver's expense.
</p>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  also acknowledges safe driving behavior on a yearly basis.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 23 of 30</p>

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page16-vehicle-maintenance',
  templateUrl: './page16-vehicle-maintenance.component.html',
  styleUrls: ['../shared.scss', './page16-vehicle-maintenance.component.scss']
})
export class Page16VehicleMaintenanceComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Vehicle Maintenance';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 14);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 14,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph
    const paragraph1 =
      'All of our vehicles are operated under a full maintenance program. Any malfunctions or safety concerns should ' +
      'always be reported to a supervisor and noted on a Vehicle Inspection Report (VIR) to ensure repairs are made.';

    currentY = this.drawWrappedText(currentPage, paragraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 15;

    // Draw first set of bullet points
    const bullets1 = [
      'Part of your daily routine includes a pre-trip and post-trip inspection of your vehicle using a company ' +
        'provided vehicle inspection report. Turn in a copy of the vehicle inspection report at the end of your shift.',
      'Keep your cab clean. Company will provide cleaning supplies such as paper towels, spray cleaner, and whisk ' +
        'brooms to help maintain the condition of the cab and create a safe workplace.'
    ];

    // Draw each bullet point
    for (const bullet of bullets1) {
      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 11,
        font: fonts.regular
      });

      // Draw bullet point text with indentation
      currentY = this.drawWrappedText(currentPage, bullet, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 11,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 10; // Space between items
    }

    // Draw second paragraph
    const paragraph2 = 'An inspection report must be completed and it must include';

    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 10;

    // Draw second set of bullet points
    const bullets2 = [
      'vehicle license number and jurisdiction',
      'motor carrier name',
      'inspection date, time and location',
      'inspection declaration (statement by the driver that the vehicle was inspected in accordance with the Regulation)',
      'name and signature of the person who conducted the inspection',
      'odometer reading',
      'driver signature.'
    ];

    // Draw each bullet point
    for (const bullet of bullets2) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 11,
        font: fonts.regular
      });

      // Draw bullet point text with indentation
      currentY = this.drawWrappedText(currentPage, bullet, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 11,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 10;
    }

    // Draw third paragraph
    const paragraph3 =
      'The inspection report must show when there are no defects and any minor or major defects must be recorded on the ' +
      'inspection report. When there are major defects, the vehicle cannot be operated. Minor defects must be repaired ' +
      'as soon as possible but do not prohibit use of a vehicle. The inspection report and the schedule must be carried ' +
      'by the driver. The ';

    currentY = this.drawWrappedText(currentPage, paragraph3, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });

    // Draw "inspection is valid for 24 hours" in bold
    const boldText = 'inspection is valid for 24 hours';
    const boldTextWidth = fonts.bold.widthOfTextAtSize(boldText, 12);

    currentPage.drawText(boldText, {
      x,
      y: currentY,
      size: 11,
      font: fonts.bold
    });

    // Continue with rest of paragraph
    const paragraph3End = '. Driver must monitor the vehicle while operating it and look for defects.';

    currentY = this.drawWrappedText(currentPage, paragraph3End, x + boldTextWidth, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth: maxWidth - boldTextWidth
    });
    currentY -= 20;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 11,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 11,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 35;

    const signature = formValues.signatures?.get('driverSignaturePage16');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 11, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

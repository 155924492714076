import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page25-seat-belt-policy',
  templateUrl: './page25-seat-belt-policy.component.html',
  styleUrls: ['../shared.scss', './page25-seat-belt-policy.component.scss']
})
export class Page25SeatBeltPolicyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Seat Belt Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw Introduction section
    // Add "Introduction:" in bold
    currentPage.drawText('Introduction:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the bold text
    const introWidth = fonts.bold.widthOfTextAtSize('Introduction: ', 12);

    // Add first part of text
    const intro1 = 'Because seat belts are the best protection against injury or death in a crash, ';
    currentPage.drawText(intro1, {
      x: x + introWidth,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Move to next line
    currentY -= 16;

    // Add company name at beginning of line
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of company name
    const companyWidthIntro = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Continue with next part of intro paragraph
    const intro2 =
      ' seat belt use policy is extremely important. The simple act of buckling up reduces the chance of serious injury or death by 40% to 60% and can save money for ';

    currentY = this.drawWrappedText(currentPage, intro2, x + companyWidthIntro, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyWidthIntro
    });

    // Add company name again on a new line
    currentPage.drawText(companyName + '.', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 20;

    // Draw Policy section (first occurrence)
    // Add "Policy:" in bold
    currentPage.drawText('Policy:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line
    currentY -= 16;

    // Add company name at beginning of new line
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of company name
    const companyWidthPolicy = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Continue with rest of paragraph
    const policy1 =
      ' recognizes that seat belts are extremely effective in preventing injuries and loss of life. We care about our employees, and want to make sure that no one is injured or killed in a tragedy that could have been prevented by the use of seat belts. Employees are strongly encouraged to use their seat belts off the job as well. The purpose of this policy is to establish mandatory belt use as an organizational priority and designate responsibility for implementation and enforcement.';

    currentY = this.drawWrappedText(currentPage, policy1, x + companyWidthPolicy, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyWidthPolicy
    });

    currentY -= 20;

    // Draw Policy section (second occurrence)
    // Add "Policy:" in bold
    currentPage.drawText('Policy:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the "Policy: " text
    const policyLabelWidth = fonts.bold.widthOfTextAtSize('Policy: ', 12);

    // Continue with paragraph
    const policy2 =
      'The seat belt use policy applies to all employees and occupants of any vehicle driven by employees, including rentals and personal vehicles when used on official company business.';

    currentY = this.drawWrappedText(currentPage, policy2, x + policyLabelWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - policyLabelWidth
    });

    currentY -= 20;

    // Draw Responsibility section
    // Add "Responsibility:" in bold
    currentPage.drawText('Responsibility:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Add responsibility paragraph
    const responsibility =
      'Managers and supervisors must demonstrate their commitment to this policy by communicating it to their employees, monitoring compliance, evaluating effectiveness and taking disciplinary action against violations.';

    currentY = this.drawWrappedText(currentPage, responsibility, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Draw Belt Systems Maintenance section
    // Add "Belt Systems Maintenance:" in bold
    currentPage.drawText('Belt Systems Maintenance:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Add maintenance paragraph
    const maintenanceStart = 'Seat belts in all ';
    currentPage.drawText(maintenanceStart, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of maintenanceStart
    const maintenanceStartWidth = fonts.regular.widthOfTextAtSize(maintenanceStart, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: x + maintenanceStartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for the rest of maintenance paragraph
    currentY -= 16;

    // Add rest of maintenance paragraph on a new line
    const maintenanceEnd = 'vehicles are to be maintained so that they are clean and in good working order.';

    currentY = this.drawWrappedText(currentPage, maintenanceEnd, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Check if we need a new page
    if (currentY < margin + 200) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw Employee Education section
    // Add "Employee Education:" in bold
    currentPage.drawText('Employee Education:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Add education paragraph - first part
    const educationStart = 'Information on the benefits of seat belts, as well as ';
    currentPage.drawText(educationStart, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of educationStart
    const educationStartWidth = fonts.regular.widthOfTextAtSize(educationStart, 12);

    // Add company name
    currentPage.drawText(companyName, {
      x: x + educationStartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line for the remainder of the text to avoid right alignment issues
    currentY -= 16;

    // Add rest of education paragraph on a new line
    const educationEnd =
      'commitment to their use will be emphasized in new employee orientation, training, handbooks, safety rules and internal communications. Employees will be required to sign a pledge to use seat belts as a condition of employment. Managers and supervisors are encouraged to promote and provide time for employee awareness programs to explain the benefits of seat belts both on- and off-the-job.';

    currentY = this.drawWrappedText(currentPage, educationEnd, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth
    });

    currentY -= 20;

    // Draw Enforcement section - REVISED TO FIX TEXT CUTOFF
    // Add "Enforcement:" in bold
    currentPage.drawText('Enforcement:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Instead of trying to place the text on a single line, use the drawWrappedText method
    // which will automatically handle line wrapping
    const enforcementText =
      'Seat belt use is such a common, healthy practice that the need to actively enforce this policy should be rare. However, the policy will be enforced the same as other ' +
      companyName +
      ' and employees who violate it will be subject to disciplinary actions which may include dismissal. Managers and supervisors have an obligation to encourage and routinely monitor usage. The driver of the vehicle is responsible for enforcing seat belt use by all occupants.';

    currentY = this.drawWrappedText(currentPage, enforcementText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage25');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

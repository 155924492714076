import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page10-tolerance-policy',
  templateUrl: './page10-tolerance-policy.component.html',
  styleUrls: ['../shared.scss', './page10-tolerance-policy.component.scss']
})
export class Page10TolerancePolicyComponent implements OnInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  constructor() {}

  ngOnInit(): void {}

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Zero Tolerance & Disciplinary Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph with inline field
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the company name to position the rest of the text
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Continue with the paragraph text
    const paragraphText =
      ' promotes fair and constructive treatment of unacceptable conduct or work performance. This is accomplished in ' +
      'part through a progressive discipline process. The purpose of this policy is to explain when and how progressive ' +
      'discipline generally be applied. Progressive discipline process is carried out with the intent to improve ' +
      'behaviour.';

    currentY = this.drawWrappedText(currentPage, paragraphText, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });
    currentY -= 20;

    // List of zero tolerance items
    const zeroToleranceItems = [
      'Consumption of Drugs, Alcohol & Cannabis before coming to work & at work,',
      'Use of cell phone or any other electronic device while driving, loading, and unloading is prohibited, we can ' +
        'ask any time for the phone bill from you,',
      'Verbal abuse',
      'Display of aggression directed towards staff, co-workers, customers, or anyone who has a relation with ' +
        'company.',
      'Staff harassment, via phone, in person or email',
      'Breach of security, safety, or fraudulent actions',
      'Any actions which impede the health care or process of our office and our staff',
      'Violating civic manners or etiquettes in public place',
      'Depending on the circumstances, criminal charges may also be laid,',
      'Not wearing seat belt while driving and careless driving',
      'Not wearing approved personal protective equipment and clothing',
      'Moving violation such as speeding, fail to obey traffic control devices, follow too close to another vehicle,',
      'Log falsifications and not complying with HOS rules,',
      'Failed to produce required document to law enforcement officer and to the company,',
      'Failed to do proper pre-trip resulting OOS at scale and get ticket for it,',
      'Fail to report any incident, accident, violation ticket, any damage, OOS,',
      'Fail to report any argument and other incident at customer place,'
    ];

    // Draw each list item as a bullet point
    for (const item of zeroToleranceItems) {
      // Check if we need a new page for this item
      if (currentY < margin + 50) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw item text with indentation
      currentY = this.drawWrappedText(currentPage, item, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 8; // Reduced spacing between items
    }

    currentY -= 20;

    // Check if we need a new page for enforcement policy section
    if (currentY < margin + 250) {
      // Enforce policy section needs significant space
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw enforcement policy section
    const enforcementTitle = 'Enforcement Policy:';
    currentPage.drawText(enforcementTitle, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    const enforcementText =
      ' Any Driver found in serious violation may result in suspension or ' +
      'termination without any notice or by the following steps:';

    currentY = this.drawWrappedText(
      currentPage,
      enforcementText,
      x + fonts.bold.widthOfTextAtSize(enforcementTitle, 12),
      currentY,
      {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - fonts.bold.widthOfTextAtSize(enforcementTitle, 12)
      }
    );
    currentY -= 20;

    // Draw warning sections
    const warningTypes = [
      {
        title: 'VERBAL WARNING:',
        text:
          '- An employee will be given a verbal warning when they engage in problematic ' +
          'behaviour. As the first step in the progressive discipline policy, a verbal caution is meant to alert the ' +
          'employee that a problem may exist or that one has been identified, which must be addressed. Verbal warnings will ' +
          "be documented and maintained by on the employee's personnel file. A verbal caution remains in effect for three " +
          'months.'
      },
      {
        title: 'WRITTEN WARNING:',
        text:
          'A written warning is more serious than a verbal warning. A written warning ' +
          'will be given when an employee engages in conduct that justifies a written warning or the employee engages in ' +
          'unacceptable behavior during the period that a verbal warning is in effect. Written warnings are maintained in ' +
          "an employee's personnel file and remains in effect for three months."
      },
      {
        title: 'SUSPENSION:',
        text:
          'A suspension without pay is more serious than a written warning. An employee will ' +
          'be suspended when he or she engages in conduct that justifies a suspension or the employee engages in ' +
          "unacceptable behavior during the period that a written warning is in effect. An employee's suspension will be " +
          'documented and, regardless of the length of the suspension issued, will remain in effect for three months.'
      },
      {
        title: 'TERMINATION:',
        text:
          'An employee will be terminated when he or she engages in conduct that justifies ' +
          'termination or does not correct the matter that resulted in less sever discipline.'
      }
    ];

    for (const warning of warningTypes) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      currentPage.drawText(warning.title, {
        x,
        y: currentY,
        size: 12,
        font: fonts.bold
      });

      currentY = this.drawWrappedText(
        currentPage,
        warning.text,
        x + fonts.bold.widthOfTextAtSize(warning.title, 12) + 5,
        currentY,
        {
          font: fonts.regular,
          fontSize: 12,
          lineHeight: 16,
          maxWidth: maxWidth - fonts.bold.widthOfTextAtSize(warning.title, 12) - 5
        }
      );
      currentY -= 15;
    }

    // Final paragraph
    const finalParagraph =
      'While the Company will generally take disciplinary action in a progressive manner, it reserves the right, in its ' +
      'sole discretion, to decide whether and what disciplinary action will be taken in a given situation. Some forms ' +
      'of misconduct, including theft or fraud, violence or threats of violence, harassment or violations of the drug ' +
      'or alcohol policy will generally result in immediate dismissal for cause.';

    // Check if we need a new page
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    currentY = this.drawWrappedText(currentPage, finalParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 25;

    // Check if we need a new page for signature section
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

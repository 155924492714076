import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page12-driver-requirements',
  templateUrl: './page12-driver-requirements.component.html',
  styleUrls: ['../shared.scss', './page12-driver-requirements.component.scss']
})
export class Page12DriverRequirementsComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;

  @ViewChild('licensing1Canvas', { static: false }) licensing1Canvas: ElementRef;
  @ViewChild('licensing2Canvas', { static: false }) licensing2Canvas: ElementRef;
  @ViewChild('licensing3Canvas', { static: false }) licensing3Canvas: ElementRef;
  @ViewChild('hoursOfWork1Canvas', { static: false }) hoursOfWork1Canvas: ElementRef;
  @ViewChild('hoursOfWork2Canvas', { static: false }) hoursOfWork2Canvas: ElementRef;
  @ViewChild('hoursOfWork3Canvas', { static: false }) hoursOfWork3Canvas: ElementRef;
  @ViewChild('preTripInspection1Canvas', { static: false }) preTripInspection1Canvas: ElementRef;
  @ViewChild('loadSecurity1Canvas', { static: false }) loadSecurity1Canvas: ElementRef;

  licensing1Pad: SignaturePad;
  licensing2Pad: SignaturePad;
  licensing3Pad: SignaturePad;
  hoursOfWork1Pad: SignaturePad;
  hoursOfWork2Pad: SignaturePad;
  hoursOfWork3Pad: SignaturePad;
  preTripInspection1Pad: SignaturePad;
  loadSecurity1Pad: SignaturePad;

  @Output() insertAllInitials = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeSignaturePads();
    }, 500); // Short delay to ensure canvas is fully rendered
  }

  initializeSignaturePads() {
    // Initialize all checkbox signature pads
    if (this.licensing1Canvas) {
      this.licensing1Pad = new SignaturePad(this.licensing1Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.licensing2Canvas) {
      this.licensing2Pad = new SignaturePad(this.licensing2Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.licensing3Canvas) {
      this.licensing3Pad = new SignaturePad(this.licensing3Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.hoursOfWork1Canvas) {
      this.hoursOfWork1Pad = new SignaturePad(this.hoursOfWork1Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.hoursOfWork2Canvas) {
      this.hoursOfWork2Pad = new SignaturePad(this.hoursOfWork2Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.hoursOfWork3Canvas) {
      this.hoursOfWork3Pad = new SignaturePad(this.hoursOfWork3Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.preTripInspection1Canvas) {
      this.preTripInspection1Pad = new SignaturePad(this.preTripInspection1Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }

    if (this.loadSecurity1Canvas) {
      this.loadSecurity1Pad = new SignaturePad(this.loadSecurity1Canvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature(type: string) {
    switch (type) {
      case 'licensing1':
        this.licensing1Pad?.clear();
        break;
      case 'licensing2':
        this.licensing2Pad?.clear();
        break;
      case 'licensing3':
        this.licensing3Pad?.clear();
        break;
      case 'hoursOfWork1':
        this.hoursOfWork1Pad?.clear();
        break;
      case 'hoursOfWork2':
        this.hoursOfWork2Pad?.clear();
        break;
      case 'hoursOfWork3':
        this.hoursOfWork3Pad?.clear();
        break;
      case 'preTripInspection1':
        this.preTripInspection1Pad?.clear();
        break;
      case 'loadSecurity1':
        this.loadSecurity1Pad?.clear();
        break;
    }
  }
  insertInitialsToAll() {
    this.insertAllInitials.emit();
  }

  getCheckboxSignatureUrls(): Map<string, string> {
    const signatureMap = new Map<string, string>();

    if (this.licensing1Pad && !this.licensing1Pad.isEmpty()) {
      signatureMap.set('licensing1', this.licensing1Pad.toDataURL());
    }
    if (this.licensing2Pad && !this.licensing2Pad.isEmpty()) {
      signatureMap.set('licensing2', this.licensing2Pad.toDataURL());
    }
    if (this.licensing3Pad && !this.licensing3Pad.isEmpty()) {
      signatureMap.set('licensing3', this.licensing3Pad.toDataURL());
    }
    if (this.hoursOfWork1Pad && !this.hoursOfWork1Pad.isEmpty()) {
      signatureMap.set('hoursOfWork1', this.hoursOfWork1Pad.toDataURL());
    }
    if (this.hoursOfWork2Pad && !this.hoursOfWork2Pad.isEmpty()) {
      signatureMap.set('hoursOfWork2', this.hoursOfWork2Pad.toDataURL());
    }
    if (this.hoursOfWork3Pad && !this.hoursOfWork3Pad.isEmpty()) {
      signatureMap.set('hoursOfWork3', this.hoursOfWork3Pad.toDataURL());
    }
    if (this.preTripInspection1Pad && !this.preTripInspection1Pad.isEmpty()) {
      signatureMap.set('preTripInspection1', this.preTripInspection1Pad.toDataURL());
    }
    if (this.loadSecurity1Pad && !this.loadSecurity1Pad.isEmpty()) {
      signatureMap.set('loadSecurity1', this.loadSecurity1Pad.toDataURL());
    }

    return signatureMap;
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Driver Requirements Rules';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw intro paragraph
    const introParagraph =
      "In order to ensure safe operation of the company's fleet vehicles, all drivers must be aware of and comply with " +
      'all regulations governing their conduct.';

    currentY = this.drawWrappedText(currentPage, introParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Define sections and their items
    const sections = [
      {
        title: 'LICENSING',
        items: [
          {
            text: "I know that I must have a valid commercial driver's license.",
            signatureKey: 'licensing1'
          },
          {
            text: 'I agree to report all traffic violations to my employer in writing.',
            signatureKey: 'licensing2'
          },
          {
            text: 'I understand that I must not operate a vehicle while under the influence of drugs or alcohol.',
            signatureKey: 'licensing3'
          }
        ]
      },
      {
        title: 'HOURS OF WORK',
        items: [
          {
            text: 'I have been informed of and understand the hours of work regulations.',
            signatureKey: 'hoursOfWork1'
          },
          {
            text: 'I am aware that I must arrange my work schedule to comply with these regulations.',
            signatureKey: 'hoursOfWork2'
          },
          {
            text: 'I agree to submit a record of all on-duty hours accumulated while working for other operators.',
            signatureKey: 'hoursOfWork3'
          }
        ]
      },
      {
        title: 'PRE-TRIP INSPECTIONS',
        items: [
          {
            text: 'I am aware of the pre-trip inspection requirements and understand them.',
            signatureKey: 'preTripInspection1'
          }
        ]
      },
      {
        title: 'LOAD SECURITY',
        items: [
          {
            text:
              'I have been informed of and understand the load security regulations. (i.e. Ensure that the load is tarped as required).',
            signatureKey: 'loadSecurity1'
          }
        ]
      }
    ];

    // Process all checkbox signatures once at the beginning
    const checkboxSignatureImages = new Map<string, any>();
    if (formValues.checkboxSignatures) {
      for (const [key, dataUrl] of formValues.checkboxSignatures.entries()) {
        if (dataUrl) {
          try {
            // Extract base64 data from data URL
            const base64Data = dataUrl.split(',')[1];
            // Convert base64 to binary
            const signatureBytes = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
            // Embed as a PNG image
            const signatureImage = await pdfDoc.embedPng(signatureBytes);
            checkboxSignatureImages.set(key, signatureImage);
          } catch (error) {
            console.error(`Error converting signature for ${key}:`, error);
          }
        }
      }
    }

    // Draw each section
    for (const section of sections) {
      // Check if we need a new page for this section
      if (currentY < margin + 150) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw section title
      currentPage.drawText(section.title, {
        x,
        y: currentY,
        size: 12,
        font: fonts.bold
      });
      currentY -= 20;

      // Draw each item in the section
      for (const item of section.items) {
        // Check if we need a new page for this item
        if (currentY < margin + 100) {
          currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
          currentY = pageHeight - margin;
        }

        // Draw item text
        currentY = this.drawWrappedText(currentPage, item.text, x + 10, currentY, {
          font: fonts.regular,
          fontSize: 11,
          lineHeight: 14,
          maxWidth: maxWidth - 20
        });
        currentY -= 8;

        const checkboxImage = checkboxSignatureImages.get(item.signatureKey);

        // Draw the checkbox signature if available
        if (checkboxImage) {
          currentPage.drawImage(checkboxImage, {
            x: x + 150,
            y: currentY - 5,
            width: 100,
            height: 30
          });
        } else {
          // Draw a line as placeholder
          currentPage.drawText('_________________', {
            x: x + 150,
            y: currentY,
            size: 12,
            font: fonts.regular
          });
        }

        currentY -= 25;
      }
      currentY -= 5;
    }

    // Check if we need a new page for final signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page7-drug-alcohol',
  templateUrl: './page7-drug-alcohol.component.html',
  styleUrls: ['../shared.scss', './page7-drug-alcohol.component.scss']
})
export class Page7DrugAlcoholComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() prospectiveEmployerNameCtrl: FormControl;
  @Input() prospectiveEmployerAddressCtrl: FormControl;
  @Input() drugAlcoholAnswerCtrl: FormControl;
  @Input() drugExplanation: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;
  @Output() moveToNextPage = new EventEmitter<void>();

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  moveToDriverRequirement() {
    this.moveToNextPage.emit();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Always create a new page for Page 7 content
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(newPage, position, formValues, fonts, formValues.currentPageCount);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = "New Employee's Drug and Alcohol Statement";
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    newPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph (italic)
    const paragraph1 =
      'In accordance with 49 CFR 40.25 (j), as the employer, you must ask any prospective employee, whether he or she ' +
      'has tested positive, or refused to test, on any pre-employment drug or alcohol test administered by an employer ' +
      'to which the employee applied for, but did not obtain, safety-sensitive transportation work covered by DOT ' +
      'agency drug and alcohol testing rules during the past two years.';

    currentY = this.drawWrappedText(newPage, paragraph1, x, currentY, {
      font: fonts.italic,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 25;

    // Draw form fields - Prospective Employer
    newPage.drawText('Prospective Employer Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.prospectiveEmployerName || '', {
      x: x + 5,
      y: currentY - 20,
      size: 12,
      font: fonts.bold
    });

    newPage.drawLine({
      start: { x, y: currentY - 23 },
      end: { x: x + 300, y: currentY - 23 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Address
    newPage.drawText('Address:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.prospectiveEmployerAddress || '', {
      x: x + 5,
      y: currentY - 20,
      size: 12,
      font: fonts.bold
    });

    newPage.drawLine({
      start: { x, y: currentY - 23 },
      end: { x: x + 300, y: currentY - 23 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw question
    const question =
      'Have you tested positive, or refused to test, including any preemployment drug or alcohol test administrated ' +
      'by an employer to which you applied for, but did not obtain, safety sensitive transportation work covered by ' +
      'DOT agency drug and alcohol testing rules during the past two years?';

    currentY = this.drawWrappedText(newPage, question, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 25;

    // Draw radio buttons
    const radioX = x + 50;
    newPage.drawText('YES', {
      x: radioX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // YES radio button
    newPage.drawCircle({
      x: radioX - 15,
      y: currentY + 4,
      size: 7,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1
    });

    // Fill circle if selected
    if (formValues.drugAlcoholAnswer === 'YES') {
      newPage.drawCircle({
        x: radioX - 15,
        y: currentY + 4,
        size: 4,
        color: rgb(0, 0, 0)
      });
    }

    // NO radio button
    const noX = radioX + 70;
    newPage.drawText('NO', {
      x: noX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawCircle({
      x: noX - 15,
      y: currentY + 4,
      size: 7,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1
    });

    // Fill circle if selected
    if (formValues.drugAlcoholAnswer === 'NO') {
      newPage.drawCircle({
        x: noX - 15,
        y: currentY + 4,
        size: 4,
        color: rgb(0, 0, 0)
      });
    }

    currentY -= 30;
    if (formValues.drugAlcoholAnswer === 'YES') {
      currentY = this.drawWrappedText(newPage, `Explanation : ${formValues.drugExplanation}`, x, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth
      });

      currentY -= 30;
    }

    // Draw second paragraph (italic)
    const paragraph2 =
      'If the employee admits that he or she had a positive test or refusal to test, you must not use the employee to ' +
      'perform safety-sensitive functions for you, until and unless the employee documents successful completion of the ' +
      'return-to-duty process (see 40.25(b)(5) and 40.25(e). [The return-to-duty process is outlined in Subpart O of ' +
      'Part 40.';

    currentY = this.drawWrappedText(newPage, paragraph2, x, currentY, {
      font: fonts.italic,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 25;

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage7');
    if (signature) {
      newPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    newPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    // Draw driver name field
    newPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    newPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    newPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    newPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0, // Height is not relevant since we're using a new page
      newPage: true,
      position: {
        ...position,
        y: currentY
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

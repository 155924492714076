import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page21-license-status',
  templateUrl: './page21-license-status.component.html',
  styleUrls: ['../shared.scss', './page21-license-status.component.scss']
})
export class Page21LicenseStatusComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Declaration of License Status Change';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph
    const paragraph1 =
      'The Ontario Government has implemented Bill 138, which includes a provision that allows the impoundment of a ' +
      'vehicle if the driver is found to be driving while his or her license is under suspension for a Canada Criminal ' +
      'Code conviction. This impoundment provision applies even if the driver is not the owner of the vehicle.';

    currentY = this.drawWrappedText(currentPage, paragraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw second paragraph
    const paragraph2 =
      'The person caught driving while under suspension will face fines of $5000.00 to $50,000.00 (10 times the current ' +
      'rate). In addition, the vehicle being operated at the time will be impounded for 45 to 180 days.';

    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw third paragraph
    const paragraph3 =
      'Because of this provision, we are asking that all drivers sign the declaration below and return this to the ' +
      'safety department A.S.A.P.';

    currentY = this.drawWrappedText(currentPage, paragraph3, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw declaration paragraph with inline fields
    // Start with "I,"
    currentPage.drawText('I,', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of the first part
    const firstPartWidth = fonts.regular.widthOfTextAtSize('I,', 12) + 5;

    // Draw driver name
    const driverName = formValues.driverName || '';
    currentPage.drawText(driverName, {
      x: x + firstPartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of driver name
    const driverNameWidth = fonts.bold.widthOfTextAtSize(driverName, 12) + 5;

    // Draw "shall provide notification immediately to"
    const secondPart = ' shall provide notification immediately to ';
    currentPage.drawText(secondPart, {
      x: x + firstPartWidth + driverNameWidth,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of second part
    const secondPartWidth = fonts.regular.widthOfTextAtSize(secondPart, 12);

    // Draw company name
    const companyName = formValues.carrierCompanyName || '';

    // Check if we need to move to next line based on available space
    const availableWidth = maxWidth - (firstPartWidth + driverNameWidth + secondPartWidth);
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    let companyNameX = x + firstPartWidth + driverNameWidth + secondPartWidth;

    if (companyNameWidth > availableWidth) {
      // Move to next line
      currentY -= 20;
      companyNameX = x;
    }

    currentPage.drawText(companyName, {
      x: companyNameX,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // For simplicity, continue on a new line with rest of declaration
    currentY -= 20;

    // Draw rest of declaration
    const declaration =
      "of any violations, citations or offense notices and suspensions or changes to my driver's license status. I have " +
      'also read the information above and understand the possible consequences, for myself as a driver and for ' +
      companyName +
      ", as the said operator of the vehicle, if I am found to be driving while my driver's license is under " +
      'suspension.';

    currentY = this.drawWrappedText(currentPage, declaration, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 40;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage21');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5"><mat-icon svgIcon="left-arrow"></mat-icon></div>
    <div fxFlex="95" class="header-text">Uploaded Document</div>
  </div>
  <div class="content" fxLayout="column">
    <div fxFlex="100" fxLayout="column">
      <div class="document-list-item">
        <div fxLayout class="document-row">
          <div fxFlex="90" class="file-name">
            <span class="link" (click)="onDownloadFileClick(selectedId)">{{ fileName }}</span>
          </div>
          <mat-icon fxFlex="10" svgIcon="delete-icon" class="delete-icon" (click)="deleteDocument(selectedId)"></mat-icon>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row" *ngIf="!issueDate">
          <div fxFlex="70" class="date-uploaded-text">Generated Date</div>
          <div fxFlex="30" class="date-text">{{ generatedDate | date: 'dd MMM YYYY' }}</div>
        </div>
        <div fxLayout class="document-row" *ngIf="issueDate">
          <div fxFlex="70" class="date-uploaded-text">Issued Date</div>
          <div fxFlex="30" class="date-text">{{ issueDate | date: 'dd MMM YYYY' }}</div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row" *ngIf="expiryDate">
          <div fxFlex="70" class="date-uploaded-text">Expiry Date</div>
          <div fxFlex="30" class="date-text">{{ expiryDate | date: 'dd MMM YYYY' }}</div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row" *ngIf="fastCardNumber">
          <div fxFlex="70" class="date-uploaded-text">FAST Card Number</div>
          <div fxFlex="30" class="date-text">{{ fastCardNumber }}</div>
        </div>
        <div fxLayout class="document-row" *ngIf="healthCardNumber">
          <div fxFlex="70" class="date-uploaded-text">Health Card Number</div>
          <div fxFlex="30" class="date-text">{{ healthCardNumber }}</div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
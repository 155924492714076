import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Bid } from 'src/app/loads-shared/shipment-data/models/bid';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { Response } from 'src/app/shared/services/response';

const PATH_SHIPMENTS = '/shipments';
const PATH_BID = `/quotes/{id}/bids`;
const PATH_SHIPMENTS_DETAILS = PATH_SHIPMENTS + '/{shipmentid}?include=quote';
const PATH_SHIPMENTS_HISTORY = '/drivers/{driverId}/history'
export const EVENT_TYPE_CONFIRMED = 'confirmed';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsApiService {
  constructor(private readonly api: FleetoperateApiService, @Inject('apiUrl') private apiUrl: string) {}

  getMyShipments(actorid?: string): Observable<Response> {
    const path = PATH_SHIPMENTS;
    let queryString = `?include=quote&actorid=${actorid}`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  createBid(quoteId: string, bid: Bid): Observable<Response> {
    const path = PATH_BID.replace('{id}', quoteId);
    return this.api.post(`${this.apiUrl}${path}`, bid);
  }

  getShipmentDetails(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DETAILS.replace('{shipmentid}', shipmentId);
    return this.api.get(`${this.apiUrl}${path}`);
  }

  updateShipment(shipmentId: string, body: any): Observable<Response> {
    const path = `${PATH_SHIPMENTS}/${shipmentId}`;
    return this.api.patch(`${this.apiUrl}${path}`, body);
  }

  getShipmentsHistory(driverId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_HISTORY.replace('{driverId}', driverId);
    let queryString = `?include=shipment`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }
}

<h2>Applicant Information</h2>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Applicant Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date of Application</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
  <p>
    In compliance with Federal and Provincial equal employment opportunity laws, qualified applicants are considered for
    all positions without regard to race, colour, religion, sex, national origin, age, marital status or the presence of
    a non-job-related medical condition or handicap.
  </p>
  <p>
    I authorize you to make such investigations and inquiries of my personal, employment, financial or medical history
    and other related matters as may be necessary in arriving at an employment decision. (Generally, inquiries regarding
    medical history will be made only if and after a conditional offer of employment has been extended.) I hereby
    release employers, schools, health care providers and other persons from all liability in responding to inquiries
    and releasing information in connection with my application. In the event of employment, I understand that false or
    misleading information given in my application or interview(s) may result in termination. I understand, also, that I
    am required to abide by all rules and regulations of the Company.
  </p>
  <p>
    I understand that information I provide regarding current and/or previous employers may be used, and those
    employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49 CFR
    391.23(d) and (e). I understand that I have the right to: Review information provided by previous employers;
  </p>
  <p>
    Have errors in the information corrected by previous employers and for those previous employers to re-send the
    corrected information to the prospective employer; and Have a rebuttal statement attached to the alleged erroneous
    information if the previous employer(s) and I cannot agree on the accuracy of the information.
  </p>
</div>
<div class="form-group">
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<h3>For Company Use</h3>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Date Employed</mat-label>
    <input matInput type="date" [formControl]="dateEmployedCtrl" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Reason Rejection</mat-label>
    <input matInput [formControl]="reasonRejectionCtrl" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Date Termination</mat-label>
    <input matInput type="date" [formControl]="dateTerminationCtrl" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Reason Termination</mat-label>
    <input matInput [formControl]="reasonTerminationCtrl" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Safety Officer Name</mat-label>
    <input matInput [formControl]="safetyOfficerNameCtrl" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Safety Officer Date</mat-label>
    <input matInput type="date" [formControl]="safetyOfficerDateCtrl" />
  </mat-form-field>
</div>
<p class="page-number">Page 2 of 30</p>

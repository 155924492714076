import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_LOADS, ROUTE_JOBS, ROUTE_ADD_TO_HOME_SCREEN, ROUTE_TRIPS, ROUTE_DQF, ROUTE_SHIPMENTS } from 'src/app/shared/routes';

const PATH_DRIVER_DASHBOARD = '';
const PATH_PROFILE = 'profile';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_NOTIFICATIONS = 'notifications';
const PATH_ACCOUNT_SETTINGS = 'accountSettings';
const PATH_REFER_FRIEND = 'referFriend';
const PATH_TELL_FRIEND = 'tellFriend';
const PATH_DRIVER_HISTORY = 'driverHistory';
const ROUTE_SHIPMENTS_HISTORY = 'shipments-history';

@Injectable({
  providedIn: 'root'
})
export class FrameNavigationService {
  constructor(private readonly router: Router) {}

  navigateToDriverDashboard(): Promise<boolean> {
    return this.router.navigate([PATH_DRIVER_DASHBOARD]);
  }

  navigateToProfile(): Promise<boolean> {
    return this.router.navigate([PATH_PROFILE]);
  }

  navigateToProfilePicture(): Promise<boolean> {
    return this.router.navigate([PATH_PROFILE_PICTURE]);
  }

  navigateToLoads(): Promise<boolean> {
    return this.router.navigate([ROUTE_LOADS]);
  }

  navigateToTrips(): Promise<boolean> {
    return this.router.navigate([ROUTE_TRIPS]);
  }

  navigateToShipments(): Promise<boolean> {
    return this.router.navigate([ROUTE_SHIPMENTS]);
  }

  navigateToShipmentsHistory(): Promise<boolean> {
    return this.router.navigate([ROUTE_SHIPMENTS_HISTORY]);
  }

  navigateToJobs(): Promise<boolean> {
    return this.router.navigate([ROUTE_JOBS]);
  }

  navigateToNotifications(): Promise<boolean> {
    return this.router.navigate([PATH_NOTIFICATIONS]);
  }

  navigateToAccountSettings(): Promise<boolean> {
    return this.router.navigate([PATH_ACCOUNT_SETTINGS]);
  }

  navigateToReferFriend(): Promise<boolean> {
    return this.router.navigate([PATH_REFER_FRIEND]);
  }

  navigateToTellFriend(): Promise<boolean> {
    return this.router.navigate([PATH_TELL_FRIEND]);
  }

  navigateToDriverHistory(): Promise<boolean> {
    return this.router.navigate([PATH_DRIVER_HISTORY]);
  }

  navigateToAddToHomeScreen(): Promise<boolean> {
    return this.router.navigate([ROUTE_ADD_TO_HOME_SCREEN]);
  }
  navigateToDriverQualificaton(): Promise<boolean> {
    return this.router.navigate([ROUTE_DQF]);
  }
}

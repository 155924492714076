import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { SubSink } from 'subsink';
import { REQUIRED_MESSAGE, CARRIER_UPLOAD_REQUESTED } from '../../constants';
import { ROUTER_DQF_VIEW } from '../../routes';
import { Location } from '@angular/common';
import { POLICE_CLEARANCE } from 'src/app/driver-qualification-files/constants';
import { CommonDataService } from '../../services/common/common-data.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { title } from 'process';
import { SelectActionComponent } from '../../dialog/select-action/select-action.component';
import { base64ToFile } from '../../utils';

const DELETE_MESSAGE = 'Are you sure you want to delete the Record?';
const UPLOAD_TYPE_ERROR = 'Upload pdf, jpeg, jpg or png files';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
  @Input() documentDqfStatus;
  @Input() formUploadFiles;
  @Input() documentType?: string;
  @Input() hideTabs?: boolean;
  @Output() uploadDocument = new EventEmitter<any>();
  @Output() deleteDocument = new EventEmitter<any>();
  @Output() requestCarrier = new EventEmitter<any>();
  currentDate = new Date();
  form: FormGroup;
  uploadedFile: any;
  required = REQUIRED_MESSAGE;
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg'];
  acceptTypesMultiple = ['application/pdf'];
  uploadError = null;
  isUploading: boolean = false;
  subSink = new SubSink();
  isRequested: boolean = false;
  isExpiring: boolean = true;
  isCarrierRequested = true;
  imageChangedEvent: any;
  canvasRotation = 0;
  transform: ImageTransform = {};
  documentPicture: any;
  showCrop: boolean;
  loading = false;
  expireMinDate = new Date(new Date().setDate(new Date().getDate() + 1));
  @ViewChild('generationDate') generationDate: MatDatepicker<Date>;
  // @ViewChild('validToDate') validToDate: MatDatepicker<Date>;
 // @ViewChild('issueDate') issueDate: MatDatepicker<Date>;
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  isIssued: boolean;
  isFastCard: boolean;
  isHealthCard: boolean;

  constructor(
    private router: Router,
    private readonly location: Location,
    private readonly commonDataService: CommonDataService,
    @Inject('environmentData') public environment: any,
    private readonly bottomSheet: MatBottomSheet,
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    // reading the config data and assigning the values, if we don't value
    // default value false will used
    this.commonDataService
      .getConfig()
      .then((response: any) => {
        if (response && response.status === 200) {
          return response.json();
        }
      })
      .then(
        (config: any) => {
          if (config) {
            this.commonDataService.isCERTNEnableForAbstract = config.isCERTNEnableForAbstract;
            this.commonDataService.isCERTNEnableForPoliceClearance = config.isCERTNEnableForPoliceClearance;
          }
        },
        error => {}
      );

    this.checkIfDocumentRequested();
    this.form = this.formUploadFiles.form;
    this.isExpiring = !!this.form.get('validToDate');
    this.isIssued = !!this.form.get('issueDate');
    this.isFastCard = !!this.form.get('fastCardNumber');
    this.isHealthCard = !!this.form.get('healthCardNumber');
    this.subSink.sink = this.form.get('file').valueChanges.subscribe(value => {
      if (value?.id) {
        this.uploadedFile = { ...value };
        this.isUploading = false;
      } else {
        this.uploadedFile = null;
      }
    });
    this.checkDataFormPage();
  }

  checkIfDocumentRequested(): void {
    this.isRequested = this.documentDqfStatus === CARRIER_UPLOAD_REQUESTED;
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
  checkDataFormPage(): void {
    if (!this.formUploadFiles || !this.documentDqfStatus) {
      this.location.back();
    }
  }

  get validToDate() {
    return this.form.get('validToDate');
  }

  get issueDate() {
    return this.form.get('issueDate');
  }

  get fastCardNumber() {
    return this.form.get('fastCardNumber');
  }

  get healthCardNumber () {
    return this.form.get('healthCardNumber');
  }

  setExpireMinDate(event): void {
    let dateToset = this.currentDate > event.value ? this.currentDate : event.value;

    this.expireMinDate = dateToset;
    if (this.validToDate?.value) {
      this.validToDate?.setValue('');
    }
  }

  openActionSheet(pictureSingle, uploadMultiple): void {
    if (this.environment?.featureFlags?.enableDQFDocImageCropFeature) {
      let options: any = [
        {
          displayName: 'Take picture (Single page)',
          name: 'picture'
        },
        {
          displayName: 'Upload File (Multiple pages)',
          name: 'upload'
        }
      ];
      this.bottomSheet.open(SelectActionComponent, {
        data: {
          title: title,
          options: options,
          selectedValue: null,
          onConfirm: (val: string) => {
            if (val === 'picture') {
              this.onFileInputClicked(pictureSingle);
            } else {
              this.onFileInputClicked(uploadMultiple);
            }
          }
        }
      });
    } else {
      this.onFileInputClicked(uploadMultiple);
    }
  }

  onUploadFileChange($event: any): void {
    this.isUploading = true;
    this.uploadError = null;
    if (!$event || $event.target.files.length === 0) {
      return;
    }
    const [file] = $event.currentTarget.files;
    if (!this.acceptTypesMultiple.includes(file.type)) {
      this.uploadError = UPLOAD_TYPE_ERROR;
      this.isUploading = false;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.form.get('file').patchValue(file);
      $event.target.files = null;
      $event.target.value = null;
      this.uploadDocument.emit();
    };
  }

  viewDocument(file): void {
    this.router.navigateByUrl(`${ROUTER_DQF_VIEW}/${file.id}`);
  }

  deleteDocuments(file): void {
    if (confirm(DELETE_MESSAGE)) {
      this.deleteDocument.emit();
    }
  }
  onRequestCarrierUpload(): void {
    this.requestCarrier.emit();
  }

  isRequestCollect($event): void {
    if (
      $event.index &&
      this.commonDataService.isCERTNEnableForPoliceClearance &&
      this.documentType === POLICE_CLEARANCE
    ) {
      this.isCarrierRequested = false;
      this.onRequestCarrierUpload();
    } else {
      this.isCarrierRequested = true;
    }
  }

  onFileInputClicked(fileInput: any) {
    fileInput.click();
  }

  onProcessPictureFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.documentPicture = file;
      };
      this.loading = true;
      this.showCrop = true;
      this.imageChangedEvent = event;
    }
  }
  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      const imageFile = new File([base64ToFile(event.base64)], this.documentPicture.name, {
        type: this.documentPicture.type
      });
      this.documentPicture = imageFile;
    }
  }

  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onFinishCrop() {
    this.form.get('file').patchValue(this.documentPicture);
    this.uploadDocument.emit();
  }

  onCancelCrop() {
    this.previewImage = null;
    this.documentPicture = null;
    this.showCrop = false;
    this.loading = false;
  }

  onLoadImageFailed() {
    this.loading = false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { DocumentsApiService } from 'src/app/shared/documents-data/documents-api.service';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Response } from 'src/app/shared/services/response';
import { catchError, map } from 'rxjs/operators';
import { DqfDocument } from '../models/dqfDocument';
import { OnHourDuty } from '../../shared/models/on-hour-duty.model';
import { DqfStatusMsgComponent } from '../dqf-document-upload/dqf-status-msg/dqf-status-msg.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { AttachedDocuments } from '../models/attachedDocuments';
import {
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  PRE_EMPLOYMENT_SCREENING,
  PRE_EMPLOYMENT_REQUIRED,
  EMPLOYMENT_HISTORY_CHECK,
  ONTARIO_DRIVING_ABSTRACT,
  PROVINCE_ONTARIO
} from '../constants';
import { DocumentAction } from '../dqf-document-upload/dqf-document-upload.component';
import { of } from 'rxjs';
const SOURCE_TEXT = 'self';
const STATUS_REJECTED = 'Rejected';
const STATUS_PENDING = 'Pending';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';

@Injectable({
  providedIn: 'root'
})
export class DriverQualificationService {
  profilePicture: string;
  dqfData: DqfDocument;
  dqfId: string;
  driverBirthLocationData: any;
  private helloSignUrlCache: { [key: string]: any | undefined } = {};
  constructor(
    private readonly http: HttpClient,
    private readonly driverApiService: DriverApiService,
    private documentApiService: DocumentsApiService,
    private dateTimeService: DateTimeService,
    private registrationService: RegistrationService,
    private readonly bottomSheet: MatBottomSheet
  ) {}

  getDqfs(): Observable<any> {
    return this.driverApiService.getDqfsByDriverId().pipe(
      map((response: any) => {
        return response && response.data ? (response.data as DqfDocument[]) : undefined;
      })
    );
  }
  getDqfById(id: string): Observable<DqfDocument> {
    return this.driverApiService.getDqfById(id).pipe(
      map((response: any) => {
        if (response && response.data) {
          this.dqfData = response.data;
        }
        return response && response.data ? (response.data as DqfDocument) : undefined;
      })
    );
  }

  updateDqfStatus(id: string, dqfStatus: any) {
    return this.driverApiService.updateDqfStatus(id, dqfStatus);
  }
  getOnDutyHours(dqfId: string): Observable<any> {
    return this.driverApiService.getOnDutyHours(dqfId).pipe(
      map(data => {
        return data.data as OnHourDuty;
      })
    );
  }
  updateOnDutyHours(model: OnHourDuty): Observable<any> {
    const id = model.id;
    delete model.id;
    return this.driverApiService.updateOnDutyHours(model, id).pipe(
      map(data => {
        return data.data;
      })
    );
  }
  getDqfDocument(documentId: string, isLoadingFalse?: boolean): Observable<any> {
    return this.documentApiService.getDqfDocument(this.driverApiService.driverID, documentId, isLoadingFalse);
  }

  uploadDqfDocument(model: any, file: any, isLoadingFalse?: boolean): Observable<Response> {
    return this.registrationService
      .saveDocument(model, file, isLoadingFalse)
      .pipe(map((response: Response) => response));
  }

  deleteDqfDocument(documentId: string, isLoadingFalse?: boolean): Observable<any> {
    return this.documentApiService.deleteDqfDocument(this.driverApiService.driverID, documentId, isLoadingFalse);
  }
  updateDocument(documentId: string, saveModel: any, isLoadingFalse?: boolean): Observable<any> {
    return this.documentApiService.updateDocument(
      this.driverApiService.driverID,
      documentId,
      saveModel,
      isLoadingFalse
    );
  }

  getDriverSignatureIntent(): Observable<any> {
    return this.driverApiService.getDriverSignatureIntent();
  }
  getCarrierCompanyLogo(carrierCompanyId): Observable<any> {
    return this.documentApiService.getCarrierCompanyLogo(carrierCompanyId).pipe(map(response => response.data));
  }
  getHelloSignUrl(doc: any): Observable<Response | undefined> {
    const docType = doc.documentType;
    if (this.helloSignUrlCache[docType]) {
      return of(this.helloSignUrlCache[docType]);
    }

    return this.driverApiService.getHelloSignUrl(doc).pipe(
      map((response: any) => {
        if (response && response.data) {
          this.helloSignUrlCache[docType] = response.data;
          return response.data;
        }
        return undefined;
      }),
      catchError(error => {
        console.error('Error fetching HelloSign URL:', error);
        return of(undefined);
      })
    );
  }

  getAndConsumeHelloSignUrl(docType: any): any | undefined {
    const cachedUrl = this.helloSignUrlCache[docType];

    if (cachedUrl) {
      delete this.helloSignUrlCache[docType];
      return cachedUrl;
    }
    return undefined;
  }

  prepareUploadDocumentSaveModel(
    formValue: any,
    reportType: string,
    documentContext: string,
    dqfId?: string
  ): DocumentRequest {
    const extension = formValue.file.name
      .split('.')
      .pop()
      .toLowerCase();
    const formModel = formValue;

    if (['pdf', 'jpeg', 'jpg', 'png'].find(element => element == extension) == undefined) {
      return undefined;
    }

    const model: any = {
      source: SOURCE_TEXT,
      extension: extension,
      generationDate: this.dateTimeService.formatDateTime(formModel.generationDate, ISO_8601),
      reportType: reportType,
      context: documentContext
    };
    if (dqfId) {
      model.dqfId = dqfId;
    }
    return model as DocumentRequest;
  }

  bindDriverDocument(notification, formUploadFiles): void {
    const fileInfo = {
      fileName: notification?.typeDisplayName,
      generationDate: notification?.generationDate.split('T')[0],
      id: notification?.id
    };
    formUploadFiles.form.get('generationDate').patchValue(fileInfo.generationDate);
    formUploadFiles.form.get('file').patchValue(fileInfo);
  }

  openBottomSheetWithFunction(sheetData: any, UPDATE_DQF_DOC_MODEL: any, callback: Function): void {
    const bottomSheetRef = this.bottomSheet.open(DqfStatusMsgComponent, {
      panelClass: 'signature-panel',
      disableClose: true,
      data: sheetData
    });

    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data === 'fixNow') {
        this.updateDqfStatus(this.dqfId, UPDATE_DQF_DOC_MODEL).subscribe(res => {
          if (res) {
            callback();
          }
        });
      }
      if (data === 'fixNowSignDeclaration') {
        callback();
      }
    });
  }

  updateDQFStatusToReview(doc: any, UPDATE_DQF_DOC_MODEL: any, navigateTo: Function): void {
    if (doc.status == STATUS_PENDING) {
      this.updateDqfStatus(this.dqfId, UPDATE_DQF_DOC_MODEL).subscribe(res => {
        if (res) {
          navigateTo();
        }
      });
    }
    if (doc.status == STATUS_REJECTED) {
      const sheetData = {
        displayName: doc.displayName,
        reason: doc.rejectionReason
      };
      this.openBottomSheetWithFunction(sheetData, UPDATE_DQF_DOC_MODEL, () => navigateTo());
    }
  }
  getDqfDocumentFromAttachedDocuments(documentType: string): AttachedDocuments {
    return this.dqfData?.attachedDocuments?.find(data => data.type === documentType);
  }

  bindDriverAttachments(
    attachedDocuments: AttachedDocuments[],
    licenseProvince: string,
    driverType: string,
    navigateToDocument?: any,
    navigateToSignDeclaration?: any,
    navigateToAbstractDoc?: any,
    navigateToPoliceCertificateBasedOnAction?: any,
    navigateToPreEmploymentScreeningReport?: any,
    navigateToEmploymentHistoryCheck?: any,
    navigateToOntarioCvorAbstractDoc?: any
  ): DocumentAction[] {
    const documents: DocumentAction[] = [];
    attachedDocuments.forEach((doc: AttachedDocuments) => {
      const info: DocumentAction = {
        status: doc.status,
        type: doc.type,
        rejectionReason: doc.rejectionReason,
        isRequirementsCompleted: doc.isRequirementsCompleted,
        action: doc['action'] || null,
        licenseProvince: licenseProvince || null
      };

      switch (info.type) {
        case DECLARATION:
          info.iconName = 'ball-pen';
          info.displayName = 'Declaration';
          info.clickHandler = () => navigateToDocument(info, () => navigateToSignDeclaration());
          info.order = 1;
          documents.push(info);
          break;
        case ABSTRACT:
          info.iconName = 'accident-record-icon';
          info.displayName = 'Abstract';
          info.clickHandler = () => navigateToDocument(info, () => navigateToAbstractDoc());
          info.order = 2;
          documents.push(info);
          break;

        case POLICE_CLEARANCE:
          info.iconName = 'car-icon';
          info.displayName = 'Police Clearance Certificate';
          info.clickHandler = () => navigateToPoliceCertificateBasedOnAction(info);
          info.order = 3;
          documents.push(info);
          break;

        case PRE_EMPLOYMENT_SCREENING:
          info.iconName = 'zoom-icon';
          info.displayName = 'Pre Employment Screening Report';
          info.clickHandler = () => navigateToDocument(info, () => navigateToPreEmploymentScreeningReport());
          info.order = 4;
          documents.push(info);
          break;

        case EMPLOYMENT_HISTORY_CHECK:
          info.iconName = 'ball-pen';
          info.displayName = 'Employment History Check';
          info.clickHandler = () => navigateToDocument(info, () => navigateToEmploymentHistoryCheck());
          info.order = 5;
          documents.push(info);
          break;

        case ONTARIO_DRIVING_ABSTRACT:
          info.iconName = 'accident-record-icon';
          info.displayName = 'Ontario CVOR Abstract';
          info.clickHandler = () => navigateToDocument(info, () => navigateToOntarioCvorAbstractDoc());
          info.order = 6;
          documents.push(info);
          break;

        default:
          break;
      }
    });

    return documents;
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page18-health-safety',
  templateUrl: './page18-health-safety.component.html',
  styleUrls: ['../shared.scss', './page18-health-safety.component.scss']
})
export class Page18HealthSafetyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Responsibility & Accountability for Health & Safety';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph with inline company name
    const paragraphStart = 'Workers employed by ';
    currentPage.drawText(paragraphStart, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of the first part of the text
    const paragraphStartWidth = fonts.regular.widthOfTextAtSize(paragraphStart, 12);

    // Draw company name
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x: x + paragraphStartWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the company name
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Draw the rest of the paragraph
    currentPage.drawText(' are responsible for:', {
      x: x + paragraphStartWidth + companyNameWidth,
      y: currentY,
      size: 12,
      font: fonts.regular
    });
    currentY -= 25;

    // Draw bullet points for responsibilities
    const responsibilities = [
      'Reporting for work "fit for duty" and/or notifying their direct supervisor of any mental or physical ' +
        'conditions that may impact the performance of their assigned work tasks.',

      'Adhering to regulations, guidelines and safety standards as required by government regulatory agencies and ' +
        'those communicated by management and supervisors (in particular, applying all hazard controls as specified by ' +
        'applicable hazard assessments).',

      'Following all appropriate codes of Practice, Safe Operating Procedures, associated Safe Work Practices and ' +
        `Safety Rules contained in the Company's Health and Safety Manual.`,

      'Reporting any hazardous or unsafe working conditions to their immediate supervisor and, if possible, ' +
        'correcting the unsafe condition.',

      'Observing activities of fellow employees and contractors to ensure their safety and the safety of those around ' +
        'them and correcting unsafe acts in a proactive, positive manner to prevent an incident from occurring.',

      'Refusing to perform work that:',

      'Reporting all incidents, injuries, and illness to their supervisor.',

      'Participating in, and using, all training offered by the Company, and',

      'Using the required personal protective equipment (PPE).'
    ];

    // Draw each responsibility bullet point
    for (let i = 0; i < responsibilities.length; i++) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Special handling for "Refusing to perform work that:" bullet point with sub-bullets
      if (i === 5) {
        // Draw the main bullet point
        currentY = this.drawWrappedText(currentPage, responsibilities[i], x + 15, currentY, {
          font: fonts.regular,
          fontSize: 12,
          lineHeight: 16,
          maxWidth: maxWidth - 15
        });
        currentY -= 15;

        // Draw sub-bullets
        const subBullets = [
          'He or she believes presents an imminent danger to the health or safety of themselves or their fellow workers, and',
          'They are not competent to perform.'
        ];

        for (const subBullet of subBullets) {
          // Check if we need a new page
          if (currentY < margin + 50) {
            currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
            currentY = pageHeight - margin;
          }

          // Draw sub-bullet with indentation
          currentPage.drawText('-', {
            x: x + 25,
            y: currentY,
            size: 12,
            font: fonts.regular
          });

          // Draw sub-bullet text with further indentation
          currentY = this.drawWrappedText(currentPage, subBullet, x + 40, currentY, {
            font: fonts.regular,
            fontSize: 12,
            lineHeight: 16,
            maxWidth: maxWidth - 40
          });
          currentY -= 15;
        }
      } else {
        // Draw regular bullet point text with indentation
        currentY = this.drawWrappedText(currentPage, responsibilities[i], x + 15, currentY, {
          font: fonts.regular,
          fontSize: 12,
          lineHeight: 16,
          maxWidth: maxWidth - 15
        });
        currentY -= 15;
      }
    }

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage18');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

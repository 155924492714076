<h2>Vehicle Maintenance</h2>
<p>
  All of our vehicles are operated under a full maintenance program. Any malfunctions or safety concerns should always
  be reported to a supervisor and noted on a Vehicle Inspection Report (VIR) to ensure repairs are made.
</p>
<ul>
  <li>
    Part of your daily routine includes a pre-trip and post-trip inspection of your vehicle using a company provided
    vehicle inspection report. Turn in a copy of the vehicle inspection report at the end of your shift.
  </li>
  <li>
    Keep your cab clean. Company will provide cleaning supplies such as paper towels, spray cleaner, and whisk brooms to
    help maintain the condition of the cab and create a safe workplace.
  </li>
</ul>
<p>An inspection report must be completed and it must include</p>
<ul>
  <li>vehicle license number and jurisdiction</li>
  <li>motor carrier name</li>
  <li>inspection date, time and location</li>
  <li>
    inspection declaration (statement by the driver that the vehicle was inspected in accordance with the Regulation)
  </li>
  <li>name and signature of the person who conducted the inspection</li>
  <li>odometer reading</li>
  <li>driver signature.</li>
</ul>
<p>
  The inspection report must show when there are no defects and any minor or major defects must be recorded on the
  inspection report. When there are major defects, the vehicle cannot be operated Minor defects must be repaired as soon
  as possible but do not prohibit use of a vehicle The inspection report and the schedule must be carried by the driver.
  The <strong>inspection is valid for 24 hours</strong>. Driver must monitor the vehicle while operating it and look for
  defects.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 16 of 30</p>

import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import dayjs, { Dayjs } from 'dayjs';
import { CERTN_COUNTRIES } from 'src/app/shared/models/provinces';
import { RegistrationService } from '../../shared/registration.service';
import { Location } from '@angular/common';
import { ResidenceAddress } from '../../shared/models/residence-adress.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { of } from 'rxjs';
import { CANADA_PROVINCES, USA_PROVINCES } from 'src/app/shared/models/provinces';
const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const REGEX_LETTERS_SPACES_HYPHENS = '^[A-Za-z- ]*$';
const PATTERN_ERROR_MESSAGE = 'Only letters, spaces and hyphens are acceptable';
const country_US = 'US';
const country_CA = 'CA';
class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}
@Component({
  selector: 'app-residence-history-form',
  templateUrl: './residence-history-form.component.html',
  styleUrls: ['./residence-history-form.component.scss', '../residence-history.component.scss']
})
export class ResidenceHistoryFormComponent implements OnInit, AfterViewInit {
  turnOffAddressLookup: boolean = false;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);
  maxDate = dayjs(this.currentDate);
  minDate = dayjs(this.currentDate).subtract(15, 'year');
  maxFromDate = this.maxDate;
  minFromDate = this.minDate;
  previousAddressForm;
  certnCountries = CERTN_COUNTRIES;
  countryUSorCA: string[] = [country_US, country_CA];
  countries;
  addresses;
  isContentReady = true;
  isGoogleLookupReinitPending: boolean = false; // Track reinitialization
  canadaProvinces = CANADA_PROVINCES;
  usaProvinces = USA_PROVINCES;
  provinces = [];

  @ViewChild('previousAddressText', { static: false }) previousAddressText: any;
  constructor(
    private readonly fb: FormBuilder,
    private readonly zone: NgZone,
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private toastService: ToastService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.previousAddressForm = this.createPreviousAddressForm();
    this.certnCountries = this.certnCountries.map(country => {
      if (!this.countryUSorCA.includes(country.value)) {
        country.value = country.key;
      } else if (country.value === country_US) {
        country.key = 'USA';
      }
      return country;
    }).filter((country, index, self) =>
      index === self.findIndex(c => c.value === country.value)
    );
    this.countries = this.certnCountries;
    this.loadAddresses();
  }
  ngAfterViewInit(): void {
    this.loadGoogleAutoComplete();
  }
  ngAfterViewChecked() {
    if (this.isGoogleLookupReinitPending && !this.turnOffAddressLookup) {
      this.getPlaceAutocomplete(this.previousAddressText);
      this.isGoogleLookupReinitPending = false; // Reset the flag
    }
  }
  loadAddresses() {
    const allAddresses = this.registrationService.getAddressesFromStore();
    if (!allAddresses?.length) {
      this.navigateToListPage();
      return;
    }
    this.addresses = [...allAddresses]?.sort(
      (a, b) => new Date(b.livedFromDate).getTime() - new Date(a.livedFromDate).getTime()
    );
    const toDate = dayjs(this.addresses[this.addresses.length - 1].livedFromDate);
    this.previousAddressToDate.setValue(toDate);
  }
  get fullAddress() {
    return this.previousAddressForm.get('fullAddress');
  }
  get previousAddress() {
    return this.previousAddressForm.get('previousAddress');
  }
  get previousAddressCity() {
    return this.previousAddressForm.get('previousAddressCity');
  }
  get previousAddressState() {
    return this.previousAddressForm.get('previousAddressState');
  }
  get previousAddressCountry() {
    return this.previousAddressForm.get('previousAddressCountry');
  }
  get previousAddressZipCode() {
    return this.previousAddressForm.get('previousAddressZipCode');
  }
  get previousAddressFromDate() {
    return this.previousAddressForm.get('previousAddressFromDate');
  }
  get previousAddressToDate() {
    return this.previousAddressForm.get('previousAddressToDate');
  }
  onTurnOffAddressAutoLookup() {
    this.turnOffAddressLookup = true;
    this.fullAddress.clearValidators();
    this.fullAddress.updateValueAndValidity();
    this.previousAddress.setValidators([Validators.required]);
    this.previousAddressCity.setValidators([
      Validators.required,
      Validators.pattern("^[A-Za-z-'. ]*$"),
      Validators.maxLength(25)
    ]);
    this.previousAddressState.setValidators([
      Validators.required,
      Validators.pattern(REGEX_LETTERS_SPACES_HYPHENS),
      Validators.maxLength(25)
    ]);
    this.previousAddressCountry.setValidators([Validators.required]);
    this.previousAddressZipCode.setValidators([Validators.required]);
    this.previousAddressForm.patchValue({
      fullAddress: ''
    });
    const input = this.previousAddressText?.nativeElement; // Check if addressText is available
      if (input?.autocompleteInstance) {
        google.maps.event.clearInstanceListeners(input);
        delete input.autocompleteInstance;
      }
  }
  onTurnOnAddressAutoLookup() {
    this.turnOffAddressLookup = false;
  
    // Reset validators for manual address fields
    this.previousAddress.clearValidators();
    this.previousAddressCity.clearValidators();
    this.previousAddressState.clearValidators();
    this.previousAddressCountry.clearValidators();
    this.previousAddressZipCode.clearValidators();
  
    // Add validators for fullAddress
    this.fullAddress.setValidators([Validators.required]);
    this.fullAddress.updateValueAndValidity();
  
    // Reset manual address fields
    this.previousAddressForm.patchValue({
      previousAddress: '',
      previousAddressCity: '',
      previousAddressState: '',
      previousAddressCountry: '',
      previousAddressZipCode: '',
    });
    if (!this.previousAddressText?.nativeElement) {
      this.isGoogleLookupReinitPending = true; // Schedule reinitialization
    } else {
      this.getPlaceAutocomplete(this.previousAddressText);
    }
  }
  getFullAddressErrorMessage(): string {
    return this.fullAddress.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressErrorMessage(): string {
    return this.previousAddress.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressCityErrorMessage(): string {
    const errors = this.previousAddressCity.errors;
    return errors.required
      ? REQUIRED_FIELD_MESSAGE
      : errors.pattern
      ? PATTERN_ERROR_MESSAGE
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : '';
  }
  getPreviousAddressStateErrorMessage(): string {
    const errors = this.previousAddressState.errors;
    return errors.required
      ? REQUIRED_FIELD_MESSAGE
      : errors.pattern
      ? PATTERN_ERROR_MESSAGE
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : '';
  }
  getPreviousAddressCountryErrorMessage(): string {
    const errors = this.previousAddressCountry.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressZipCodeErrorMessage(): string {
    return this.previousAddressZipCode.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressFromDate(): string {
    return this.previousAddressFromDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getPreviousAddressToDate(): string {
    return this.previousAddressToDate.errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }
  chosenMonthHandler(normalizedMonth: Dayjs, datepicker: MatDatepicker<Dayjs>, element?: string): void {
    const date = dayjs()
      .set('month', normalizedMonth.get('month'))
      .set('year', normalizedMonth.get('year'))
      .set('date', 1);
    const formControl = new FormControl(date);
    if (element) {
      if (element == 'previous') {
        this.previousAddressForm.patchValue({
          previousAddressFromDate: formControl.value
        });
      }
      if (element == 'toDateControl') {
        this.previousAddressForm.patchValue({
          previousAddressToDate: formControl.value
        });
      }

      // if (element == 'fromCurrentAddressPicker') {
      //   this.form.patchValue({
      //     fromDate: formControl.value
      //   });
      // }

      // if (element == 'currentToDatePicker') {
      //   this.form.patchValue({
      //     toDate: formControl.value
      //   });
      // }
    }
    datepicker.close();
  }
  onCertnCountrySearch(event: any): void {
    const searchText = event?.target?.value?.trim();

    let countries = this.certnCountries;
    if (searchText) {
      this.countries = countries.filter(country => country.key.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      this.countries = countries;
    }
  }
  onCancel() {
    this.turnOffAddressLookup = false;
    this.resetPreviousAddressValidation();
  }
  resetPreviousAddressValidation() {
    this.fullAddress.setValidators([Validators.required]);
    this.previousAddress.clearValidators();
    this.previousAddressCity.clearValidators();
    this.previousAddressState.clearValidators();
    this.previousAddressCountry.clearValidators();
    this.previousAddressZipCode.clearValidators();
  }
  onSubmit() {
    const model = this.preparePreviousAddressModel();
    this.isContentReady = false;

    this.registrationService
      .addAddress(model)
      .pipe(
        catchError((error: any) => {
          this.isContentReady = true;
          this.cdr.detectChanges(); // Manually trigger change detection
          this.toastService.showError(error?.message);
          this.navigateToListPage();
          return of(false);
        })
      )
      .subscribe(res => {
        if (res) {
          this.isContentReady = true;
          this.cdr.detectChanges(); // Manually trigger change detection
          this.navigateToListPage();
        }
      });
  }
  navigateToListPage() {
    this.location.back();
  }
  countryLowerCaseCheck(country: string): string {
    return this.countryUSorCA.includes(country) ? country.toLowerCase() : country;
  }
  private preparePreviousAddressModel(): ResidenceAddress {
    const formModel = this.previousAddressForm.value;
    const addressModel = this.parseAddress(formModel.fullAddress);
    const model: ResidenceAddress = {
      completeAddress: this.turnOffAddressLookup
        ? `${formModel.previousAddress}, ${formModel.previousAddressCity}, ${formModel.previousAddressState} ${formModel.previousAddressZipCode}, ${formModel.previousAddressCountry}`
        : formModel.fullAddress,
      streetAddress: this.turnOffAddressLookup ? formModel.previousAddress : addressModel.streetAddress,
      city: this.turnOffAddressLookup ? formModel.previousAddressCity : addressModel.city,
      province: this.turnOffAddressLookup ? formModel.previousAddressState : addressModel.state,
      country: this.countryLowerCaseCheck(
        this.turnOffAddressLookup ? formModel.previousAddressCountry : addressModel.country
      ),
      postalCode: this.turnOffAddressLookup ? formModel.previousAddressZipCode : addressModel.zipcode,
      livedFromDate: formModel.previousAddressFromDate
        ? (dayjs(formModel.previousAddressFromDate).format('YYYY-MM-DD') as string)
        : '',
      livedToDate: formModel.previousAddressToDate
        ? (dayjs(formModel.previousAddressToDate).format('YYYY-MM-DD') as string)
        : ''
    };
    if (formModel.id && formModel.id != '') {
      model.id = formModel.id;
    }
    return model;
  }
  private parseAddress(address: string): AddressModel {
    const parsedAddressArr = address?.split(',');
    const addressModel = new AddressModel();
    if (address && parsedAddressArr.length === 4) {
      addressModel.fullAddress = address;
      addressModel.streetAddress = parsedAddressArr[0].trim();
      addressModel.city = parsedAddressArr[1].trim();
      addressModel.state = parsedAddressArr[2].trim();
      addressModel.zipcode = addressModel.state.substring(3, addressModel.state.length).trim();
      addressModel.state = addressModel.state?.split(' ')[0];
      addressModel.country = parsedAddressArr[3].trim();
      if (addressModel.country == 'Canada' || addressModel.country == 'CA') {
        addressModel.country = 'CA';
      }
      if (addressModel.country == 'USA' || addressModel.country == 'US') {
        addressModel.country = 'US';
        addressModel.state = `US-${addressModel.state}`;
      }
    }
    return addressModel;
  }
  private getPlaceAutocomplete(addressText: any): void {
    if (addressText) {
      const autocomplete = new google.maps.places.Autocomplete(addressText?.nativeElement || addressText, {
        componentRestrictions: {
          country: this.countryUSorCA
        },
        types: []
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        if (place) {
          this.zone.run(() => {
            this.fullAddress.setValue(place.formatted_address);
          });
        }
      });
    }
  }
  private createPreviousAddressForm(): FormGroup {
    return this.fb.group({
      fullAddress: ['', Validators.required],
      previousAddress: [''],
      previousAddressCity: [''],
      previousAddressState: [''],
      previousAddressCountry: [''],
      previousAddressZipCode: [''],
      previousAddressFromDate: ['', Validators.required],
      previousAddressToDate: ['', Validators.required]
    });
  }
  private loadGoogleAutoComplete(): void {
    this.getPlaceAutocomplete(this.previousAddressText);
  }
  onCountryChange(): void {
    const selectedCountry = this.previousAddressForm.get('previousAddressCountry').value;

    if (selectedCountry === 'CA') {
      this.provinces = this.canadaProvinces;
    } else if (selectedCountry === 'US') {
      this.provinces = this.usaProvinces;
    } else {
      this.provinces = [];
    }

    // Reset the state/province field when the country changes
    this.previousAddressForm.get('previousAddressState').setValue('');
  }
}

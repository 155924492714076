<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Traffic Convictions</span>
  </div>
  <div *ngIf="!isContentReady">
    <app-skeleton-accident-traffic-records [isAccidentRecord]="false"></app-skeleton-accident-traffic-records>
  </div>
  <div *ngIf="isContentReady">
    <div>
      <mat-error *ngIf="errorMessage" class="error padding-left-md">
        {{ errorMessage }}
      </mat-error>
      <form [formGroup]="noTrafficConvictionsRecordsForm" (ngSubmit)="onSubmitTrafficConvictionRecord(true)"
        [ngClass]="{ disabled: disableNoTrafficConvictions || trafficRecords?.length > 0 }" fxLayout="column"
        class="no-traffic-container">
        <span>I have no traffic conviction in the last <strong>3 years</strong></span>
        <mat-checkbox [disabled]="disableNoTrafficConvictions || trafficRecords?.length > 0"
          formControlName="noConvictions"></mat-checkbox>
      </form>
    </div>

    <div fxLayout class="add-new-container">
      <div fxFlex="60">
        <button mat-button [disabled]="disableAddNewRecord" (click)="onAddNewTrafficConvictionRecord()"
          data-test="add-traffic-record-button">
          <i class="material-icons">add</i> Add New Record
        </button>
      </div>
      <div fxFlex="40" fxLayout>
        <div fxFlex="50">
          <button mat-button *ngIf="openForm"
            [disabled]="(!trafficConvictionForm.dirty || !trafficConvictionForm.valid) && !loading"
            [class.save-disabled]="(!trafficConvictionForm.dirty || !trafficConvictionForm.valid) && !loading"
            (click)="onSubmitTrafficConvictionRecord(false)" data-test="save-new-record">
            Save
          </button>
        </div>
        <div fxFlex="50">
          <button mat-button *ngIf="openForm" class="cancel-button" (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div class="content" fxLayout="column" *ngIf="openForm">
      <div fxLayout="column" class="component-container">
        <form [formGroup]="trafficConvictionForm" fxLayout="column" (ngSubmit)="onSubmitTrafficConvictionRecord(false)">
          <mat-form-field>
            <input matInput readonly placeholder="Date" [matDatepicker]="convictionDate"
              formControlName="convictionDate" [max]="maxDate" (click)="convictionDate.open()" />
            <mat-datepicker-toggle matSuffix [for]="convictionDate" data-test="from-date-form-field">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #convictionDate touchUi="true"> </mat-datepicker>
            <mat-error *ngIf="convictionDate.touched && convictionDate.invalid">
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Violation Type</mat-label>
            <mat-select formControlName="violationType" data-test="nature-of-violation-selector">
              <mat-option *ngFor="let violation of violationTypes" [value]="violation.value"
                data-test="violation-type">{{ violation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="trafficConvictionForm.get('violationType').value === 'other'">
            <input matInput placeholder="Other Violation Details" formControlName="otherViolationType"
              data-test="other-violation-form-field" required />
            <mat-error
              *ngIf="trafficConvictionForm.get('violationType').touched && trafficConvictionForm.get('violationType').invalid">
              {{ getOtherViolationTypeErrorMessage() }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>State of Violation Location</mat-label>
            <mat-select formControlName="stateOfViolation" data-test="state-of-violation-selector">
              <mat-option *ngFor="let state of stateOfViolation" [value]="state.value" data-test="state-name">{{
                state.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="stateOfViolation.touched && stateOfViolation.invalid">
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Penalty</mat-label>
            <mat-select formControlName="penalty" data-test="number-of-penalty-selector">
              <mat-option *ngFor="let penalty of penalty" [value]="penalty.value" data-test="penalty-value">{{
                penalty.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="penalty.touched && penalty.invalid">
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Demerit Points</mat-label>
            <mat-select formControlName="demeritPoints" data-test="demerit-points-selector">
              <mat-option *ngFor="let demerit of demeritPoints" [value]="demerit" data-test="demerit-points-value">{{
                demerit }}</mat-option>
            </mat-select>
            <mat-error *ngIf="demeritPoints.touched && demeritPoints.invalid">
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Notes (Optional)</mat-label>
            <textarea matInput formControlName="notes" maxlength="140" placeholder="Enter any additional notes"
              data-test="notes-field"></textarea>
            <mat-hint align="end">{{ trafficConvictionForm.get('notes').value?.length || 0 }}/140</mat-hint>
            <mat-error *ngIf="trafficConvictionForm.get('notes').hasError('pattern')">
              Invalid characters used.
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="content" fxLayout="column" *ngIf="trafficRecords">
      <ng-container *ngFor="let record of trafficRecords">
        <div class="view-record">
          <div class="info">
            <p class="view-date">{{ record.convictionDate | date: 'dd MMM yyyy' }}</p>
            <p class="view-nature">
              {{ getViolationValue(record.violationType) || 'No traffic conviction in the last 3 years' }}
            </p>
          </div>
          <div class="actions">
            <mat-icon svgIcon="edit-black" (click)="onEdit(record)"
              data-test="edit-traffic-conviction-record"></mat-icon>
            <mat-icon svgIcon="delete-sweep-outlined" (click)="onDelete(record)"
              data-test="delete-traffic-conviction-record"></mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
import { Routes } from '@angular/router';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import {
  ROUTE_LICENSE_STATUS,
  ROUTE_LICENSE_START,
  ROUTE_LICENSE_VERIFICATION,
  ROUTE_LICENSE_UPLOAD
} from './identity-routes';
import { LicenseStatusComponent } from './license-status/license-status.component';
import { IdentityStartComponent } from './identity-start/identity-start.component';
import { IdentityLaunchComponent } from './identity-launch/identity-launch.component';
import { LicenseUploadComponent } from './license-upload/license-upload.component';
export const identityCheckRoutes: Routes = [
  {
    path: ROUTE_LICENSE_START,
    component: IdentityStartComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: ROUTE_LICENSE_VERIFICATION,
    component: IdentityLaunchComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: ROUTE_LICENSE_STATUS,
    component: LicenseStatusComponent,
    canActivate: [DriverGuardService]
  }
];

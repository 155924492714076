<h2>Third Party Authorization</h2>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Managed User Company Name</mat-label>
    <input matInput [formControl]="managedUserCompanyName" required />
  </mat-form-field>
  is a third-party service provider. We are providing safety services to
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name / DBA Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  and we are doing employment applications on behalf of the company.
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Managed User Company Name</mat-label>
    <input matInput [formControl]="managedUserCompanyName" required />
  </mat-form-field>
  is not responsible for dispatch services, payments, conflicts and other issues related to company. I
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  hereby agree with this authorization and fully understood all entries on it and information in it are true and
  completed to the best of my knowledge.
</p>
<div class="form-group">
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
    <button type="button" mat-raised-button color="accent" (click)="insertSignatureToAllEvent()">
      Insert Everywhere
    </button>
    <button type="button" mat-raised-button class="btn-next" (click)="moveToDrugAlcohol()">Next</button>
  </div>
  <mat-radio-group
    [formControl]="driverType"
    required
    class="radio-group"
    [ngClass]="{ error: driverType.hasError('required') }"
  >
    <mat-radio-button value="Driver">Driver</mat-radio-button>
    <mat-radio-button value="Owner-Operator">Owner Operator</mat-radio-button>
  </mat-radio-group>
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 1 of 30</p>

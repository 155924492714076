<h2>Accident Policy</h2>
<p>
  In spite of our best efforts to avoid accidents, we realize they can happen and we must be prepared when they do. If
  you are involved in a vehicle accident, do the following: -
</p>
<ul>
  <li>Stop immediately.</li>
  <li>If you can safely do so, move out of the way of traffic.</li>
  <li>Check other drivers and passengers for injuries.</li>
  <li>
    If you are not injured, protect the accident scene to prevent other vehicles from becoming involved. Put out
    emergency reflectors or flares.
  </li>
  <li>Call for help and the police if necessary.</li>
  <li>Notify your supervisor or manager.</li>
  <li>
    Take photographs of the accident scene, if possible, with the disposable camera kept in the Accident Kit located in
    the vehicle.
  </li>
  <li>Complete the Drivers Report in the Accident Kit.</li>
  <li>Hand out witness cards and get names, addresses, and telephone numbers of witnesses.</li>
  <li>Do not admit liability at the accident scene.</li>
</ul>
<p>
  In many cases, determining fault for an accident is done only after extensive investigation. Be courteous to others at
  the scene, but never take the blame. Let the investigators determine that. Also, do not discuss the details of the
  accident with anyone other than a licensed authority such as a police officer or our insurance company representative,
  without getting prior approval from management.
</p>
<p>Upon returning to the dock, submit the completed Accident Kit to your supervisor.</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 14 of 30</p>

export const ROUTE_ROOT = '';
export const ROUTE_DRIVER_DASHBOARD = '';
export const ROUTE_PROFILE = 'profile';
export const ROUTE_PROFILE_PREFERENCE = `${ROUTE_PROFILE}/preferences`;
export const ROUTE_NOTIFICATIONS = 'notifications';
export const ROUTE_ACCOUNT_SETTINGS = 'accountSettings';
export const ROUTE_REFER_A_FRIEND = 'referFriend';
export const ROUTE_TELL_A_FRIEND = 'tellFriend';
export const ROUTE_ADD_TO_HOME_SCREEN = 'addToHomeScreen';
export const ROUTE_DRIVER_HISTORY = 'driverHistory';
export const ROUTE_REGISTRATION = 'registration';
export const ROUTE_LOADS = 'loads';
export const ROUTE_TRIPS = 'trips';
export const ROUTE_SHIPMENTS = 'shipments';
export const ROUTE_JOBS = 'jobs';
export const ROUTE_UNSUBSCRIBE_EMAIL = 'unsubscribe-email';
export const ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL = 'successful-unsubscribe-email';
export const ROUTE_FAILED_UNSUBSCRIBE_EMAIL = 'failed-unsubscribe-email';
export const ROUTE_ACCOUNT_LOCKED = 'account-locked';
export const ROUTE_DQF = 'driverQualification';
export const ROUTE_DQF_DOCUMENT_UPLOAD = 'documents';
export const ROUTE_DQF_DRIVING_EXPERIENCE = 'drivingExperience';
export const ROUTE_DQF_APPLICATION_EMPLOYMENT = 'applicationEmployment';
export const ROUTE_DQF_ABSTRACT = 'abstract';
export const ROUTE_PERSONAL_INFO = 'personalInfo';
export const ROUTE_PROFILE_IMAGE = 'profilePicture';
export const ROUTE_LICENSE = 'license';
export const ROUTE_RESIDENCE_HISTORY = 'residenceHistory';
export const ROUTE_ADD_RESIDENCE_HISTORY = 'addPreviousAddress';
export const ROUTE_EMPLOYMENT_HISTORY = 'employmentHistory';
export const ROUTE_EMPLOYED_FORM = 'employedForm';
export const ROUTE_UNEMPLOYED_FORM = 'unEmployedForm';
export const ROUTE_ACCIDENT_RECORDS = 'accidentRecords';
export const ROUTE_TRAFFIC_CONVICTIONS = 'trafficConvictions';
export const ROUTE_DQF_DRUG_TEST = 'drugTest';
export const ROUTE_DQF_DOCUMENT_VIEW = 'documentView';
export const ROUTER_DQF_VIEW = `${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_VIEW}`;
export const ROUTE_ON_DUTY_HOURS = 'onDutyHour';
export const ROUTE_DQF_REVIEW = 'review';
export const ROUTE_DQF_DECLARE = 'declare';
export const ROUTE_DQF_UPLOADS = 'uploads';
export const ROUTE_DQF_POLICE_CERTIFICATE = 'policeClearanceCertificate';
export const ROUTE_DQF_PRE_EMPLOYMENT_CERTIFICATE = 'preEmployment';
export const ROUTE_DQF_HOUSE_CLEAR_REPORT = 'clearHouseReport';
export const ROUTE_DQF_BIRTH_LOCATION = 'birthLocation';
export const ROUTE_DQF_CRIMINAL_CONVICTION = 'criminalConviction';
export const ROUTE_DQF_BACKGROUND_CHECK_PROCESSING = 'requestProcessing';
export const ROUTE_DQF_ONTARIO_CVOR_ABSTRACT = 'ontarioCvor';
export const ROUTE_DRIVER_CONSENT = 'share-profile-consent';
export const ROUTE_REPORT_INCIDENT = 'reportIncident';

// route for native signature component
export const ROUTE_DQF_DECLARATION_SIGN = 'declarationSign';

// route for hello sign component
export const ROUTE_DQF_SIGN_DECLARATION = 'signDeclaration';

<div class="header-navigation-back" fxLayout fxLayoutAlign="left">
  <mat-icon data-test="back-arrow-icon" (click)="navigateBack()">arrow_back</mat-icon>
  <span fxFlex class="title">Shipments History</span>
</div>
<div *ngIf="!loading" class="list-container">
  <!-- search filter -->
  <div class="search-container" fxLayout>
    <form class="summary-filters" fxFlex="70">
      <mat-form-field>
        <mat-icon matPrefix svgIcon="magnifying-glass" data-test="search"></mat-icon>
        <span matPrefix *ngIf="!currentSearchInput">Search&nbsp;</span>
        <input matInput (input)="onSearchInput(searchInput.value)" #searchInput [value]="currentSearchInput" />
      </mat-form-field>
    </form>
    <button mat-stroked-button class="btn-filter" fxFlex="15" (click)="navigateToFilterShipments()">
        <mat-icon svgIcon="filter" data-test="filter"></mat-icon>
      </button>
    <button mat-stroked-button class="btn-filter" fxFlex="15" (click)="sortByStatus()">
      <mat-icon svgIcon="sort" data-test="sort"></mat-icon>
    </button>
  </div>
  <div class="tag-container">
    <div *ngFor="let tag of tagsList">
      <span>{{ tag }}</span>
    </div>
  </div>
  <ng-container *ngIf="shipments">
    <div *ngFor="let shipment of shipments">
      <app-shipments-item [shipment]="shipment" [shipmentHistory]="true"></app-shipments-item>
    </div>
  </ng-container>
  <app-no-shipment
    *ngIf="!shipments?.length"
    [message]="noShipmentHistory"
    [isShipmentListPage]="true"
    icon="shipments"
  >
  </app-no-shipment>
</div>

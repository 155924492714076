import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page23-safe-driving-policy',
  templateUrl: './page23-safe-driving-policy.component.html',
  styleUrls: ['../shared.scss', './page23-safe-driving-policy.component.scss']
})
export class Page23SafeDrivingPolicyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Safe Driving Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw driver's name line
    currentPage.drawText("Driver's Name:", {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of the label
    const labelWidth = fonts.regular.widthOfTextAtSize("Driver's Name: ", 12);

    // Add driver name
    const driverName = formValues.driverName || '';
    currentPage.drawText(driverName, {
      x: x + labelWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 30;

    // Draw first paragraph with underlined parts
    const paragraph1Part1 =
      'By signing below, I hereby acknowledge to establish and maintain a standard for safe driving. I am responsible ' +
      'for operating any vehicle in a safe manner and to always comply with all Federal and Provincial requirements ie: all posted ';

    currentY = this.drawWrappedText(currentPage, paragraph1Part1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    // Find the last line position
    const lastLineY = currentY;

    // Add "speed signs" underlined
    const speedSignsText = 'speed signs';
    currentPage.drawText(speedSignsText, {
      x,
      y: lastLineY,
      size: 12,
      font: fonts.regular
    });

    // Draw underline for "speed signs"
    const speedSignsWidth = fonts.regular.widthOfTextAtSize(speedSignsText, 12);
    currentPage.drawLine({
      start: { x, y: lastLineY - 2 },
      end: { x: x + speedSignsWidth, y: lastLineY - 2 },
      thickness: 0.5,
      color: rgb(0, 0, 0)
    });

    // Add " and " after "speed signs"
    const andText = ' and ';
    currentPage.drawText(andText, {
      x: x + speedSignsWidth,
      y: lastLineY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of "and"
    const andTextWidth = fonts.regular.widthOfTextAtSize(andText, 12);

    // Add "seat belt regulations" underlined
    const seatBeltText = 'seat belt regulations';
    currentPage.drawText(seatBeltText, {
      x: x + speedSignsWidth + andTextWidth,
      y: lastLineY,
      size: 12,
      font: fonts.regular
    });

    // Draw underline for "seat belt regulations"
    const seatBeltTextWidth = fonts.regular.widthOfTextAtSize(seatBeltText, 12);
    currentPage.drawLine({
      start: { x: x + speedSignsWidth + andTextWidth, y: lastLineY - 2 },
      end: { x: x + speedSignsWidth + andTextWidth + seatBeltTextWidth, y: lastLineY - 2 },
      thickness: 0.5,
      color: rgb(0, 0, 0)
    });

    // Add ". I will also follow the" after "seat belt regulations"
    currentPage.drawText('. I will also follow the', {
      x: x + speedSignsWidth + andTextWidth + seatBeltTextWidth,
      y: lastLineY,
      size: 12,
      font: fonts.regular
    });

    // Move to next line for the rest of paragraph
    currentY -= 20;

    // Add "Hours-of-Service Rules and not use any Hand-held instrument" underlined
    const hoursRulesText = 'Hours-of-Service Rules and not use any Hand-held instrument';
    currentPage.drawText(hoursRulesText, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Draw underline
    const hoursRulesTextWidth = fonts.regular.widthOfTextAtSize(hoursRulesText, 12);
    currentPage.drawLine({
      start: { x, y: currentY - 2 },
      end: { x: x + hoursRulesTextWidth, y: currentY - 2 },
      thickness: 0.5,
      color: rgb(0, 0, 0)
    });

    // Add final period
    currentPage.drawText('.', {
      x: x + hoursRulesTextWidth,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentY -= 30;

    // Draw second paragraph with company name
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the company name
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Add the remainder of the second paragraph
    const paragraph2 =
      ' is responsible to record all accidents and is the property of the assigned insurance company at that time.';
    currentY = this.drawWrappedText(currentPage, paragraph2, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });

    currentY -= 20;

    // Draw third paragraph with company name
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Add the remainder of the third paragraph
    const paragraph3 =
      ' will establish remedial training, corrective action, and discipline procedures for drivers involved in ' +
      "accidents. This will be at the driver's expense.";
    currentY = this.drawWrappedText(currentPage, paragraph3, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });

    currentY -= 20;

    // Draw fourth paragraph with company name
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Add the remainder of the fourth paragraph
    const paragraph4 = ' also acknowledges safe driving behavior on a yearly basis.';
    currentY = this.drawWrappedText(currentPage, paragraph4, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });

    currentY -= 40;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage23');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { PDFDocument, rgb } from 'pdf-lib';

@Component({
  selector: 'app-page26-handheld-device-policy',
  templateUrl: './page26-handheld-device-policy.component.html',
  styleUrls: ['../shared.scss', './page26-handheld-device-policy.component.scss']
})
export class Page26HandheldDevicePolicyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  // Form controls passed from parent
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;

  // Reference to signature canvas
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  // Signature pad instance
  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeSignaturePad();
    }, 500);
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Handheld & Wireless Device Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw main paragraph content
    const paragraph1 =
      'Distracted driving law makes it illegal for motorists to use hand-held wireless communication devices or hand-held electronic entertainment devices while driving. This law also prohibits viewing display screens unrelated to the driving task such as laptops or DVD players while driving. Law applies to hand-held wireless communications and hand-held electronic entertainment devices. This means that motorists may still use these devices in a "hands-free" manner. To minimize distraction, the use of hands-free wireless devices should be kept to a minimum, used only when necessary, while driving.';
    currentY = this.drawWrappedText(currentPage, paragraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    currentPage.drawText('Examples of hands-free use include:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });
    currentY -= 16;

    const bulletPoints1 = [
      "A cell phone with an earpiece or headset using voice dialing, or plugged into the vehicle's sound system (when equipped)",
      'A global positioning system (GPS) device that is properly secured to the dashboard or another accessible place in the vehicle',
      "A portable audio player that has been plugged into the vehicle's sound system."
    ];

    // Draw bullet points
    for (const point of bulletPoints1) {
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      currentY = this.drawWrappedText(currentPage, point, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 10;
    }
    currentY -= 10;

    const paragraph2 =
      'Drivers should remember that their number one priority is driving safely and obeying the rules of the road. To insure you arrive to your destination safely, Company is requiring all drivers to comply with the following:';
    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    const bulletPoints2 = [
      'Find a safe place to pull off the roadway before placing a call.',
      'If you receive a call while driving, let the call go to voice mail and retrieve the message when safe to do so. Drivers using hands-free telephones must find a safe place to pull off the roadway to place calls unless the cell phone is equipped with a voice dialing function.'
    ];

    // Draw second set of bullet points
    for (const point of bulletPoints2) {
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      currentY = this.drawWrappedText(currentPage, point, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 10;
    }
    currentY -= 20;

    // Draw warning text in bold
    const warningText =
      'ANY DRIVER FOUND GUILTY OF USING HANDHELD & WIRELESS DEVICE, WILL SUBJECT TO FINES AND TERMINATION OF EMPLYMENT WITHOUT ANY NOTICE';
    currentY = this.drawWrappedText(currentPage, warningText, x + 15, currentY, {
      font: fonts.bold,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - 15
    });
    currentY -= 30;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for name
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage26');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box (always draw this as a container whether signature exists or not)
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });
    currentY -= 70;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

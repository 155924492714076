import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page9-medical-declaration',
  templateUrl: './page9-medical-declaration.component.html',
  styleUrls: ['../shared.scss', './page9-medical-declaration.component.scss']
})
export class Page9MedicalDeclarationComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Always create a new page for Page 9 content
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(newPage, position, formValues, fonts, formValues.currentPageCount);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Medical Declaration';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    newPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph
    const paragraph1 =
      'On March 30, 1999, Transport Canada and U.S. Federal Highway Administration (FHWA) entered into a reciprocal ' +
      'agreement regarding the physical requirements for a Canadian driver of a commercial vehicle in the U.S., as ' +
      'currently contained in the Federal Motor Carrier Safety Regulations, Part 391.41 et seq., and vice versa. The ' +
      'reciprocal agreement removes the requirement for a Canadian driver to carry a copy of a medical examiners ' +
      `certificate indicating that the driver is physically qualified. (In effect, the existence of a valid driver's ` +
      'license issued by the Province within Canada is deemed to be proof that a driver is physically qualified to ' +
      'drive in the U.S.). However, FHWA will not recognize an Canadian Province issued license if the driver has ' +
      'certain medical conditions, and those conditions would prohibit him from driving in the U.S.';

    currentY = this.drawWrappedText(newPage, paragraph1, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw paragraph with inline field
    newPage.drawText('1. I', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Draw driver name inline with proper spacing
    newPage.drawText(formValues.driverName || '', {
      x: x + 30,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for name
    newPage.drawLine({
      start: { x: x + 30, y: currentY - 3 },
      end: { x: x + 180, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Continue the text after the field
    const restOfText =
      '(Driver or O/O name) certify that I am qualified to operate a commercial motor vehicle in the United States. I ' +
      'further certify that.';

    currentY = this.drawWrappedText(newPage, restOfText, x + 190, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - 190
    });
    currentY -= 20;

    // Draw numbered points 2-5
    const point2 = '2. I have no clinical diagnosis of diabetes currently requiring insulin for control.';
    newPage.drawText(point2, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });
    currentY -= 20;

    const point3 = '3. I have no established medical history or clinical diagnosis of epilepsy.';
    newPage.drawText(point3, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });
    currentY -= 20;

    const point4 =
      '4. I do not have impaired hearing. (A driver must be able to first perceive a forced whispered voice in the ' +
      'better ear at not less than 5 feet with or without the use of a hearing aid, or does not have an average hearing ' +
      'loss in the better ear greater than 40 decibels at 500 Hz, 1000 Hz, or 2000 Hz with or without a hearing aid ' +
      'when tested by an audiometric device calibrated to American national Standard Z24.5-1951).';

    currentY = this.drawWrappedText(newPage, point4, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    const point5 =
      '5. I have not been issued a waiver by the Province of Ontario allowing me to operate a commercial motor vehicle ' +
      'pursuant to Section 20 or 21 of Ontario Regulation 340/94.';

    currentY = this.drawWrappedText(newPage, point5, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw final paragraph
    const finalParagraph =
      'I further agree to immediately inform the Safety or Operations Manager should my medical status change, or if I ' +
      'can no longer Certify conditions as mentioned above.';

    currentY = this.drawWrappedText(newPage, finalParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 25;

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage9');
    if (signature) {
      newPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    newPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    // Draw driver name field
    newPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    newPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    newPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    newPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    newPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

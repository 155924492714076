import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';
@Component({
  selector: 'app-page2728-reefer-policy',
  templateUrl: './page2728-reefer-policy.component.html',
  styleUrls: ['../shared.scss', './page2728-reefer-policy.component.scss']
})
export class Page2728ReeferPolicyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page for Reefer Policy
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Reefer Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw Purpose section
    currentPage.drawText('Purpose:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Draw purpose text
    const purposeText =
      'To reduce claims related to temperature sensitive freight and provide guidance to drivers who transport refrigerated products.';

    currentY = this.drawWrappedText(currentPage, purposeText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Draw Responsibilities section
    currentPage.drawText('Responsibilities:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Draw responsibilities text
    const responsibilitiesText =
      'It is the responsibility of the Company management to enforce and maintain this policy. It is the responsibility of the Company drivers to follow these procedures thus reducing the potential for cargo claims.';

    currentY = this.drawWrappedText(currentPage, responsibilitiesText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Draw Policy and Procedures section
    currentPage.drawText('Policy and Procedures:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Draw procedures text
    const proceduresText =
      'When the Company dispatch accepts a refrigerated load, they must inform the driver if they are picking up and empty trailer to load live load or a preloaded trailer. Dispatch must also include the set point temperature in their dispatch instructions to the driver(s).';

    currentY = this.drawWrappedText(currentPage, proceduresText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Draw Live Loading section
    currentPage.drawText('Live Loading:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 16;

    // Draw live loading intro
    const liveLoadingIntro = 'When a driver picks up an empty trailer for live load he/she must:';

    currentY = this.drawWrappedText(currentPage, liveLoadingIntro, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 16;

    // Draw bullet point 1
    currentPage.drawText('•', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Draw bullet point 1 text
    const bullet1Text = 'Conduct a pre-trip inspection on the trailer or reefer.';

    currentY = this.drawWrappedText(currentPage, bullet1Text, x + 15, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - 15
    });

    // Draw numbered list under bullet point 1
    const numberedItems = [
      'The reefer display will have a button for conducting a pre-trip inspection.',
      'Once the pre-trip is activated it will run through a series of checks to confirm the reefer is working properly.',
      'The driver must also check the oil, antifreeze, belts or hoses to ensure fluid levels are good and there is no cuts or holes in the belts or hoses.',
      'If there is a reefer fuel tank this needs to be checked and fuel added if below ¾ full.'
    ];

    for (let i = 0; i < numberedItems.length; i++) {
      currentY -= 16;

      // Draw number
      currentPage.drawText(`${i + 1}.`, {
        x: x + 25,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw text for numbered item
      currentY = this.drawWrappedText(currentPage, numberedItems[i], x + 40, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 40
      });
    }

    currentY -= 20;

    // Draw pretrip paragraph
    const pretripText =
      'A reefer pre-trip must be completed every day. Any "reefer codes" will show on the reefer display screen. The driver must write the codes down and report them to the Company dispatch immediately.';

    currentY = this.drawWrappedText(currentPage, pretripText, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 16;

    // Draw remaining bullet points
    const remainingBullets = [
      'Make sure the trailer is clean and free of any debris or odor/smell.',
      'If dirty the driver must sweep or wash the trailer.',
      'If the trailer has an odor/smell it will often be refused for loading by the shipper so the trailer must be washed. Washing however, does not always remove the odor/smell.',
      'If the odor persists coffee works very well to remove odors.'
    ];

    for (const bulletText of remainingBullets) {
      // Check if we need a new page
      if (currentY < margin + 50) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      currentY = this.drawWrappedText(currentPage, bulletText, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });

      currentY -= 16;

      // If it's the last bullet, add the sub-numbered list
      if (bulletText.includes('coffee works very well')) {
        // Check if we need a new page for the coffee steps
        if (currentY < margin + 150) {
          currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
          currentY = pageHeight - margin;
        }

        const coffeeSteps = [
          'Sprinkle a generous amount of coffee directly onto the metal floor of the reefer trailer.',
          'Close the trailer doors and leave for at least few hours.',
          'Open the trailer doors and check if the odor remains If it does, then leave the coffee in the trailer for longer.',
          'Once the odor is no longer present in the trailer can be swept or washed to remove all traces of coffee.',
          'Keep in mind coffee has a strong odor as well so you must regularly check the trailer to determine if the original odor is gone. If coffee is left too long the trailer will now smell like coffee and can be refused for loading.'
        ];

        for (let i = 0; i < coffeeSteps.length; i++) {
          // Check if we need to add a page in the middle of the steps
          if (currentY < margin + 30) {
            currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
            currentY = pageHeight - margin;
          }

          // Draw number
          currentPage.drawText(`${i + 1}.`, {
            x: x + 25,
            y: currentY,
            size: 12,
            font: fonts.regular
          });

          // Draw text for numbered item
          currentY = this.drawWrappedText(currentPage, coffeeSteps[i], x + 40, currentY, {
            font: fonts.regular,
            fontSize: 12,
            lineHeight: 16,
            maxWidth: maxWidth - 40
          });

          currentY -= 16;
        }
      }
    }

    // Check if we need a new page before drawing the complete temperature paragraph
    if (currentY < margin + 100) {
      // Increased space requirement to ensure entire paragraph fits
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw complete temperature paragraph without artificially splitting it
    const completeTempParagraph =
      'If the temperature inside the trailer is a lot higher or lower than the "set point" temperature the trailer must be cooled and warmed before loading any product. If product is loaded onto a hot trailer the reefer will not be able to cool down the freight properly and it will often result in a claim. If loaded onto a trailer that is too cold, it then damage or freeze the product.';

    currentY = this.drawWrappedText(currentPage, completeTempParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 15; // Use slightly reduced spacing

    // Continue with next paragraphs as a natural flow
    const paragraphs = [
      'Once at the shipper turn off the reefer if not backed into a sealed and temperature-controlled warehouse. If the trailer is backed into the sealed door and temperature-controlled warehouse the trailer must be left on.',

      'When loading products such as produced the product should be probed with the temperature probe or laser gun to confirm what the loading temperature of the product is. This should be done while loading, whenever possible, and at least 3 times during the loading process. If the probe temperature is higher or lower than the "set point" temperature the Company dispatch must be called. Probe temperatures must be noted on the shipper BOL (bill of lading).',

      'Many shippers use a "template" which is a recorder put in the trailer after loading to record the temperature of the load while in transit. Upon delivery the customer will download the template information. If the reefer "set point" has not been maintained the load can be refused.',

      'Once loaded, ALL shipping documents must be reviewed to confirm what temperature the reefer "set point" is. Make sure you check if the temperature is to be in Celsius "C" or Fahrenheit "F". Also check what reefer cycle needs to be set – "continuous" or "cycle sentry". Continuous cycle the reefer does not usually stop because the freight is very temperature sensitive and the "set point" must be maintained. On Cycle sentry the reefer will stop and then restart once the temperature goes either too high or too low from the "set point". If driver is not sure check will the shipper and then they must call the Company dispatch for confirmation.'
    ];

    for (const paragraph of paragraphs) {
      // Check if we need a new page
      if (currentY < margin + 50) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      currentY = this.drawWrappedText(currentPage, paragraph, x, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth
      });

      currentY -= 15; // Reduced spacing from 20px to 15px for more natural flow
    }

    // Check if we need a new page for bold paragraph
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Add bold paragraph
    const boldParagraph =
      'Once the driver is loaded and paperwork is checked a picture of the reefer temperature on the display screen must be taken and sent to the Company dispatch. At least once per day, except while off duty/sleeping, a picture of the reefer temperature must be sent to the Company dispatch. Failure to follow these procedures will result in disciplinary action per the company policy.';

    currentY = this.drawWrappedText(currentPage, boldParagraph, x, currentY, {
      font: fonts.bold,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 15; // Reduced spacing

    // Add remaining paragraphs
    const finalParagraphs = [
      `Once the driver has arrived at the customer if any cargo issues are reported verbally or on the BOL the driver must stay at the customer's yard and call the Company dispatch immediately.`,

      'The procedures are the same and when picking up a pre-loaded trailer except sweeping, washing and precooling/warming the trailer. If the trailer is pre-sealed, then probing of the product would not be possible.',

      'Failure to follow this policy and the procedures will result in disciplinary action per our company policy. When in doubt check with dispatch for clarification and/or instructions.'
    ];

    for (const paragraph of finalParagraphs) {
      // Check if we need a new page
      if (currentY < margin + 50) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      currentY = this.drawWrappedText(currentPage, paragraph, x, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth
      });

      currentY -= 15; // Reduced spacing
    }

    // Check if we need a new page before the final bold text and signature
    if (currentY < margin + 160) {
      // Sufficient space for bold text and signature
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Add final bold instruction with proper spacing
    const finalBoldText =
      'Driver needs to contact dispatch when there are any temperature discrepancies, cargo claims, the reefer stops working or any other issues that will affect the temperature-controlled freight.';

    currentY = this.drawWrappedText(currentPage, finalBoldText, x, currentY, {
      font: fonts.bold,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    // Add space before signature
    currentY -= 30;

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage28');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

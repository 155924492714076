<h2>Medical Declaration</h2>
<p>
  On March 30, 1999, Transport Canada and U.S. Federal Highway Administration (FHWA) entered into a reciprocal agreement
  regarding the physical requirements for a Canadian driver of a commercial vehicle in the U.S., as currently contained
  in the Federal Motor Carrier Safety Regulations, Part 391.41 et seq., and vice versa. The reciprocal agreement removes
  the requirement for a Canadian driver to carry a copy of a medical examiners certificate indicating that the driver is
  physically qualified. (In effect, the existence of a valid driver's license issued by the Province within Canada is
  deemed to be proof that a driver is physically qualified to drive in the U.S.). However, FHWA will not recognize an
  Canadian Province issued license if the driver has certain medical conditions, and those conditions would prohibit him
  from driving in the U.S.
</p>
<p>
  1. I
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  (Driver or O/O name) certify that I am qualified to operate a commercial motor vehicle in the United States. I further
  certify that.
</p>
<p>
  2. I have no clinical diagnosis of diabetes currently requiring insulin for control.<br />
  3. I have no established medical history or clinical diagnosis of epilepsy.<br />
  4. I do not have impaired hearing. (A driver must be able to first perceive a forced whispered voice in the better ear
  at not less than 5 feet with or without the use of a hearing aid, or does not have an average hearing loss in the
  better ear greater than 40 decibels at 500 Hz, 1000 Hz, or 2000 Hz with or without a hearing aid when tested by an
  audiometric device calibrated to American national Standard Z24.5-1951).<br />
  5. I have not been issued a waiver by the Province of Ontario allowing me to operate a commercial motor vehicle
  pursuant to Section 20 or 21 of Ontario Regulation 340/94.
</p>
<p>
  I further agree to immediately inform the Safety or Operations Manager should my medical status change, or if I can no
  longer Certify conditions as mentioned above.
</p>
<div class="form-group">
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 9 of 30</p>

<p>49 CFR Part 382.413 and Part 40.25:</p>
<p>
  § 382.413 and 40.25 Must an employer check on the drug and alcohol testing record of employees it is intending to use
  to perform safety-sensitive duties
</p>
<ul class="page-4-list">
  <li>
    Yes, as an employer, you must, after obtaining an employee's written consent, request the information about the
    employee listed in paragraph (b) of this section. This requirement applies only to employees seeking to begin
    performing safety-sensitive duties for you for the first time (i.e., a new hire, an employee transfers into a safety
    sensitive position). If the employee refuses to provide this written consent, you must not permit the employee to
    perform safety-sensitive functions.
  </li>
  <li>
    You must request the information listed in this paragraph (b) from DOT-regulated employers who have employed the
    employee during any period during the two years before the date of the employee's application or transfer:
    <ol class="list-decimal">
      <li>Alcohol tests with a result of 0.04 or higher alcohol concentration.</li>
      <li>Verified positive drug tests.</li>
      <li>Refusals to be tested (including verified adulterated or substituted drug test results);</li>
      <li>Other violations of DOT agency drug and alcohol testing regulations; and</li>
      <li>
        With respect to any employee who violated a DOT drug and alcohol regulation, documentation of the employee's
        successful completion of DOT return-to-duty requirements (including follow-up tests). If the previous employer
        does not have information about the return-do-duty process (e.g., an employer who did not hire an employee who
        tested positive on a pre-employment test), you must seek to obtain this information from the employee.
      </li>
    </ol>
  </li>
  <li>
    The information obtained from a previous employer includes any drug or alcohol test information obtained from
    previous employers under this section or other applicable DOT agency regulations.
  </li>
  <li>
    If feasible, you must obtain and review this information before the employee first performs safetysensitive
    functions. If this is not feasible, you must obtain and review the information as soon as possible. However, you
    must not permit the employee to perform safety-sensitive functions after 30 days from the date on which the employee
    first performed safety-sensitive functions, unless you have obtained or made and documented a good faith effort to
    obtain this information.
  </li>
  <li>
    If you obtain information that the employee has violated a DOT agency drug and alcohol regulation, you must not use
    the employee to perform safety-sensitive functions unless you also obtain information that the employee has
    subsequently complied with the return-to-duty requirements of Subpart O of this part and DOT agency drug and alcohol
    regulations.
  </li>
  <li>
    You must provide to each of the employers from whom you request information under paragraph (b) of this section
    written consent for the release of the information cited in paragraph (a) of this section.
  </li>
  <li>
    The release of information under this section must be in any written form (e.g., fax, e-mail, letter) that ensures
    confidentiality. As the previous employer, you must maintain a written record of the information released, including
    the date, the party to whom it was released, and a summary of the information provided.
  </li>
</ul>
<p class="page-number">Page 4 of 30</p>

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page17-truck-trailer-insurance',
  templateUrl: './page17-truck-trailer-insurance.component.html',
  styleUrls: ['../shared.scss', './page17-truck-trailer-insurance.component.scss']
})
export class Page17TruckTrailerInsuranceComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading for insurance section
    const headingText = 'Truck and Trailer Insurance';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 14);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 14,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph with inline company name
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 11,
      font: fonts.bold
    });

    // Calculate width of the company name to position the rest of the text
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 11);

    // Continue with the paragraph text
    const paragraphText = ' maintains appropriate coverage on all equipment.';

    currentY = this.drawWrappedText(currentPage, paragraphText, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });
    currentY -= 10;

    // Draw second paragraph
    const paragraph2 =
      'The deductible amount is stated on the Owner Operator agreement. Owner/Operators are encouraged to consider our ' +
      '"Buy-Down" coverage to mitigate unexpected costs in the case of a major mechanical breakdown or an accident.';

    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 10;

    // Draw third paragraph
    const paragraph3 =
      'The Safety Department will review your Permit Book with you so that you can locate the Insurance Certificate and ' +
      '"pink slip" should the need arise.';

    currentY = this.drawWrappedText(currentPage, paragraph3, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 10;

    // Draw fourth paragraph
    const paragraph4 = 'Driver must check certificate of insurance and pick slip for vehicle in truck binder.';

    currentY = this.drawWrappedText(currentPage, paragraph4, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw second heading
    const heading2Text = 'PERSONAL PROTECTIVE EQUIPMENT';
    const heading2Width = fonts.bold.widthOfTextAtSize(heading2Text, 16);
    const center2X = x + (maxWidth - heading2Width) / 2;

    currentPage.drawText(heading2Text, {
      x: center2X,
      y: currentY,
      size: 14,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw fifth paragraph with inline company name
    const paragraph5Start =
      'All drivers are required to have with them at all times: safety vest, safety glasses, work boots, gloves and ' +
      'hard hat. It is the responsibility of each employee to utilize such safety equipment when on the premises of ' +
      'customers as may be required by such in the course of their duties on behalf of ';

    currentY = this.drawWrappedText(currentPage, paragraph5Start, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });

    // Find the right position for company name in the paragraph
    const lastLineWords = paragraph5Start.split(' ');
    const lastWord = lastLineWords[lastLineWords.length - 1];
    const lastWordWidth = fonts.regular.widthOfTextAtSize(lastWord + ' ', 12);

    // Draw company name after the last word in the paragraph
    const lastLine = currentY;
    currentPage.drawText(companyName, {
      x: x + lastWordWidth,
      y: lastLine,
      size: 11,
      font: fonts.bold
    });

    // Continue with rest of paragraph
    const paragraph5End =
      '. Any notification received by company in regards to an employee breach of our customers rules and regulations ' +
      'will result in disciplinary action.';

    const companyNameWidth2 = fonts.bold.widthOfTextAtSize(companyName, 11);

    currentY = this.drawWrappedText(currentPage, paragraph5End, x + lastWordWidth + companyNameWidth2, lastLine, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth: maxWidth - lastWordWidth - companyNameWidth2
    });
    currentY -= 20;

    // Check if we need a new page
    if (currentY < margin + 200) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw third heading
    const heading3Text = 'NO PASSENGERS POLICY';
    const heading3Width = fonts.bold.widthOfTextAtSize(heading3Text, 14);
    const center3X = x + (maxWidth - heading3Width) / 2;

    currentPage.drawText(heading3Text, {
      x: center3X,
      y: currentY,
      size: 14,
      font: fonts.bold
    });
    currentY -= 20;

    // Draw sixth paragraph with inline company name
    const paragraph6Start =
      'This policy extends to any "ride along" family or friends of our drivers. Anyone who is not an employee or ' +
      'contractor of ';

    currentY = this.drawWrappedText(currentPage, paragraph6Start, x, currentY, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth
    });

    // Find position for company name in paragraph
    const lastLineWords6 = paragraph6Start.split(' ');
    const lastWord6 = lastLineWords6[lastLineWords6.length - 1];
    const lastWordWidth6 = fonts.regular.widthOfTextAtSize(lastWord6 + ' ', 11);

    // Draw company name
    const lastLine6 = currentY;
    currentPage.drawText(companyName, {
      x: x + lastWordWidth6,
      y: lastLine6,
      size: 11,
      font: fonts.bold
    });

    // Continue with rest of paragraph
    const paragraph6End =
      ' or assigned to the trip by Dispatch or the Safety Department are prohibited from riding in our equipment, or any ' +
      'equipment running under the authority of company. In addition, pets are prohibited from riding in any ' +
      'company-owned equipment without Management Approval and a signed damages waiver. Damages from pets WILL BE ' +
      'CHARGED to the responsible driver including cleaning, replacement of carpeting, etc. Due to liability and ' +
      'security concerns, Company will strictly enforce this No Passengers or Pets Policy. Any violation is also in ' +
      'violation of our C-TPAT policies. No family, friends, or other passengers are to be transported. Liability lays ' +
      'solely with the driver for legal responsibilities, costs, and damages.';

    currentY = this.drawWrappedText(currentPage, paragraph6End, x + lastWordWidth6 + companyNameWidth, lastLine6, {
      font: fonts.regular,
      fontSize: 11,
      lineHeight: 16,
      maxWidth: maxWidth - lastWordWidth6 - companyNameWidth
    });
    currentY -= 30;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 11,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 11,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage17');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Page1ThirdPartyAuthComponent } from './components/page1-third-party-auth/page1-third-party-auth.component';
import { Page2ApplicationInformationComponent } from './components/page2-application-information/page2-application-information.component';
import { DeclarationFormComponent } from './declaration-form/declaration-form.component';
import { Page3ToReadSignedComponent } from './components/page3-to-read-signed/page3-to-read-signed.component';
import { Page4CfrInfoComponent } from './components/page4-cfr-info/page4-cfr-info.component';
import { Page5CfrInfoComponent } from './components/page5-cfr-info/page5-cfr-info.component';
import { Page7DrugAlcoholComponent } from './components/page7-drug-alcohol/page7-drug-alcohol.component';
import { Page8DriveCertificationComponent } from './components/page8-drive-certification/page8-drive-certification.component';
import { Page9MedicalDeclarationComponent } from './components/page9-medical-declaration/page9-medical-declaration.component';
import { Page10TolerancePolicyComponent } from './components/page10-tolerance-policy/page10-tolerance-policy.component';
import { Page12DriverRequirementsComponent } from './components/page12-driver-requirements/page12-driver-requirements.component';
import { Page13DriverResponsibilityComponent } from './components/page13-driver-responsibility/page13-driver-responsibility.component';
import { Page14AccidentPolicyComponent } from './components/page14-accident-policy/page14-accident-policy.component';
import { Page16VehicleMaintenanceComponent } from './components/page16-vehicle-maintenance/page16-vehicle-maintenance.component';
import { Page15HoursOfServiceComponent } from './components/page15-hours-of-service/page15-hours-of-service.component';
import { Page17TruckTrailerInsuranceComponent } from './components/page17-truck-trailer-insurance/page17-truck-trailer-insurance.component';
import { Page18HealthSafetyComponent } from './components/page18-health-safety/page18-health-safety.component';
import { Page19TrafficTicketsComponent } from './components/page19-traffic-tickets/page19-traffic-tickets.component';
import { Page20InspectionViolationsComponent } from './components/page20-inspection-violations/page20-inspection-violations.component';
import { Page21LicenseStatusComponent } from './components/page21-license-status/page21-license-status.component';
import { Page22ConfidentialityPolicyComponent } from './components/page22-confidentiality-policy/page22-confidentiality-policy.component';
import { Page23SafeDrivingPolicyComponent } from './components/page23-safe-driving-policy/page23-safe-driving-policy.component';
import { Page25SeatBeltPolicyComponent } from './components/page25-seat-belt-policy/page25-seat-belt-policy.component';
import { Page24EntryNumbersSealPolicyComponent } from './components/page24-entry-numbers-seal-policy/page24-entry-numbers-seal-policy.component';
import { Page26HandheldDevicePolicyComponent } from './components/page26-handheld-device-policy/page26-handheld-device-policy.component';
import { Page2728ReeferPolicyComponent } from './components/page2627-reefer-policy/page2728-reefer-policy.component';
import { Page2930PspAuthorizationComponent } from './components/page2930-psp-authorization/page2930-psp-authorization.component';

@NgModule({
  declarations: [
    Page1ThirdPartyAuthComponent,
    Page2ApplicationInformationComponent,
    DeclarationFormComponent,
    Page3ToReadSignedComponent,
    Page4CfrInfoComponent,
    Page5CfrInfoComponent,
    Page7DrugAlcoholComponent,
    Page8DriveCertificationComponent,
    Page9MedicalDeclarationComponent,
    Page10TolerancePolicyComponent,
    Page12DriverRequirementsComponent,
    Page13DriverResponsibilityComponent,
    Page14AccidentPolicyComponent,
    Page15HoursOfServiceComponent,
    Page16VehicleMaintenanceComponent,
    Page17TruckTrailerInsuranceComponent,
    Page18HealthSafetyComponent,
    Page19TrafficTicketsComponent,
    Page20InspectionViolationsComponent,
    Page21LicenseStatusComponent,
    Page22ConfidentialityPolicyComponent,
    Page23SafeDrivingPolicyComponent,
    Page24EntryNumbersSealPolicyComponent,
    Page25SeatBeltPolicyComponent,
    Page26HandheldDevicePolicyComponent,
    Page2728ReeferPolicyComponent,
    Page2930PspAuthorizationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    FormsModule,
    MatInputModule
  ],
  exports: [
    Page1ThirdPartyAuthComponent,
    Page2ApplicationInformationComponent,
    DeclarationFormComponent,
    Page3ToReadSignedComponent,
    Page4CfrInfoComponent,
    Page5CfrInfoComponent,
    Page7DrugAlcoholComponent,
    Page8DriveCertificationComponent,
    Page9MedicalDeclarationComponent,
    Page10TolerancePolicyComponent,
    Page12DriverRequirementsComponent,
    Page13DriverResponsibilityComponent,
    Page14AccidentPolicyComponent,
    Page15HoursOfServiceComponent,
    Page16VehicleMaintenanceComponent,
    Page17TruckTrailerInsuranceComponent,
    Page18HealthSafetyComponent,
    Page19TrafficTicketsComponent,
    Page20InspectionViolationsComponent,
    Page21LicenseStatusComponent,
    Page22ConfidentialityPolicyComponent,
    Page23SafeDrivingPolicyComponent,
    Page24EntryNumbersSealPolicyComponent,
    Page25SeatBeltPolicyComponent,
    Page26HandheldDevicePolicyComponent,
    Page2728ReeferPolicyComponent,
    Page2930PspAuthorizationComponent
  ]
})
export class DeclarationSignModule {}

<h2>Seat Belt Policy</h2>
<p>
  <strong>Introduction:</strong>
  Because seat belts are the best protection against injury or death in a crash,
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  seat belt use policy is extremely important. The simple act of buckling up reduces the chance of serious injury or
  death by 40% to 60% and can save money for
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  .
</p>
<p>
  <strong>Policy:</strong>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  recognizes that seat belts are extremely effective in preventing injuries and loss of life. We care about our
  employees, and want to make sure that no one is injured or killed in a tragedy that could have been prevented by the
  use of seat belts. Employees are strongly encouraged to use their seat belts off the job as well. The purpose of this
  policy is to establish mandatory belt use as an organizational priority and designate responsibility for
  implementation and enforcement.
</p>
<p>
  <strong>Policy:</strong>
  The seat belt use policy applies to all employees and occupants of any vehicle driven by employees, including rentals
  and personal vehicles when used on official company business.
</p>
<p><strong>Responsibility:</strong></p>
<p>
  Managers and supervisors must demonstrate their commitment to this policy by communicating it to their employees,
  monitoring compliance, evaluating effectiveness and taking disciplinary action against violations.
</p>
<p><strong>Belt Systems Maintenance:</strong></p>
<p>
  Seat belts in all
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  vehicles are to be maintained so that they are clean and in good working order.
</p>
<p><strong>Employee Education:</strong></p>
<p>
  Information on the benefits of seat belts, as well as
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  commitment to their use will be emphasized in new employee orientation, training, handbooks, safety rules and internal
  communications. Employees will be required to sign a pledge to use seat belts as a condition of employment. Managers
  and supervisors are encouraged to promote and provide time for employee awareness programs to explain the benefits of
  seat belts both on- and off-the-job.
</p>
<p><strong>Enforcement:</strong></p>
<p>
  Seat belt use is such a common, healthy practice that the need to actively enforce this policy should be rare.
  However, the policy will be enforced the same as other
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  and employees who violate it will be subject to disciplinary actions which may include dismissal. Managers and
  supervisors have an obligation to encourage and routinely monitor usage. The driver of the vehicle is responsible for
  enforcing seat belt use by all occupants.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 25 of 30</p>

<h2>MOTOR VEHICLE DRIVER'S CERTIFICATION OF COMPLIANCE WITH DRIVER LICENCE REQUIREMENTS</h2>
<p>
  MOTOR CARRIER INSTRUCTIONS: The requirements in Part 383 apply to every driver who operates in intrastate, interstate,
  or foreign commerce and operates a vehicle weighing 26,001 pounds or more, can transport more than 15 people, or
  transports hazardous materials that require placarding.
</p>
<p>
  The requirements in Part 391 apply to every driver who operates in interstate commerce and operates a vehicle weighing
  10,001 pounds or more, can transport more than 15 people, or transports hazardous materials that require placarding.
</p>
<p>
  DRIVER REQUIREMENTS: Parts 383 and 391 of the Federal Motor Carrier Safety Regulations contain some requirements that
  you as a driver must comply with. These requirements are in effect as of July 1, 1987. They are as follows:
</p>
<ol>
  <li>
    <strong>POSSESS ONLY ONE LICENSE:</strong> You, as a commercial vehicle driver, may not possess more than one motor
    vehicle operator's license. If you have more than one license, keep the license from your state of residence and
    return the additional licenses to the states that issued them. DESTROYING a license does not close the record in the
    state that issued it; you must notify the state. If a multiple license has been lost, stolen, or destroyed, close
    your record by notifying the state of issuance that you no longer want to be licensed by that state.
  </li>
  <li>
    <strong>NOTIFICATION OF LICENSE SUSPENSION, REVOCATION OR CANCELLATION:</strong> Sections 391 .1 5(b)(2) and 383.33
    of the Federal Motor Carrier Safety Regulations require that you notify your employer the NEXT BUSINESS DAY of any
    revocation or suspension of your driver's license. In addition, Section 383.31 requires that any time you violate a
    state or local traffic law (other than parking), you must report it within 30 days to: 1) your employing motor
    carrier, and 2) the state that issued your license (If the violation occurs in a state other than the one which
    issued your license). The notification to both the employer and state must be in writing.
  </li>
</ol>
<p>The following license is the only one I will possess: -</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver's License No.</mat-label>
    <input matInput [formControl]="driverLicenseNoCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Expiry Date</mat-label>
    <input matInput type="date" [formControl]="expiryDateCtrl" required />
  </mat-form-field>
</div>
<p>DRIVER CERTIFICATION: I certify that I have read and understood the above requirements.</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 8 of 30</p>

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page13-driver-responsibility',
  templateUrl: './page13-driver-responsibility.component.html',
  styleUrls: ['../shared.scss', './page13-driver-responsibility.component.scss']
})
export class Page13DriverResponsibilityComponent implements OnInit, PDFCapableComponent {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  constructor() {}

  ngOnInit(): void {}
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw first heading
    const headingText1 = 'Driver Responsibility';
    const headingWidth1 = fonts.bold.widthOfTextAtSize(headingText1, 16);
    const centerX1 = x + (maxWidth - headingWidth1) / 2;

    currentPage.drawText(headingText1, {
      x: centerX1,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw driver responsibility list items
    const responsibilityItems = [
      'All motor vehicle violations and accidents in company and personal vehicles, must be reported to management as ' +
        'soon after the incident as practical. Failure to do so may result in disciplinary action up to and including ' +
        'termination of employment.',

      "It is every driver's responsibility to drive defensively to avoid accidents, and safely maintain each vehicle " +
        'under the driver\'s control. Defensive Driving is defined as "Driving to avoid accidents in spite of the ' +
        'incorrect actions of others, and the adverse conditions of weather, visibility, light, and traffic that the ' +
        'driver may encounter on the road". Failure to operate a vehicle safely may result in a suspension of driving ' +
        'duties or termination of employment.',

      'The Accident Review Committee will review each vehicle accident and a determination of preventability made. ' +
        'Drivers with unacceptable driving records will be subject to a progressive disciplinary procedure. Poor ' +
        'driving behavior can result in remedial training, days off without pay, reassignment to a non-driving job or ' +
        'termination of employment.'
    ];

    // Draw each item with bullet point
    for (const item of responsibilityItems) {
      // Check if we need a new page
      if (currentY < margin + 150) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw item text with indentation
      currentY = this.drawWrappedText(currentPage, item, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 15; // Space between items
    }

    // Check if we need a new page for cell phone policy
    if (currentY < margin + 250) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw second heading
    const headingText2 = 'CELL PHONE USAGE POLICY';
    const headingWidth2 = fonts.bold.widthOfTextAtSize(headingText2, 16);
    const centerX2 = x + (maxWidth - headingWidth2) / 2;

    currentPage.drawText(headingText2, {
      x: centerX2,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw intro paragraph
    const introParagraph =
      'Driving is a serious responsibility that demands and deserves your full attention. Driver distractions may occur ' +
      'anytime, anywhere. A distraction is anything that takes your attention away from driving, such as cell phone ' +
      'use.';

    currentY = this.drawWrappedText(currentPage, introParagraph, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Cell phone policy list items
    const cellPhoneItems = [
      {
        text:
          'The use of COMPANY issued cell phones or radios should be used on an as needed basis, and only when it is safe ' +
          'to do so. Personal use of these units is prohibited.',
        isBold: false
      },
      {
        text:
          'The use of PERSONAL cell phones while on duty is strictly prohibited while driving a company vehicle and/or ' +
          'any other safety sensitive position.',
        isBold: true
      },
      {
        text:
          'If a driver is found to be using cell phone while driving or pulled by the authorities, driver will be ' +
          'responsible to pay all fines/violations/ticket and may be termination will be applied.',
        isBold: true
      }
    ];

    // Draw each cell phone policy item
    for (const item of cellPhoneItems) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw item text with indentation and appropriate font
      currentY = this.drawWrappedText(currentPage, item.text, x + 15, currentY, {
        font: item.isBold ? fonts.bold : fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 15; // Space between items
    }

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

<h2>TRAFFIC TICKETS, CITATIONS, FINES, LEGAL REPRESENTATION AND COSTS</h2>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  makes it a policy to fight all tickets for all charges on behalf of all drivers.
</p>
<p>
  While we try to keep these costs to a minimum, we choose the best method to defend tickets, citations, etc.
</p>
<p class="mt-1 mb-1">
  <strong
    >ALL fees and related fines will be sole responsibility of the offending party and will be deducted as per your
    contact or employment agreement.</strong
  >
</p>
<p class="mt-1 mb-1">
  Any driver who receives a Ticket, Citation, or Fine while operating a commercial motor vehicle must notify Safety
  within 1 hour of receiving the ticket, citation, or fine. Submit a copy of the document to the Safety Department
  within 24 hours.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 19 of 30</p>

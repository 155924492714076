import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { RegistrationService } from '../shared/registration.service';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { WorkPlaceSafety } from 'src/app/shared/models/workplacesafety.model';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DEFAULT_ERROR_MESSAGE } from 'src/app/shipments/shared/constants';
import { DownloadDocument } from 'src/app/shared/models/download-document.model';
import { RegistrationState } from '../state/registration.state';
import { Store } from '@ngxs/store';

const MinMaxLengthFieldMessage = (min: number, max: number) => `Must be between ${min} and ${max} characters`;
const NUMBER_LETTERS_PATTERN_MESSAGE = 'Only letters, numbers are acceptable';
const INCORPORATION_NAME_PATTERN_MESSAGE = "Only letters, numbers, spaces and  , . - & ' are acceptable";
const INCORPORATION_ADDRESS_PATTERN_MESSAGE = 'Must not include < >';
const SOURCE_TEXT = 'self';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';
const WSIB_CLEARANCE_DOC = 'WsibClearance';
const POLICY_DOCUMENT_DOC = 'DisabilityBenefitPolicyDocument';
const PROOF_INSURANCE_DOC = 'DisabilityBenefitProofInsurance';
const HAVE_WORKPLACE_SAFETY_ACCOUNT = 'haveWorkplaceSafetyAccount';
const HAVE_DISABILITY_BENEFIT = 'haveDisabilityBenefit';
const DOCUMENTS_TYPES = [WSIB_CLEARANCE_DOC, POLICY_DOCUMENT_DOC, PROOF_INSURANCE_DOC];
const DOCUMENT_SECTION_MAPPING = {
  [WSIB_CLEARANCE_DOC]: HAVE_WORKPLACE_SAFETY_ACCOUNT,
  [POLICY_DOCUMENT_DOC]: HAVE_DISABILITY_BENEFIT,
  [PROOF_INSURANCE_DOC]: HAVE_DISABILITY_BENEFIT
};

@Component({
  selector: 'app-incorporation',
  templateUrl: './incorporation.component.html',
  styleUrls: ['./incorporation.component.scss']
})
export class IncorporationComponent implements OnInit {
  incorpForm = new FormGroup({});
  REQUIRED_FIELD_MESSAGE = 'You must enter a value';
  haveWorkPlaceSafetyAccountText = HAVE_WORKPLACE_SAFETY_ACCOUNT;
  haveDisabilityBenefitText = HAVE_DISABILITY_BENEFIT;
  wsibClearanceText = WSIB_CLEARANCE_DOC;
  disabilityBenefitPolicyDocumentText = POLICY_DOCUMENT_DOC;
  disabilityBenefitProofInsuranceText = PROOF_INSURANCE_DOC;
  loading: boolean;
  errorMessage: string;
  acceptTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
  isCreateMode: boolean;
  notificationRes: any;
  presentDate: Date = new Date();
  isDocumentsDeleted: any = {
    [WSIB_CLEARANCE_DOC]: false,
    [POLICY_DOCUMENT_DOC]: false,
    [PROOF_INSURANCE_DOC]: false
  };
  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly dateTimeService: DateTimeService,
    private readonly store: Store
  ) {
    this.loading = false;
    this.createForm();
  }

  ngOnInit(): void {
    this.getIncorporationDetails();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getIncorporationDetails(): void {
    this.subs.add(
      this.registrationService.selectWorkPlaceSafety().subscribe((incorporation: WorkPlaceSafety) => {
        if (incorporation.id) {
          // get notification details
          let notificationState = this.store.selectSnapshot(RegistrationState.notification);
          this.notificationRes = {
            [WSIB_CLEARANCE_DOC]:
              notificationState.notifications.find(el => el.type === WSIB_CLEARANCE_DOC)?.id ?? undefined,
            [POLICY_DOCUMENT_DOC]:
              notificationState.notifications.find(el => el.type === POLICY_DOCUMENT_DOC)?.id ?? undefined,
            [PROOF_INSURANCE_DOC]:
              notificationState.notifications.find(el => el.type === PROOF_INSURANCE_DOC)?.id ?? undefined
          };
          this.populateForm(incorporation);
          this.isCreateMode = false;
        } else {
          this.isCreateMode = true;
        }
      })
    );
  }

  createForm(): void {
    this.incorpForm = this.fb.group({
      incorporationNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern('^$|^[a-zA-Z0-9]{1,20}$')
        ]
      ],
      craNumber: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(20),
          Validators.pattern('^$|^[a-zA-Z0-9]{1,20}$')
        ]
      ],
      incorporationName: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9- ,&.'\\s]+$")]],
      incorporationAddress: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern('^[^<>]{1,100}$')]
      ],
      haveWorkplaceSafetyAccount: ['', Validators.required],
      haveDisabilityBenefit: ['', Validators.required],
      WsibClearance: [''],
      DisabilityBenefitPolicyDocument: [''],
      DisabilityBenefitProofInsurance: [''],
      id: ['']
    });
  }

  get incorporationNumber() {
    return this.incorpForm.get('incorporationNumber');
  }
  get craNumber() {
    return this.incorpForm.get('craNumber');
  }
  get incorporationName() {
    return this.incorpForm.get('incorporationName');
  }
  get incorporationAddress() {
    return this.incorpForm.get('incorporationAddress');
  }
  get haveWorkplaceSafetyAccount() {
    return this.incorpForm.get('haveWorkplaceSafetyAccount');
  }
  get haveDisabilityBenefit() {
    return this.incorpForm.get('haveDisabilityBenefit');
  }
  get WsibClearance() {
    return this.incorpForm.get('WsibClearance');
  }
  get DisabilityBenefitPolicyDocument() {
    return this.incorpForm.get('DisabilityBenefitPolicyDocument');
  }
  get DisabilityBenefitProofInsurance() {
    return this.incorpForm.get('DisabilityBenefitProofInsurance');
  }

  getIncorporationNumberErrorMessage() {
    const errors = this.incorporationNumber.errors;
    return errors && errors.required
      ? this.REQUIRED_FIELD_MESSAGE
      : errors.minlength || errors.maxlength
      ? MinMaxLengthFieldMessage(1, 20)
      : errors.pattern
      ? NUMBER_LETTERS_PATTERN_MESSAGE
      : '';
  }

  getCRANumberErrorMessage() {
    const errors = this.craNumber.errors;
    return errors && errors.required
      ? this.REQUIRED_FIELD_MESSAGE
      : errors.minlength || errors.maxlength
      ? MinMaxLengthFieldMessage(1, 20)
      : errors.pattern
      ? NUMBER_LETTERS_PATTERN_MESSAGE
      : '';
  }

  getIncorporationNameErrorMessage() {
    const errors = this.incorporationName.errors;
    return errors && errors.required
      ? this.REQUIRED_FIELD_MESSAGE
      : errors.pattern
      ? INCORPORATION_NAME_PATTERN_MESSAGE
      : '';
  }

  getIncorporationAddressErrorMessage() {
    const errors = this.incorporationAddress.errors;
    return errors && errors.required
      ? this.REQUIRED_FIELD_MESSAGE
      : errors.minlength || errors.maxlength
      ? MinMaxLengthFieldMessage(1, 100)
      : errors.pattern
      ? INCORPORATION_ADDRESS_PATTERN_MESSAGE
      : '';
  }

  getHaveWorkplaceSafetyAccountErrorMessage() {
    const errors = this.haveWorkplaceSafetyAccount.errors;
    return errors && errors.required ? this.REQUIRED_FIELD_MESSAGE : '';
  }

  getHaveDisabilityBenefitErrorMessage() {
    const errors = this.haveDisabilityBenefit.errors;
    return errors && errors.required ? this.REQUIRED_FIELD_MESSAGE : '';
  }

  private populateForm(model: WorkPlaceSafety): void {
    this.incorpForm.patchValue({
      incorporationNumber: model.incorporationNumber,
      incorporationName: model.incorporationName,
      incorporationAddress: model.incorporationAddress,
      haveWorkplaceSafetyAccount: model.haveWorkplaceSafetyAccount,
      haveDisabilityBenefit: model.haveDisabilityBenefit,
      WsibClearance: '',
      DisabilityBenefitProofInsurance: '',
      DisabilityBenefitPolicyDocument: '',
      id: model.id
    });
    if (model?.craNumber) {
      this.incorpForm.patchValue({
        craNumber: model.craNumber
      });
    }
    if (model.haveWorkplaceSafetyAccount) {
      this.incorpForm.patchValue({
        WsibClearance: this.notificationRes.WsibClearance ?? ''
      });
      this.WsibClearance.setValidators([Validators.required]);
    }
    if (model.haveDisabilityBenefit) {
      this.incorpForm.patchValue({
        DisabilityBenefitProofInsurance: this.notificationRes.DisabilityBenefitProofInsurance ?? '',
        DisabilityBenefitPolicyDocument: this.notificationRes.DisabilityBenefitPolicyDocument ?? ''
      });
      this.DisabilityBenefitPolicyDocument.setValidators([Validators.required]);
       this.DisabilityBenefitProofInsurance.setValidators([Validators.required]);
    }
    this.WsibClearance.updateValueAndValidity();
  }

  onWSIBorBenefitsChange(type: string, value: boolean): void {
    if (type === HAVE_WORKPLACE_SAFETY_ACCOUNT && value) {
      this.WsibClearance.setValidators([Validators.required]);
      this.WsibClearance.updateValueAndValidity();
      if (!this.isDocumentsDeleted[WSIB_CLEARANCE_DOC] && this.notificationRes?.[WSIB_CLEARANCE_DOC]) {
        this.WsibClearance.setValue(this.notificationRes[WSIB_CLEARANCE_DOC]);
      }
    } else if (type === HAVE_WORKPLACE_SAFETY_ACCOUNT && !value) {
      this.WsibClearance.setValidators([]);
      this.WsibClearance.updateValueAndValidity();
    } else if (type === HAVE_DISABILITY_BENEFIT && value) {
      this.DisabilityBenefitPolicyDocument.setValidators([Validators.required]);
      if (!this.isDocumentsDeleted[POLICY_DOCUMENT_DOC] && this.notificationRes?.[POLICY_DOCUMENT_DOC]) {
        this.DisabilityBenefitPolicyDocument.setValue(this.notificationRes[POLICY_DOCUMENT_DOC]);
      }
      this.DisabilityBenefitPolicyDocument.updateValueAndValidity();

      this.DisabilityBenefitProofInsurance.setValidators([Validators.required]);
      if (!this.isDocumentsDeleted[PROOF_INSURANCE_DOC] && this.notificationRes?.[PROOF_INSURANCE_DOC]) {
        this.DisabilityBenefitProofInsurance.setValue(this.notificationRes[PROOF_INSURANCE_DOC]);
      }
      this.DisabilityBenefitProofInsurance.updateValueAndValidity();
    } else if (type === HAVE_DISABILITY_BENEFIT && !value) {
      this.DisabilityBenefitPolicyDocument.setValidators([]);
      this.DisabilityBenefitPolicyDocument.updateValueAndValidity();

      this.DisabilityBenefitProofInsurance.setValidators([]);
      this.DisabilityBenefitProofInsurance.updateValueAndValidity();
    }
  }

  onFileChange($event: any, type: string): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.incorpForm.get(type).patchValue(file);
    };
  }

  viewDocument(type: string): void {
    if (this.isCreateMode || this.loading) {
      return;
    }
    if (this.notificationRes?.[type] && !this.isDocumentsDeleted[type]) {
      this.registrationService.downloadDocument(this[type].value).subscribe(response => {
        this.downloadDocument(response);
      });
    }
  }

  downloadDocument(document: DownloadDocument) {
    if (document && document.fileUrl) {
      window.open(document.fileUrl);
    }
  }

  deleteDocuments(type: string): void {
    if (this.loading) {
      return;
    }
    if (this.notificationRes?.[type]) {
      this.isDocumentsDeleted[type] = true;
    }
    this[type].setValue('');
    this[type].updateValueAndValidity();
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    if (this.incorpForm.valid) {
      this.loading = true;
      this.incorpForm.disable();
      const model = this.prepareSaveModel();
      const formData = this.incorpForm.value;
      if (model.id) {
        const workplaceSafetyId = model.id;
        delete model.id;
        this.subs.add(
          this.registrationService
            .updateIncorporation(model, workplaceSafetyId)
            .pipe(
              catchError((error: any) => {
                this.loading = false;
                this.errorMessage = error?.message ?? DEFAULT_ERROR_MESSAGE;
                this.incorpForm.enable();
                return of(false);
              })
            )
            .subscribe(() => {
              this.errorMessage = undefined;
              this.loading = false;
              this.deleteAndUploadDocumentsProcess(formData);
            })
        );
      } else {
        model.id = undefined;

        this.subs.add(
          this.registrationService
            .saveIncorporation(model)
            .pipe(
              catchError((error: any) => {
                this.loading = false;
                this.errorMessage = error?.message ?? DEFAULT_ERROR_MESSAGE;
                this.incorpForm.enable();
                return of(false);
              })
            )
            .subscribe(() => {
              this.errorMessage = undefined;
              this.uploadAllDocuments(DOCUMENTS_TYPES, formData);
            })
        );
      }
    } else {
      // Mark form controls as touched to trigger error display
      this.markFormGroupAsTouched(this.incorpForm);
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  private prepareSaveModel(): WorkPlaceSafety {
    const formModel = this.incorpForm.value;
    const model: WorkPlaceSafety = {
      incorporationNumber: formModel.incorporationNumber as string,
      craNumber: formModel.craNumber as string,
      incorporationName: formModel.incorporationName as string,
      incorporationAddress: formModel.incorporationAddress as string,
      haveWorkplaceSafetyAccount: formModel.haveWorkplaceSafetyAccount as boolean,
      haveDisabilityBenefit: formModel.haveDisabilityBenefit as boolean,
      id: formModel.id as string
    };

    return model;
  }

  deleteAndUploadDocumentsProcess(formData) {
    const deleteDocumentsList$: any[] = [];
    const uploadDocumentsList: any[] = [];
    DOCUMENTS_TYPES.forEach(doc => {
      if (this.notificationRes?.[doc] && this.isDocumentsDeleted[doc] && this[DOCUMENT_SECTION_MAPPING[doc]].value) {
        deleteDocumentsList$.push(this.registrationService.deleteDocument(this.notificationRes[doc]));
      }
      // if docs has file with type and name
      if (formData[doc].type && formData[doc].name) {
        uploadDocumentsList.push(doc);
      }
    });
    if (deleteDocumentsList$.length) {
      this.loading = true;
      forkJoin([...deleteDocumentsList$])
        .pipe(
          first(),
          catchError((error: any) => {
            this.errorMessage = undefined;
            this.errorMessage = error?.message ?? DEFAULT_ERROR_MESSAGE;
            this.incorpForm.enable();
            return of(false);
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(res => {
          if (res) {
            this.loading = false;
            this.uploadAllDocuments(uploadDocumentsList, formData);
          }
        });
    } else {
      this.uploadAllDocuments(uploadDocumentsList, formData);
    }
  }

  uploadAllDocuments(docTypes: any, formData: any) {
    const documentsList$: any[] = [];
    docTypes.forEach(doc => {
      if (this[DOCUMENT_SECTION_MAPPING[doc]].value) {
        const model = this.prepareDocumentSaveModel(doc, formData);
        documentsList$.push(this.registrationService.saveDocument(model, this.incorpForm.get(doc).value));
      }
    });
    if (documentsList$.length) {
      this.loading = true;
      forkJoin([...documentsList$])
        .pipe(
          first(),
          catchError((error: any) => {
            this.errorMessage = undefined;
            this.errorMessage = error?.message ?? DEFAULT_ERROR_MESSAGE;
            this.incorpForm.enable();
            return of(false);
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(res => {
          this.incorpForm.enable();
          this.onNavigateToProfile();
        });
    } else {
      this.incorpForm.enable();
      this.onNavigateToProfile();
    }
  }

  private prepareDocumentSaveModel(type: string, formData: any): DocumentRequest {
    const extension =
      formData[type].name
        ?.split('.')
        .pop()
        .toLowerCase() ?? type;

    const model = {
      extension: extension,
      source: SOURCE_TEXT,
      generationDate: this.dateTimeService.formatDateTime(this.presentDate.toUTCString(), ISO_8601),
      reportType: type
    };

    return model as DocumentRequest;
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFHelpers, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page1-third-party-auth',
  templateUrl: './page1-third-party-auth.component.html',
  styleUrls: ['../shared.scss', './page1-third-party-auth.component.scss']
})
export class Page1ThirdPartyAuthComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() carrierCompanyNameCtrl: FormControl;
  @Input() managedUserCompanyName: FormControl;
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() driverType: FormControl;

  @Output() insertAllSignatures = new EventEmitter<void>();
  @Output() moveToNextPage = new EventEmitter<void>();

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  insertSignatureToAllEvent() {
    this.insertAllSignatures.emit();
  }
  moveToDrugAlcohol() {
    this.moveToNextPage.emit();
  }
  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { x, y, pageWidth } = position;
    const maxWidth = pageWidth - 2 * position.margin;
    let currentY = y;

    const headingText = 'Third Party Authorization';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;
    // Draw heading
    page.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw paragraph with inline fields
    const paragraph = [
      { type: 'field', value: formValues.managedUserCompanyName, width: 180 },
      { type: 'text', text: ' is a third-party service provider. We are providing safety services to ' },
      { type: 'field', value: formValues.carrierCompanyName, width: 180 },
      { type: 'text', text: ' and we are doing employment applications on behalf of the company. ' },
      { type: 'field', value: formValues.managedUserCompanyName, width: 180 },
      {
        type: 'text',
        text: ' is not responsible for dispatch services, payments, conflicts and other issues related to company. I '
      },
      { type: 'field', value: formValues.driverName, width: 180 },
      {
        type: 'text',
        text:
          ' hereby agree with this authorization and fully understood all entries on it and information in it are true and completed to the best of my knowledge.'
      }
    ];

    // Draw the paragraph with fields
    let lineX = x;
    for (const segment of paragraph) {
      if (segment.type === 'text') {
        // Split text into words
        const words = segment.text.split(' ');
        for (const word of words) {
          const wordWidth = fonts.regular.widthOfTextAtSize(word + ' ', 12);

          // Check if we need to wrap to next line
          if (lineX + wordWidth > x + maxWidth) {
            lineX = x;
            currentY -= 16;
          }

          // Draw the word
          page.drawText(word + ' ', {
            x: lineX,
            y: currentY,
            size: 12,
            font: fonts.regular
          });
          lineX += wordWidth;
        }
      } else if (segment.type === 'field') {
        const fieldWidth = segment.width || 150;

        // Check if field needs to wrap to next line
        if (lineX + fieldWidth > x + maxWidth) {
          lineX = x;
          currentY -= 16;
        }

        // Draw inline field value
        page.drawText(segment.value || '', {
          x: lineX,
          y: currentY,
          size: 12,
          font: fonts.bold
        });

        // Draw underline
        page.drawLine({
          start: { x: lineX - 5, y: currentY - 3 },
          end: { x: lineX + fieldWidth, y: currentY - 3 },
          thickness: 1,
          color: rgb(0, 0, 0)
        });

        lineX += fieldWidth;
      }
    }

    currentY -= 40;

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage1');
    if (signature) {
      page.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    page.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    const userType = formValues.driverType || 'Driver';
    page.drawText(`${userType} Name:`, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });
    page.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });
    page.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    // Draw date field
    page.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    page.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    page.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Total height used
    const height = y - currentY + 70;

    return { height };
  }
}

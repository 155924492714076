<h2>Entry Numbers/Seal Policy</h2>
<p>
  Driver's Name:
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
</p>
<p>
  By signing below, I hereby acknowledge that I <strong>WILL NOT</strong> arrive at any border without an entry number
  or multiple entry numbers. I am responsible to make sure that prior to arriving at a border I have an entry number or
  multiple entry numbers either from Dispatch or I have looked them up myself. An entry number or multiple entry numbers
  are needed for all shipments.
</p>
<p>Remember if you have multiple shipments, you will need multiple entry numbers.</p>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  and I are responsible for getting an entry number or entry numbers prior to arrival at any border.
</p>
<p><strong>Seals - Policy</strong></p>
<ul>
  <li>
    All trailers in transit to the US or returning to Canada MUST have a
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    C-TPAT certified security seal securing the trailer
  </li>
  <li>Seals are to be applied to the right trailer door prior to leaving the customer's facility</li>
  <li>
    If the customer provides a seal, it must be used, as well as a
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    seal
  </li>
  <li>The Driver is not to leave the shipper until he communicates with Dispatch the seal number</li>
  <li>All loaded trailers dropped at any of our terminals must be sealed</li>
  <li>
    Any time that a loaded trailer is left unattended (regardless of the amount of time involved) the door seal(s) must
    be checked for tampering prior to departing
  </li>
  <li>
    If a seal has to be broken for any reason the driver MUST contact Operations first. Never break a seal without
    Operations instruction.
  </li>
  <li>Seals that are found tampered with must be reported immediately to Operations and take a picture</li>
  <li>Seals that have been removed must be destroyed so they are not able to be reused.</li>
</ul>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  will establish remedial training, corrective action, and discipline procedures for drivers who arrive at any border
  without an entry number or multiple entry numbers/unsealed trailers. Drivers will be responsible for cost of the fines
  for crossing the border without an entry number or multiple entry numbers/unsealed trailers. Fines start at $5000.00
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 24 of 30</p>

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LicenseDataService } from 'src/app/new-license-scan/license-data.service';
import { ImageCompressService } from 'src/app/shared/utils/image-compress.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Location } from '@angular/common';

const FRONT_LICENSE_DEFAULT_NAME = 'front-license';
const BACK_LICENSE_DEFAULT_NAME = 'back-license';
const MAX_FILE_SIZE = 2.5 * 1024 * 1024; // 5MB

@Component({
  selector: 'app-license-upload',
  templateUrl: './license-upload.component.html',
  styleUrls: ['./license-upload.component.scss']
})
export class LicenseUploadComponent implements OnDestroy {
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  // Image states
  documentPicture: File;
  loading = false;
  showCrop = false;
  imageChangedEvent: any;
  acceptTypesPicture = 'image/png,image/jpg,image/jpeg';
  imageFormat: string;

  // Cropper properties
  canvasRotation = 0;
  transform: ImageTransform = {};
  private rotationTimeout: any;
  isRotating = false;

  // API and UI states
  isApiLoading = false;
  isFrontSave = false;
  isPreviewLoading = false;

  // Image data
  finalImageFiles: File[] = [];
  frontImage: File;
  backImage: File;
  frontImageUrl: SafeUrl;
  backImageUrl: SafeUrl;

  // Preview loading tracking
  private imageLoadCount = 0;
  private readonly PREVIEW_IMAGE_MAX_SIZE = 800; // Maximum dimension in pixels

  constructor(
    private readonly router: Router,
    private readonly domSanitizer: DomSanitizer,
    private readonly licenseDataService: LicenseDataService,
    private readonly registrationService: RegistrationService,
    private readonly imageCompressService: ImageCompressService,
    private readonly toastService: ToastService,
    private readonly location: Location
  ) {}

  getHeaderTitle(): string {
    if (this.finalImageFiles?.length === 2) {
      return 'Review';
    }
    return this.isFrontSave ? 'Upload Back License' : 'Upload Front License';
  }

  openGallery(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  async onProcessPictureFile(event: any) {
    if (!event.target.files?.length) return;

    try {
      const [file] = event.target.files;

      if (!file.type.startsWith('image/')) {
        this.toastService.showError('Please select a valid image file');
        return;
      }

      // Handle large files
      if (file.size > MAX_FILE_SIZE) {
        const compressedFile = await this.imageCompressService.compressImage(file, 0.6);
        this.handleImageFile(compressedFile);
      } else {
        this.handleImageFile(file);
      }
    } catch (error) {
      this.toastService.showError('Error processing image. Please try again.');
      this.resetFileInput();
    }
  }

  private handleImageFile(file: File) {
    const extension = file.name
      .split('.')
      .pop()
      .toLowerCase();
    this.imageFormat = extension || file.type.split('/')[1];

    // Create a new event-like object with the processed file
    const processedEvent = {
      target: { files: [file] }
    };

    this.documentPicture = file;
    this.loading = true;
    this.showCrop = true;
    this.imageChangedEvent = processedEvent;
  }

  async onImageCropped(event: ImageCroppedEvent) {
    if (!event?.base64) return;

    try {
      const name = this.isFrontSave ? BACK_LICENSE_DEFAULT_NAME : FRONT_LICENSE_DEFAULT_NAME;
      const imageFile = new File([base64ToFile(event.base64)], name, { type: `${this.imageFormat}` });

      // Optimize image for preview
      const optimizedFile = await this.optimizeImageForPreview(imageFile);
      const url = await this.readFileAsDataURL(optimizedFile);

      this.isPreviewLoading = true;

      if (!this.isFrontSave) {
        this.frontImage = imageFile; // Keep original for upload
        this.frontImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      } else {
        this.backImage = imageFile; // Keep original for upload
        this.backImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      }
    } catch (error) {
      this.toastService.showError('Unable to process image. Please try again');
      this.resetLicense();
    }
  }

  private async optimizeImageForPreview(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        try {
          const canvas = document.createElement('canvas');
          let { width, height } = img;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height && width > this.PREVIEW_IMAGE_MAX_SIZE) {
            height = (height * this.PREVIEW_IMAGE_MAX_SIZE) / width;
            width = this.PREVIEW_IMAGE_MAX_SIZE;
          } else if (height > this.PREVIEW_IMAGE_MAX_SIZE) {
            width = (width * this.PREVIEW_IMAGE_MAX_SIZE) / height;
            height = this.PREVIEW_IMAGE_MAX_SIZE;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to blob with reduced quality for preview
          canvas.toBlob(
            blob => {
              if (blob) {
                resolve(new File([blob], file.name, { type: 'image/jpeg' }));
              } else {
                reject(new Error('Failed to create preview image'));
              }
            },
            'image/jpeg',
            0.7 // 70% quality
          );
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = () => reject(new Error('Failed to load image'));
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      // Clean up object URL after image loads or on error
      img.onload = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(file);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        reject(new Error('Failed to load image'));
      };
    });
  }

  onPreviewImageLoad() {
    this.imageLoadCount++;
    if (this.imageLoadCount >= 2) {
      // Both images loaded
      this.isPreviewLoading = false;
      this.imageLoadCount = 0;
    }
  }

  onPreviewImageError() {
    this.toastService.showError('Error loading preview image');
    this.isPreviewLoading = false;
    this.imageLoadCount = 0;
  }

  onImageLoaded() {
    try {
      this.loading = false;
    } catch (error) {
      this.toastService.showError('Error loading image. Please try again.');
      this.resetLicense();
    }
  }

  onCropperReady() {
    this.loading = false;
  }

  private handleRotation(direction: number) {
    if (this.isRotating) return;

    this.isRotating = true;
    this.loading = true;

    if (this.rotationTimeout) {
      clearTimeout(this.rotationTimeout);
    }

    requestAnimationFrame(() => {
      this.canvasRotation += direction;
      this.flipAfterRotate();

      this.rotationTimeout = setTimeout(() => {
        this.isRotating = false;
        this.loading = false;
      }, 300);
    });
  }

  rotateLeft() {
    this.handleRotation(-1);
  }

  rotateRight() {
    this.handleRotation(1);
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onFinishCrop() {
    if (!this.isFrontSave) {
      this.finalImageFiles.push(this.frontImage);
      this.saveLicense();
      this.showCrop = false;
      this.loading = false;
      this.resetFileInput();
      this.finalImageFiles = [];
    } else {
      this.finalImageFiles.push(this.backImage);
      this.saveLicense();
      this.showCrop = false;
      this.loading = false;
      this.resetFileInput();
      this.finalImageFiles = [];
    }
  }

  onCancelCrop() {
    this.showCrop = false;
    this.loading = false;

    if (!this.isFrontSave) {
      this.frontImage = undefined;
      this.frontImageUrl = undefined;
    } else {
      this.backImage = undefined;
      this.backImageUrl = undefined;
    }

    this.resetFileInput();
  }

  resetLicense() {
    this.isFrontSave = false;
    this.finalImageFiles = [];
    this.frontImage = undefined;
    this.frontImageUrl = undefined;
    this.backImage = undefined;
    this.backImageUrl = undefined;
    this.showCrop = false;
    this.loading = false;
    this.isPreviewLoading = false;
    this.imageLoadCount = 0;
    this.resetFileInput();
  }

  private resetFileInput() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  onLoadImageFailed() {
    this.loading = false;
    this.toastService.showError('Failed to load image. Please try again');
    this.resetFileInput();
  }

  private readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Create a new image to check dimensions
        const img = new Image();
        img.onload = () => {
          resolve(reader.result as string);
          URL.revokeObjectURL(img.src); // Clean up
        };
        img.onerror = () => {
          reject(new Error('Failed to load image'));
          URL.revokeObjectURL(img.src); // Clean up
        };
        img.src = reader.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  saveLicense() {
    if (this.finalImageFiles?.length > 1) return;

    this.isApiLoading = true;
    const frontLicense = this.finalImageFiles.find(file => file.name === FRONT_LICENSE_DEFAULT_NAME);
    const backLicense = this.finalImageFiles.find(file => file.name === BACK_LICENSE_DEFAULT_NAME);

    let saveModel;
    if (frontLicense) {
      saveModel = {
        frontScanImageExtension: frontLicense.type
      };
    } else if (backLicense) {
      saveModel = {
        backScanImageExtension: backLicense.type
      };
    }
    const licensePresent = this.isLicensePresent();
    if (licensePresent) {
      const license = this.registrationService.getLicenseStore();
      this.licenseDataService
        .updateLicense(saveModel, license.id)
        .pipe(
          first(),
          tap(
            response => {
              if (response?.frontScanImageUrl) {
                response.backScanImagePresent = true;
              }
              if (response?.backScanImageUrl) {
                response.frontScanImagePresent = true;
              }
              this.registrationService.updateLicenseStore(response);
              this.uploadLicenseImages(response, backLicense);
            },
            catchError(error => {
              return of(false);
            })
          )
        )
        .subscribe();
    } else {
      this.licenseDataService
        .uploadLicense(saveModel)
        .pipe(
          first(),
          tap(response => {
            if (response) {
              if (response?.frontScanImageUrl) {
                response.backScanImagePresent = true;
              }
              if (response?.backScanImageUrl) {
                response.frontScanImagePresent = true;
              }
              this.registrationService.updateLicenseStore(response);
              this.uploadLicenseImages(response, frontLicense);
            }
          }),
          catchError(error => {
            this.toastService.showError('Failed to upload license. Please try again');
            return of(false);
          })
        )
        .subscribe(() => {
          this.isFrontSave = true;
        });
    }
  }
  onNavigateBack(): void {
    this.location.back();
  }
  navigateToProfile() {
    this.registrationService.navigateToProfile();
  }
  private uploadLicenseImages(response: any, licenseFile: File) {
    if (licenseFile.name === FRONT_LICENSE_DEFAULT_NAME) {
      this.licenseDataService
        .uploadFrontLicenseImage(licenseFile, response)
        .pipe(
          first(),
          catchError(error => {
            this.toastService.showError('Failed to upload license image. Please try again');
            return of(false);
          }),
          finalize(() => {
            this.isApiLoading = false;
          })
        )
        .subscribe();
    } else if (licenseFile.name === BACK_LICENSE_DEFAULT_NAME) {
      this.licenseDataService
        .uploadBackLicenseImage(licenseFile, response)
        .pipe(
          first(),
          tap(() => {
            this.toastService.showSuccess('License uploaded successfully');
            this.navigateToProfile();
          }),
          catchError(error => {
            this.toastService.showError('Failed to upload license image. Please try again');
            return of(false);
          }),
          finalize(() => {
            this.isApiLoading = false;
          })
        )
        .subscribe();
    }
  }
  private isLicensePresent() {
    const license = this.registrationService.getLicenseStore();
    return license?.frontScanImagePresent;
  }
  ngOnDestroy() {
    // Clear timeouts and memory
    if (this.rotationTimeout) {
      clearTimeout(this.rotationTimeout);
    }

    // Clear object URLs
    if (this.frontImageUrl) {
      URL.revokeObjectURL(this.frontImageUrl.toString());
    }
    if (this.backImageUrl) {
      URL.revokeObjectURL(this.backImageUrl.toString());
    }

    // Clear file data
    this.frontImage = null;
    this.backImage = null;
    this.documentPicture = null;
    this.imageChangedEvent = null;
  }
}

<h2>Zero Tolerance & Disciplinary Policy</h2>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  promotes fair and constructive treatment of unacceptable conduct or work performance. This is accomplished in part
  through a progressive discipline process. The purpose of this policy is to explain when and how progressive discipline
  generally be applied. Progressive discipline process is carried out with the intent to improve behaviour.
</p>
<ul>
  <li>Consumption of Drugs, Alcohol & Cannabis before coming to work & at work,</li>
  <li>
    Use of cell phone or any other electronic device while driving, loading, and unloading is prohibited, we can ask any
    time for the phone bill from you,
  </li>
  <li>Verbal abuse</li>
  <li>
    Display of aggression directed towards staff, co-workers, customers, or anyone who has a relation with company.
  </li>
  <li>Staff harassment, via phone, in person or email</li>
  <li>Breach of security, safety, or fraudulent actions</li>
  <li>Any actions which impede the health care or process of our office and our staff</li>
  <li>Violating civic manners or etiquettes in public place</li>
  <li>Depending on the circumstances, criminal charges may also be laid,</li>
  <li>Not wearing seat belt while driving and careless driving</li>
  <li>Not wearing approved personal protective equipment and clothing</li>
  <li>
    Moving violation such as speeding, fail to obey traffic control devices, follow too close to another vehicle,
  </li>
  <li>Log falsifications and not complying with HOS rules,</li>
  <li>Failed to produce required document to law enforcement officer and to the company,</li>
  <li>Failed to do proper pre-trip resulting OOS at scale and get ticket for it,</li>
  <li>Fail to report any incident, accident, violation ticket, any damage, OOS,</li>
  <li>Fail to report any argument and other incident at customer place,</li>
</ul>

<p>
  <strong>Enforcement Policy:</strong> Any Driver found in serious violation may result in suspension or termination
  without any notice or by the following steps:
</p>
<p>
  <strong>VERBAL WARNING:</strong> - An employee will be given a verbal warning when they engage in problematic
  behaviour. As the first step in the progressive discipline policy, a verbal caution is meant to alert the employee
  that a problem may exist or that one has been identified, which must be addressed. Verbal warnings will be documented
  and maintained by on the employee's personnel file. A verbal caution remains in effect for three months.
</p>
<p>
  <strong>WRITTEN WARNING:</strong> A written warning is more serious than a verbal warning. A written warning will be
  given when an employee engages in conduct that justifies a written warning or the employee engages in unacceptable
  behavior during the period that a verbal warning is in effect. Written warnings are maintained in an employee's
  personnel file and remains in effect for three months.
</p>
<p>
  <strong>SUSPENSION:</strong> A suspension without pay is more serious than a written warning. An employee will be
  suspended when he or she engages in conduct that justifies a suspension or the employee engages in unacceptable
  behavior during the period that a written warning is in effect. An employee's suspension will be documented and,
  regardless of the length of the suspension issued, will remain in effect for three months.
</p>
<p>
  <strong>TERMINATION:</strong> An employee will be terminated when he or she engages in conduct that justifies
  termination or does not correct the matter that resulted in less sever discipline.
</p>
<p>
  While the Company will generally take disciplinary action in a progressive manner, it reserves the right, in its sole
  discretion, to decide whether and what disciplinary action will be taken in a given situation. Some forms of
  misconduct, including theft or fraud, violence or threats of violence, harassment or violations of the drug or alcohol
  policy will generally result in immediate dismissal for cause.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 10-11 of 30</p>

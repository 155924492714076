import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { ROUTE_DRIVER_LICENSE_UPLOAD } from 'src/app/authentication/shared/routes';
import { ROUTE_LICENSE_UPLOAD } from 'src/app/new-license-scan/license-routes';

@Component({
  selector: 'app-identity-license-upload-action-sheet',
  templateUrl: './identity-license-upload-action-sheet.component.html',
  styleUrls: ['./identity-license-upload-action-sheet.component.scss']
})
export class IdentityLicenseUploadActionSheetComponent implements OnInit {
  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<IdentityLicenseUploadActionSheetComponent>,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  navigateToLicenseScan(): void {
    this.router.navigate([ROUTE_DRIVER_LICENSE_UPLOAD]);
    this.dismissBottomSheetToUpload();
  }

  navigateToLicenseFileUpload() {
    this.router.navigate([ROUTE_LICENSE_UPLOAD]);
    this.dismissBottomSheetToUpload();
  }

  dismissBottomSheetToUpload(): void {
    this.bottomSheetRef.dismiss();
  }
}

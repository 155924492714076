<h2>Handheld & Wireless Device Policy</h2>
<p class="mb-1">
  Distracted driving law makes it illegal for motorists to use hand-held wireless communication devices or hand-held
  electronic entertainment devices while driving. This law also prohibits viewing display screens unrelated to the
  driving task such as laptops or DVD players while driving. Law applies to hand-held wireless communications and
  hand-held electronic entertainment devices. This means that motorists may still use these devices in a "hands-free"
  manner. To minimize distraction, the use of hands-free wireless devices should be kept to a minimum, used only when
  necessary, while driving.
</p>
<p class="mb-1">Examples of hands-free use include:</p>
<ul class="mb-1">
  <li>
    A cell phone with an earpiece or headset using voice dialing, or plugged into the vehicle's sound system (when
    equipped)
  </li>
  <li>
    A global positioning system (GPS) device that is properly secured to the dashboard or another accessible place in
    the vehicle,
  </li>
  <li>A portable audio player that has been plugged into the vehicle's sound system.</li>
</ul>
<p class="mb-1">
  Drivers should remember that their number one priority is driving safely and obeying the rules of the road. To insure
  you arrive to your destination safely, Company is requiring all drivers to comply with the following:
</p>
<ul>
  <li>Find a safe place to pull off the roadway before placing a call.</li>
  <li>
    If you receive a call while driving, let the call go to voice mail and retrieve the message when safe to do so.
    Drivers using hands-free telephones must find a safe place to pull off the roadway to place calls unless the cell
    phone is equipped with a voice dialing function.
  </li>
</ul>
<ul class="mt-1">
  <li>
    <strong
      >ANY DRIVER FOUND GUILTY OF USING HANDHELD & WIRELESS DEVICE, WILL SUBJECT TO FINES AND TERMINATION OF EMPLYMENT
      WITHOUT ANY NOTICE</strong
    >
  </li>
</ul>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 26 of 30</p>

<h2>THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY ALL</h2>
<h2>ACCOUNT HOLDERS</h2>
<h2>IMPORTANT DISCLOSURE</h2>
<h2>REGARDING BACKGROUND REPORTS FROM THE PSP Online Service</h2>
<p>
  In connection with your application for employment with
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  ("Prospective Employer"), Prospective Employer, its employees, agents or contractors may obtain one or more reports
  regarding your driving, and safety inspection history from the Federal Motor Carrier Safety Administration (FMCSA).
</p>
<p>
  When the application for employment is submitted in person, if the Prospective Employer uses any information it
  obtains from FMCSA in a decision to not hire you or to make any other adverse employment decision regarding you, the
  Prospective Employer will provide you with a copy of the report upon which its decision was based and a written
  summary of your rights under the Fair Credit Reporting Act before taking any final adverse action. If any final
  adverse action is taken against you based upon your driving history or safety report, the Prospective Employer will
  notify you that the action has been taken and that the action was based in part or in whole on this report.
</p>
<p>
  When the application for employment is submitted by mail, telephone, computer, or other similar means, if the
  Prospective Employer uses any information it obtains from FMCSA in a decision to not hire you or to make any other
  adverse employment decision regarding you, the Prospective Employer must provide you within three business days of
  taking adverse action oral, written or electronic notification: that adverse action has been taken based in whole or
  in part on information obtained from FMCSA; the name, address, and the toll free telephone number of FMCSA; that the
  FMCSA did not make the decision to take the adverse action and is unable to provide you the specific reasons why the
  adverse action was taken; and that you may, upon providing proper identification, request a free copy of the report
  and may dispute with the FMCSA the accuracy or completeness of any information or report. If you request a copy of a
  driver record from the Prospective Employer who procured the report, then, within 3 business days of receiving your
  request, together with proper identification, the Prospective Employer must send or provide to you a copy of your
  report and a summary of your rights under the Fair Credit Reporting Act.
</p>
<p>
  Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety information has the
  capability to correct any safety data that appears to be incorrect. You may challenge the accuracy of the data by
  submitting a request to https://dataqs.fmcsa.dot.gov. If you challenge crash or inspection information reported by a
  State, FMCSA cannot change or correct this data. Your request will be forwarded by the DataQs system to the
  appropriate State for adjudication.
</p>
<p>
  Any crash or inspection in which you were involved will display on your PSP report. Since the PSP report does not
  report, or assign, or imply fault, it will include all Commercial Motor Vehicle (CMV) crashes where you were a driver
  or co-driver and where those crashes were reported to FMCSA, regardless of fault. Similarly, all inspections, with or
  without violations, appear on the PSP report. State citations associated with Federal Motor Carrier Safety Regulations
  (FMCSR) violations that have been adjudicated by a court of law will also appear, and remain, on a PSP report.
</p>

<h2>AUTHORIZATION</h2>
<p>The Prospective Employer cannot obtain background reports from FMCSA without your authorization.</p>
<p>
  If you agree that the Prospective Employer may obtain such background reports, please read the following and sign
  below:
</p>
<p>
  I authorize
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
</p>
<p>
  ("Prospective Employer") to access the FMCSA Pre-Employment Screening Program (PSP) system to seek information
  regarding my commercial driving safety record and information regarding my safety inspection history. I understand
  that I am authorizing the release of safety performance information including crash data from the previous five (5)
  years and inspection history from the previous three (3) years. I understand and acknowledge that this release of
  information may assist the Prospective Employer to make a determination regarding my suitability as an employee
</p>
<p>
  I further understand that neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety
  information has the capability to correct any safety data that appears to be incorrect. I understand I may challenge
  the accuracy of the data by submitting a request to https://dataqs.fimcsa.dot.gov. If I challenge crash or inspection
  information reported by a State, FMCSA cannot change or correct this data. I understand my request will be forwarded
  by the DataQs system to the appropriate State for adjudication.
</p>
<p>
  I understand that any crash or inspection in which I was involved will display on my PSP report. Since the PSP report
  does not report, or assign, or imply fault, I acknowledge it will include all CMV crashes where I was a driver or
  co-driver and where those crashes were reported to FMCSA, regardless of fault. Similarly, I understand all
  inspections, with or without violations, will appear on my PSP report, and State citations associated with FMCSR
  violations that have been adjudicated by a court of law will also appear, and remain, on my PSP report.
</p>
<p>
  I have read the above Disclosure Regarding Background Reports provided to me by Prospective Employer and I understand
  that if I sign this Disclosure and Authorization, Prospective Employer may obtain a report of my crash and inspection
  history. I hereby authorize Prospective Employer and its employees, authorized agents, and/or affiliates to obtain the
  information authorized above.
</p>

<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>

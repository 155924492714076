<div
  fxLayoutAlign="start"
  *ngIf="licenseForm"
  fxLayout="column"
  class="page gray-font-color font-family bodyBackground profile-license"
>
  <mat-error *ngIf="errorMessage" class="error padding-left-md">
    {{ errorMessage }}
  </mat-error>
  <form [formGroup]="licenseForm">
    <div class="header-navigation-back">
      <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title">License</span>
      <button
        mat-button
        [disabled]="!licenseForm.valid || loading || currentStatus == 'processing'"
        [class.save-disabled]="!licenseForm.valid || loading || currentStatus == 'processing'"
        class="header-action-button"
        (click)="onSubmit()"
        data-test="save-button"
      >
        Save
      </button>
    </div>
    <div class="description-banner" *ngIf="currentStatus == 'processing' || currentStatus == 'failed'">
      <div class="description" [innerHTML]="currentStatusMessage"></div>
    </div>
    <div class="form-field" *ngIf="licenseLoaded">
      <div *ngIf="licenseLoaded">
        <div class="information-header">
          <p>Images</p>
        </div>
        <ng-container *ngIf="driverLicenseId && licenseExists; else noLicenseView">
          <div class="container">
            <div class="column">
              <img
                (click)="openLicenseView('Front')"
                class="img-license"
                src="/assets/images/license-uploaded-front.png"
                alt="Uploaded Front License"
              />
            </div>
            <div class="column">
              <img
                (click)="openLicenseView('Back')"
                class="img-license"
                src="/assets/images/license-uploaded-back.png"
                alt="Uploaded Back License"
              />
            </div>
          </div>
        </ng-container>

        <ng-template #noLicenseView>
          <div class="container-add-license">
            <button (click)="navigateToLicenseScan()" class="add-license-btn">
              ADD LICENSE IMAGES
            </button>
          </div>
        </ng-template>
      </div>
      <div>
        <div class="information-header margin-top">
          <p>Information</p>
        </div>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-placeholder>License Number</mat-placeholder>
          <input matInput formControlName="licenseNumber" data-test="license-number" required />
          <mat-error *ngIf="licenseForm.touched && licenseForm.invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input
            matInput
            readonly
            placeholder="Issue Date"
            formControlName="issueDate"
            [matDatepicker]="issueDate"
            (click)="issueDate.open()"
            [max]="maxDate"
            (dateChange)="issueDateChangeEvent($event)"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="issueDate" data-test="issue-date-form-field"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" #issueDate></mat-datepicker>
          <mat-error *ngIf="issueDate.touched && issueDate.invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input
            matInput
            readonly
            placeholder="Expiration Date"
            formControlName="expiryDate"
            [matDatepicker]="expiryDate"
            [min]="expiryMinDate"
            (click)="expiryDate.open()"
            [disabled]="!licenseForm.get('issueDate').value"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="expiryDate"
            data-test="expiry-date-form-field"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" #expiryDate></mat-datepicker>
          <mat-error *ngIf="expiryDate.touched && expiryDate.invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Issuing Country</mat-label>
          <mat-select
            formControlName="issuingCountry"
            (selectionChange)="onCountryChange($event.value)"
            required
            data-test="issuing-country"
          >
            <mat-option *ngFor="let country of issuingCountry" [value]="country.code" data-test="country-name">
              {{ country.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="licenseForm.get('issuingCountry').touched && licenseForm.get('issuingCountry').invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Issuing State/Province</mat-label>
          <mat-select
            formControlName="issuingProvince"
            (selectionChange)="onProvinceChange($event.value)"
            required
            data-test="issuing-province"
          >
            <mat-option *ngFor="let province of stateProvinces" [value]="province.value" data-test="province-name">
              {{ province.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="licenseForm.get('issuingProvince').touched && licenseForm.get('issuingProvince').invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width" data-test="license-class-field">
          <mat-label>License Class</mat-label>
          <mat-select formControlName="licenseType" required data-test="license-class">
            <mat-option *ngFor="let license of provinceLicenseClass" [value]="license" data-test="class-value">
              {{ license }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="licenseForm.get('licenseType').touched && licenseForm.get('licenseType').invalid">
            {{ REQUIRED_FIELD_MESSAGE }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-row" *ngIf="isOntario">
        <div>
          <span class="field-header">Do you have Air Brake (Z) endorsement?</span>
        </div>
        <div fxFlex class="button-container">
          <button
            fxFlex="50"
            class="button-raised"
            mat-raised-button
            [ngClass]="{ active: true === licenseForm.get('hasAirBreakEndorsment').value }"
            (click)="setAirBrakeEndorsement(true)"
            data-test="air-break-endorsement"
          >
            Yes
          </button>
          <button
            fxFlex="50"
            class="button-raised"
            mat-raised-button
            [ngClass]="{ active: false === licenseForm.get('hasAirBreakEndorsment').value }"
            (click)="setAirBrakeEndorsement(false)"
            data-test="air-break-endorsement"
          >
            No
          </button>
        </div>
      </div>

      <div>
        <div class="declaration-header">
          <p>Declaration</p>
        </div>
        <div class="field-row">
          <div>
            <span class="field-header"
              >Have you ever been denied a license, permit or privilege to operate a motor Vehicle?</span
            >
          </div>
          <div fxFlex class="button-container">
            <button
              fxFlex="50"
              class="button-raised"
              mat-raised-button
              (click)="setDeniedLicense(true)"
              [ngClass]="{ active: true === licenseForm.get('hasDeniedLicense').value }"
              data-test="denied-license"
            >
              Yes
            </button>
            <button
              fxFlex="50"
              class="button-raised"
              mat-raised-button
              (click)="setDeniedLicense(false)"
              [ngClass]="{ active: false === licenseForm.get('hasDeniedLicense').value }"
              data-test="denied-license"
            >
              No
            </button>
          </div>

          <mat-form-field class="full-width" *ngIf="licenseForm.get('hasDeniedLicense').value">
            <input
              matInput
              type="text"
              minlength="1"
              maxlength="100"
              formControlName="deniedLicenseExplanation"
              required
            />
            <mat-placeholder>Explain</mat-placeholder>
            <mat-error
              *ngIf="
                licenseForm.get('deniedLicenseExplanation').touched &&
                licenseForm.get('deniedLicenseExplanation').invalid
              "
            >
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field-row">
          <div>
            <span class="field-header">Has any License, Permit or privilege ever been suspended or Revoked?</span>
          </div>
          <div fxFlex class="button-container">
            <button
              fxFlex="50"
              class="button-raised"
              mat-raised-button
              [ngClass]="{ active: true === licenseForm.get('hasSuspendedLicense').value }"
              (click)="setPermitSuspended(true)"
              data-test="suspended-permit"
            >
              Yes
            </button>
            <button
              fxFlex="50"
              class="button-raised"
              mat-raised-button
              [ngClass]="{ active: false === licenseForm.get('hasSuspendedLicense').value }"
              (click)="setPermitSuspended(false)"
              data-test="suspended-permit"
            >
              No
            </button>
          </div>

          <mat-form-field class="full-width" *ngIf="licenseForm.get('hasSuspendedLicense').value">
            <input
              matInput
              type="text"
              minlength="1"
              maxlength="100"
              formControlName="suspendedLicenseExplanation"
              required
            />
            <mat-placeholder>Explain</mat-placeholder>
            <mat-error
              *ngIf="
                licenseForm.get('suspendedLicenseExplanation').touched &&
                licenseForm.get('suspendedLicenseExplanation').invalid
              "
            >
              {{ REQUIRED_FIELD_MESSAGE }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <app-loading-gif *ngIf="!licenseLoaded"></app-loading-gif>
  </form>
</div>

<div class="header-navigation-back" fxFlayout>
  <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-button">
    <mat-icon svgIcon="left-arrow"></mat-icon>
  </div>
  <div fxFlex="95" class="header-text">Declaration Form</div>
</div>
<form [formGroup]="employmentForm" class="form-container" *ngIf="isContentReady && isSigning">
  <!-- Page 1 -->
  <app-page1-third-party-auth
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
    [managedUserCompanyName]="employmentForm.get('managedUserCompanyName')"
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [driverType]="employmentForm.get('driverType')"
    (insertAllSignatures)="insertSignatureToAll()"
    (moveToNextPage)="scrollToPage('drug-alcohol')"
  >
  </app-page1-third-party-auth>

  <!-- Page 2 -->
  <app-page2-application-information
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [dateEmployedCtrl]="employmentForm.get('dateEmployed')"
    [reasonRejectionCtrl]="employmentForm.get('reasonRejection')"
    [dateTerminationCtrl]="employmentForm.get('dateTermination')"
    [reasonTerminationCtrl]="employmentForm.get('reasonTermination')"
    [safetyOfficerNameCtrl]="employmentForm.get('safetyOfficerName')"
    [safetyOfficerDateCtrl]="employmentForm.get('safetyOfficerDate')"
  >
  </app-page2-application-information>

  <!-- Page 3 -->
  <app-page3-to-read-signed
    [driverNameCtrl]="employmentForm.get('driverName')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  >
  </app-page3-to-read-signed>

  <!-- Page 4 -->
  <app-page4-cfr-info></app-page4-cfr-info>

  <!-- Page 5 & 6 -->
  <app-page5-cfr-info></app-page5-cfr-info>

  <!-- Page 7 -->
  <app-page7-drug-alcohol
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [prospectiveEmployerNameCtrl]="employmentForm.get('prospectiveEmployerName')"
    [prospectiveEmployerAddressCtrl]="employmentForm.get('prospectiveEmployerAddress')"
    [drugAlcoholAnswerCtrl]="employmentForm.get('drugAlcoholAnswer')"
    [drugExplanation]="employmentForm.get('drugExplanation')"
    (moveToNextPage)="scrollToPage('driver-requirement')"
  >
  </app-page7-drug-alcohol>

  <!-- Page 8 -->
  <app-page8-drive-certification
    [driverNameCtrl]="employmentForm.get('driverName')"
    [driverLicenseNoCtrl]="employmentForm.get('driverLicenseNo')"
    [expiryDateCtrl]="employmentForm.get('expiryDate')"
  >
  </app-page8-drive-certification>

  <!-- Page 9 -->
  <app-page9-medical-declaration
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  ></app-page9-medical-declaration>

  <!-- Page 10 - 11 -->
  <app-page10-tolerance-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page10-tolerance-policy>

  <!-- Page 12 -->
  <app-page12-driver-requirements
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    (insertAllInitials)="insertInitialsToAll()"
  ></app-page12-driver-requirements>

  <!-- Page 13 -->
  <app-page13-driver-responsibility
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  ></app-page13-driver-responsibility>

  <!-- Page 14 -->
  <app-page14-accident-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  ></app-page14-accident-policy>

  <!-- Page 15 -->
  <app-page15-hours-of-service
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page15-hours-of-service>

  <!-- Page 16 -->
  <app-page16-vehicle-maintenance
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  >
  </app-page16-vehicle-maintenance>

  <!-- Page 17 -->
  <app-page17-truck-trailer-insurance
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page17-truck-trailer-insurance>

  <!-- Page 18 -->
  <app-page18-health-safety
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page18-health-safety>

  <!-- Page 19 -->
  <app-page19-traffic-tickets
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page19-traffic-tickets>

  <!-- Page 20 -->
  <app-page20-inspection-violations
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  >
  </app-page20-inspection-violations>

  <!-- Page 21 -->
  <app-page21-license-status
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page21-license-status>

  <!-- Page 22 -->
  <app-page22-confidentiality-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  ></app-page22-confidentiality-policy>

  <!-- Page 23 -->
  <app-page23-safe-driving-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page23-safe-driving-policy>

  <!-- Page 24 -->
  <app-page24-entry-numbers-seal-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page24-entry-numbers-seal-policy>

  <!-- Page 25 -->
  <app-page25-seat-belt-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page25-seat-belt-policy>

  <!-- Page 26 -->
  <app-page26-handheld-device-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  >
  </app-page26-handheld-device-policy>

  <!-- Page 27-28-->
  <app-page2728-reefer-policy
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
  >
  </app-page2728-reefer-policy>

  <!-- Page 29-30-->
  <app-page2930-psp-authorization
    [driverNameCtrl]="employmentForm.get('driverName')"
    [dateOfApplicationCtrl]="employmentForm.get('dateOfApplication')"
    [carrierCompanyNameCtrl]="employmentForm.get('carrierCompanyName')"
  >
  </app-page2930-psp-authorization>

  <!-- Submit Button -->
  <div class="submit-button">
    <button
      mat-raised-button
      color="primary"
      type="button"
      [disabled]="!employmentForm.valid || !allSignaturesPresent()"
      (click)="onSubmit()"
    >
      Generate PDF
    </button>
  </div>
</form>
<div *ngIf="!isContentReady" class="container-loading-gif">
  <div fxLayoutAlign="center">
    <img src="/assets/icons/FleetOperate load.gif" />
  </div>
</div>
<div class="added-file" *ngIf="isContentReady && !isSigning">
  <div fxFlex="100" class="added-icon" (click)="viewDocument()">
    <div class="added-display-icon" fxFlex="10">
      <mat-icon svgIcon="icon-file-blue" class="added-icon-color"></mat-icon>
    </div>
    <div fxFlex="90" class="file-name" data-test="uploaded-file-name">
      <p>Declaration Form</p>
    </div>
  </div>
  <div class="delete-icon" fxFlex="20" (click)="deleteDocument()">
    <mat-icon svgIcon="delete-icon"></mat-icon>
  </div>
</div>

import { Component } from '@angular/core';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';

@Component({
  selector: 'app-page5-cfr-info',
  templateUrl: './page5-cfr-info.component.html',
  styleUrls: ['../shared.scss', './page5-cfr-info.component.scss']
})
export class Page5CfrInfoComponent implements PDFCapableComponent {
  constructor() {}

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { x, pageWidth, pageHeight, margin } = position;
    const maxWidth = pageWidth - 2 * margin;

    // Always start page 5 content on a new page
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    let currentPage = newPage;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.pageCount + 1);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const checkPageBreak = (requiredSpace: number = margin * 2) => {
      if (currentY < requiredSpace) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);

        if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
          formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
          formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
        }

        currentY = position.headerOffset || pageHeight - margin;

        return true;
      }
      return false;
    };

    // Function to draw wrapped text
    const drawWrappedText = (text: string, startX: number, startY: number, options: any = {}) => {
      const { fontSize = 12, font = fonts.regular, lineHeight = 16 } = options;
      const words = text.split(' ');
      let line = '';
      let lineX = startX;
      let currentYPos = startY;

      for (const word of words) {
        const testLine = line + word + ' ';
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);

        if (lineX + testWidth > startX + maxWidth) {
          currentPage.drawText(line, {
            x: lineX,
            y: currentYPos,
            size: fontSize,
            font
          });
          currentYPos -= lineHeight;

          // Check if we need a new page
          if (currentYPos < margin) {
            currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
            currentYPos = pageHeight - margin;
            lineX = startX;
          } else {
            lineX = startX;
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      // Draw remaining line
      if (line) {
        currentPage.drawText(line, {
          x: lineX,
          y: currentYPos,
          size: fontSize,
          font
        });
        currentYPos -= lineHeight;
      }

      return currentYPos;
    };

    // Title - Centered
    const titleText = '49 CFR Part 382.301';
    const titleWidth = fonts.bold.widthOfTextAtSize(titleText, 12);
    const centerX = x + (maxWidth - titleWidth) / 2;

    currentPage.drawText(titleText, {
      x: centerX,
      y: currentY,
      size: 12,
      font: fonts.bold
    });
    currentY -= 20;

    // Subtitle
    currentY = drawWrappedText('382.301 Pre-employment testing.', x, currentY, { font: fonts.bold });
    currentY -= 15;

    // Page 5 paragraphs
    const paragraphs = [
      'a Prior to the first time a driver performs safety-sensitive functions for an employer, the driver shall undergo testing for controlled substances as a condition prior to being used, unless the employer uses the exception in paragraph (b) of this section. No employer shall allow a driver, who the employer intends to hire or use, to perform safety-sensitive functions unless the employer has received a controlled substances test result from the MRO or C/TPA indicating a verified negative test result for that driver.',

      'b An employer is not required to administer a controlled substances test required by paragraph (a) of this section if:',

      '(1) The driver has participated in a controlled substance testing program that meets the requirements of this part within the previous 30 days: and',

      '(2) While participating in that program, either:',

      '(i) Was tested for controlled substances within the past 6 months (from the date of application with the employer) or',

      '(ii) Participated in the random controlled substances testing program for the previous 12 months (from the date of application with the employer); and',

      '(3) The employer ensures that no prior employer of the driver of whom the employer has knowledge has records of a violation of this part or the controlled substances use rule of another DOT agency within the previous six months.',

      '(c) (1) An employer who exercises the exception in paragraph (b) of this section shall contact the controlled substances testing program(s) in which the'
    ];

    // Draw each paragraph
    for (const paragraph of paragraphs) {
      currentY = drawWrappedText(paragraph, x, currentY);
      currentY -= 10; // Space between paragraphs
      if (currentY < margin * 3) {
        // Only check for page break if truly needed
        checkPageBreak();
      }
    }

    // Key change: REMOVE the automatic page break for Page 6 content
    // We'll only create a new page if actually necessary based on content length

    // First paragraph
    currentY = drawWrappedText(
      'driver participates or participated and shall obtain and retain from the testing program(s) the following information:',
      x,
      currentY
    );
    currentY -= 10;

    // List items
    const listItems = [
      'Name(s) and address(es) of the program(s).',
      'Verification that the driver participates or participated in the program(s).',
      'Verification that the program(s) conforms to part 40 of this title.',
      'Verification that the driver is qualified under the rules of this part, including that the driver has not refused to be tested for controlled substances.',
      'The date the driver was last tested for controlled substances.',
      'The results of any tests taken within the previous six months and any other violations of subpart B of this part'
    ];

    // Draw each list item
    for (const item of listItems) {
      // Before drawing, check if enough space for at least one more line
      if (currentY < margin + 20) {
        checkPageBreak();
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw item text with indentation
      const itemX = x + 15;
      currentY = drawWrappedText(item, itemX, currentY);
      currentY -= 5;
    }

    currentY -= 10;

    // Additional paragraphs for Page 6
    const page6Paragraphs = [
      '(2) An employer who uses but does not employ a driver more than once a year to operate commercial motor vehicles must obtain the information in paragraph (c)(1) of this section at least once every six months. The records prepared under this paragraph shall be maintained in accordance with § 382.401. If the employer cannot verify that the driver is participating in a controlled substance testing program in accordance with this part and part 40 of this title, the employer shall conduct a pre employmentcontrolled substances test.',

      '(d) An employer may, but is not required to, conduct pre-employment alcohol testing under this part. If an employer chooses to conduct pre-employment alcohol testing, it must comply with the following requirements:',

      '(1) It must conduct a pre-employment alcohol test before the first performance of safety-sensitive functions by every covered employee (whether a new employee or someone who has transferred to a position involving the performance of safety-sensitive functions).',

      '(2) It must treat all safety-sensitive employees performing safety-sensitive functions the same for the purpose of pre-employment alcohol testing (i.e., it must not test some covered employees and not others).',

      '(3) It must conduct the pre-employment tests after making a contingent offer of employment or transfer, subject to the employee passing the pre-employment alcohol test.',

      '(4) It must conduct all pre-employment alcohol tests using the alcohol testing procedures of 49 CFR part 40 of this title.',

      "(5) It must not allow a covered employee to begin performing safety-sensitive functions unless the result of the employee's test indicates an alcohol concentration of less than 0.04."
    ];

    // Draw each paragraph
    for (const paragraph of page6Paragraphs) {
      // Before drawing, check if enough space for at least one more line
      if (currentY < margin + 20) {
        checkPageBreak();
      }

      currentY = drawWrappedText(paragraph, x, currentY);
      currentY -= 10; // Space between paragraphs
    }

    // Return result with newPage flag since we created new pages
    return {
      height: pageHeight - margin - currentY,
      newPage: true,
      position: {
        ...position,
        y: currentY
      }
    };
  }
}

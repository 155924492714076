import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page15-hours-of-service',
  templateUrl: './page15-hours-of-service.component.html',
  styleUrls: ['../shared.scss', './page15-hours-of-service.component.scss']
})
export class Page15HoursOfServiceComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Hours of Service';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw first paragraph with inline company name
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of the company name to position the rest of the text
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Continue with the paragraph text
    const paragraphText =
      ' is committed to following the Department of Transportation (DOT) hours of service regulations. DOT regulations ' +
      'require all motor carriers and drivers to follow the hours-of-service requirements. Our hours-of-service ' +
      'procedures will help avoid DOT penalties and reduce driver fatigue. Hours of Service Procedures requires that ' +
      "all new drivers be trained in the hours-of-service regulations as part of the company's driver orientation " +
      'program. In addition, all current drivers are required to be retrained in the hours-of-service regulations ' +
      'annually. Driver supervisors are responsible to ensure all current drivers are recertified annually in ' +
      "hours-of-service regulations training. All training is to be documented and put in the driver's file. Drivers " +
      'will be notified in advance of scheduled refresher sessions.';

    currentY = this.drawWrappedText(currentPage, paragraphText, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });
    currentY -= 20;

    // Draw second paragraph
    const paragraph2 = 'Drivers will receive warnings if any of the following violations are found:';

    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });
    currentY -= 20;

    // Draw the bullet points for violations
    const violations = [
      'Missing information (trailer number, driver name, truck number)',
      'Not showing 15-minute pre-trip.',
      'Not showing 10 hours off-duty',
      "Showing sleeper berth when you don't have a sleeper",
      'Not showing a safety check within the first 100 miles or 2 hours of driving',
      'Not showing BOL number or shipper and commodity on your logs',
      'Supporting document dates do not match log dates and times of Shipment paperwork (bills of lading, shipment ' +
        'invoices, delivery receipts, etc.) Dispatch and trip records, or equivalent documents o Expense receipts ' +
        'related to any on-duty non-driving time (fuel receipts, lumper receipts, etc.)'
    ];

    // Draw each violation bullet point
    for (const violation of violations) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw bullet point text with indentation
      currentY = this.drawWrappedText(currentPage, violation, x + 15, currentY, {
        font: fonts.regular,
        fontSize: 12,
        lineHeight: 16,
        maxWidth: maxWidth - 15
      });
      currentY -= 15; // Space between items
    }

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage15');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

<h2>To Be Read and Signed by Applicant</h2>
<p>
  I,
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  certify that this application was completed by me, and that all entries on it and information in it are true and
  completed to the best of my knowledge. I authorize (
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  ) and their assigned agents, to make such investigations and inquiries of my personal, employment, financial (credit
  bureau) information, criminal search, driving abstracts, drug results from previous employers or their consortium or
  their insurance carrier or Agent for my driving record, insurance history, medical history, and other related matters
  as may be necessary in arriving at an employment decision.
</p>
<p>
  If hired or contracted, this authorization shall remain on file and shall service as ongoing authorization to recheck
  or report as deemed necessary at any time throughout my employment or contract period or after such period.
  (Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has
  been extended).
</p>
<p>
  I hereby release employers, schools, health care providers and other persons from all liability in responding to
  inquiries and releasing information in connection with my application. In the event of employment, I understand that
  false or misleading information given in my application or interview(s) may result in discharge.
</p>
<p>
  Furthermore, I understand that (
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  ) and/or their Agents may keep any information on file including work performance as related to my employment period
  and make it available to any second party only with my verbal or written consent. I understand, also, that I am
  required to abide by all rules and regulations of company.
</p>
<p>
  For purposes of gathering this information, I have supplied information which may be required by law enforcement
  agencies and other entities for positive identification purposes when checking records, including Date of Birth,
  residence history and Driver's License Number/Province. It is confidential and will not be used for any other purpose.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 3 of 30</p>

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PdfGenerationService } from '../pdf-generation.service';
import { PDFCapableComponent } from '../pdf-interfaces';
import { Location } from '@angular/common';

import { Page1ThirdPartyAuthComponent } from '../components/page1-third-party-auth/page1-third-party-auth.component';
import { Page2ApplicationInformationComponent } from '../components/page2-application-information/page2-application-information.component';
import { Page3ToReadSignedComponent } from '../components/page3-to-read-signed/page3-to-read-signed.component';
import { Page4CfrInfoComponent } from '../components/page4-cfr-info/page4-cfr-info.component';
import { Page5CfrInfoComponent } from '../components/page5-cfr-info/page5-cfr-info.component';
import { Page7DrugAlcoholComponent } from '../components/page7-drug-alcohol/page7-drug-alcohol.component';
import { Page8DriveCertificationComponent } from '../components/page8-drive-certification/page8-drive-certification.component';
import { Page9MedicalDeclarationComponent } from '../components/page9-medical-declaration/page9-medical-declaration.component';
import { Page10TolerancePolicyComponent } from '../components/page10-tolerance-policy/page10-tolerance-policy.component';
import { Page12DriverRequirementsComponent } from '../components/page12-driver-requirements/page12-driver-requirements.component';
import { Page13DriverResponsibilityComponent } from '../components/page13-driver-responsibility/page13-driver-responsibility.component';
import { Page14AccidentPolicyComponent } from '../components/page14-accident-policy/page14-accident-policy.component';
import { Page15HoursOfServiceComponent } from '../components/page15-hours-of-service/page15-hours-of-service.component';
import { Page16VehicleMaintenanceComponent } from '../components/page16-vehicle-maintenance/page16-vehicle-maintenance.component';
import { Page17TruckTrailerInsuranceComponent } from '../components/page17-truck-trailer-insurance/page17-truck-trailer-insurance.component';
import { Page18HealthSafetyComponent } from '../components/page18-health-safety/page18-health-safety.component';
import { Page19TrafficTicketsComponent } from '../components/page19-traffic-tickets/page19-traffic-tickets.component';
import { Page20InspectionViolationsComponent } from '../components/page20-inspection-violations/page20-inspection-violations.component';
import { Page21LicenseStatusComponent } from '../components/page21-license-status/page21-license-status.component';
import { Page22ConfidentialityPolicyComponent } from '../components/page22-confidentiality-policy/page22-confidentiality-policy.component';
import { Page23SafeDrivingPolicyComponent } from '../components/page23-safe-driving-policy/page23-safe-driving-policy.component';
import { Page24EntryNumbersSealPolicyComponent } from '../components/page24-entry-numbers-seal-policy/page24-entry-numbers-seal-policy.component';
import { Page25SeatBeltPolicyComponent } from '../components/page25-seat-belt-policy/page25-seat-belt-policy.component';
import { Page26HandheldDevicePolicyComponent } from '../components/page26-handheld-device-policy/page26-handheld-device-policy.component';
import { Page2728ReeferPolicyComponent } from '../components/page2627-reefer-policy/page2728-reefer-policy.component';
import { Page2930PspAuthorizationComponent } from '../components/page2930-psp-authorization/page2930-psp-authorization.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverQualificationService } from '../../service/driver-qualification.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Store } from '@ngxs/store';
import { ROUTE_DQF } from '../../driverQualification-routing.module';
import { DqfDocument } from '../../models/dqfDocument';
import { NotificationStateModel, RegistrationState } from 'src/app/registration/state/registration.state';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { Driver } from 'src/app/shared/models/driver.model';
import dayjs from 'dayjs';
import { DQF_DOC_STATUS } from '../../constants';
import { ROUTER_DQF_VIEW } from 'src/app/shared/routes';
import { MatInput } from '@angular/material/input';

const DOCUMENT_TYPE_SIGN = 'declarationForm';
const REJECTED_STATUS = 'Rejected';
const DISABLE_TOP_LOADING_BAR = true;
const PENDING_STATUS = 'Pending';
const SIGNED_STATUS = 'signed';
const UPLOADED_STATUS = 'uploaded';
const UPLOADED_DOCUMENT = 'Successfully uploaded document';
const DELETED_DOCUMENT = 'Successfully deleted document';

@Component({
  selector: 'app-declaration-form',
  templateUrl: './declaration-form.component.html',
  styleUrls: ['./declaration-form.component.scss']
})
export class DeclarationFormComponent implements OnInit {
  employmentForm: FormGroup;
  totalPages = 30;

  @ViewChild(Page1ThirdPartyAuthComponent) thirdPartyAuthComponent: Page1ThirdPartyAuthComponent;
  @ViewChild(Page2ApplicationInformationComponent) applicantInfoComponent: Page2ApplicationInformationComponent;
  @ViewChild(Page3ToReadSignedComponent) toBeReadComponent: Page3ToReadSignedComponent;
  @ViewChild(Page4CfrInfoComponent) cfrInfoComponent: Page4CfrInfoComponent;
  @ViewChild(Page5CfrInfoComponent) cfrInfoComponent2: Page5CfrInfoComponent;
  @ViewChild(Page7DrugAlcoholComponent) drugAlcoholStatementComponent: Page7DrugAlcoholComponent;
  @ViewChild(Page8DriveCertificationComponent) driverCertificationComponent: Page8DriveCertificationComponent;
  @ViewChild(Page9MedicalDeclarationComponent) medicalDeclarationComponent: Page9MedicalDeclarationComponent;
  @ViewChild(Page10TolerancePolicyComponent) zeroTolerancePolicyComponent: Page10TolerancePolicyComponent;
  @ViewChild(Page12DriverRequirementsComponent) driverRequirementsComponent: Page12DriverRequirementsComponent;
  @ViewChild(Page13DriverResponsibilityComponent) driverResponsibilityComponent: Page13DriverResponsibilityComponent;
  @ViewChild(Page14AccidentPolicyComponent) accidentPolicyComponent: Page14AccidentPolicyComponent;
  @ViewChild(Page15HoursOfServiceComponent) hoursOfServiceComponent: Page15HoursOfServiceComponent;
  @ViewChild(Page16VehicleMaintenanceComponent) vehicleMaintenanceComponent: Page16VehicleMaintenanceComponent;
  @ViewChild(Page17TruckTrailerInsuranceComponent) truckTrailerInsuranceComponent: Page17TruckTrailerInsuranceComponent;
  @ViewChild(Page18HealthSafetyComponent) healthSafetyComponent: Page18HealthSafetyComponent;
  @ViewChild(Page19TrafficTicketsComponent) trafficTicketsComponent: Page19TrafficTicketsComponent;
  @ViewChild(Page20InspectionViolationsComponent) inspectionsViolationsComponent: Page20InspectionViolationsComponent;
  @ViewChild(Page21LicenseStatusComponent) licenseStatusComponent: Page21LicenseStatusComponent;
  @ViewChild(Page22ConfidentialityPolicyComponent) confidentialityPolicyComponent: Page22ConfidentialityPolicyComponent;
  @ViewChild(Page23SafeDrivingPolicyComponent) safeDrivingPolicyComponent: Page23SafeDrivingPolicyComponent;
  @ViewChild(Page24EntryNumbersSealPolicyComponent)
  entryNumbersSealPolicyComponent: Page24EntryNumbersSealPolicyComponent;
  @ViewChild(Page25SeatBeltPolicyComponent) seatBeltPolicyComponent: Page25SeatBeltPolicyComponent;
  @ViewChild(Page26HandheldDevicePolicyComponent) handheldDevicePolicyComponent: Page26HandheldDevicePolicyComponent;
  @ViewChild(Page2728ReeferPolicyComponent) reeferPolicyComponent: Page2728ReeferPolicyComponent;
  @ViewChild(Page2930PspAuthorizationComponent) pspAuthorizationComponent: Page2930PspAuthorizationComponent;

  dqfId: string;
  dqfData;
  isContentReady = false;
  declarationFormDqfData;
  isRejected;
  declarationFormDriverData;
  isSigning;
  driver;

  constructor(
    private fb: FormBuilder,
    private pdfService: PdfGenerationService,
    private readonly location: Location,
    private router: Router,
    private driverQualificationService: DriverQualificationService,
    private readonly registrationService: RegistrationService,
    private readonly toastService: ToastService,
    @Inject('environmentData') private environment: any,
    private readonly store: Store
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    forkJoin([this.getDqfData(), this.loadDocuments()]).subscribe(() => {
      this.isContentReady = true;
      this.getDeclarationFormData();
    });
    this.employmentForm.get('drugAlcoholAnswer').valueChanges.subscribe(value => {
      if (value === 'YES') {
        this.employmentForm.get('drugExplanation').setValidators([Validators.required]);
        this.employmentForm.get('drugExplanation').markAsTouched();
        this.employmentForm.get('drugExplanation').updateValueAndValidity();
      } else {
        this.employmentForm.get('drugExplanation').clearValidators();
        this.employmentForm.get('drugExplanation').markAsTouched();
        this.employmentForm.get('drugExplanation').updateValueAndValidity();
      }
      this.employmentForm.updateValueAndValidity();
    });
  }
  createForm() {
    this.employmentForm = this.fb.group({
      driverName: ['', Validators.required],
      managedUserCompanyName: [this.dqfData?.managedUserCompanyName ?? '', Validators.required],
      carrierCompanyName: [this.dqfData?.carrierCompanyName ?? '', Validators.required],
      carrierCompanyAddress: [''],
      dateOfApplication: [new Date().toISOString().substring(0, 10), Validators.required],
      driverType: ['', Validators.required],

      // Company Use fields
      dateEmployed: [''],
      reasonRejection: [''],
      dateTermination: [''],
      reasonTermination: [''],
      safetyOfficerName: [''],
      safetyOfficerDate: [''],

      // Other form fields
      prospectiveEmployerName: [this.dqfData?.carrierCompanyName ?? '', [Validators.required]],
      prospectiveEmployerAddress: [''],
      drugAlcoholAnswer: ['', Validators.required],
      drugExplanation: [''],
      driverLicenseNo: ['', Validators.required],
      expiryDate: ['', Validators.required]
    });
  }

  async onSubmit() {
    if (this.employmentForm.valid && this.allSignaturesPresent()) {
      // Collect form values
      const formValues = this.employmentForm.value;

      // Collect signatures
      const signatureMap = new Map<string, string>();

      // Add signatures from each page component
      signatureMap.set('driverSignaturePage1', this.thirdPartyAuthComponent.getSignatureDataUrl());
      signatureMap.set('applicantSignaturePage2', this.applicantInfoComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage3', this.toBeReadComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage7', this.drugAlcoholStatementComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage8', this.driverCertificationComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage9', this.medicalDeclarationComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage15', this.hoursOfServiceComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage16', this.vehicleMaintenanceComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage17', this.truckTrailerInsuranceComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage18', this.healthSafetyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage19', this.trafficTicketsComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage20', this.inspectionsViolationsComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage21', this.licenseStatusComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage22', this.confidentialityPolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage23', this.safeDrivingPolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage24', this.entryNumbersSealPolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage25', this.seatBeltPolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage26', this.handheldDevicePolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage28', this.reeferPolicyComponent.getSignatureDataUrl());
      signatureMap.set('driverSignaturePage30', this.pspAuthorizationComponent.getSignatureDataUrl());

      const checkboxSignatures = this.driverRequirementsComponent.getCheckboxSignatureUrls();

      // Collect all PDF-capable components in order
      const components: PDFCapableComponent[] = [
        this.thirdPartyAuthComponent,
        this.applicantInfoComponent,
        this.toBeReadComponent,
        this.cfrInfoComponent,
        this.cfrInfoComponent2,
        this.drugAlcoholStatementComponent,
        this.driverCertificationComponent,
        this.medicalDeclarationComponent,
        this.zeroTolerancePolicyComponent,
        this.driverRequirementsComponent,
        this.driverResponsibilityComponent,
        this.accidentPolicyComponent,
        this.hoursOfServiceComponent,
        this.vehicleMaintenanceComponent,
        this.truckTrailerInsuranceComponent,
        this.healthSafetyComponent,
        this.trafficTicketsComponent,
        this.inspectionsViolationsComponent,
        this.licenseStatusComponent,
        this.confidentialityPolicyComponent,
        this.safeDrivingPolicyComponent,
        this.entryNumbersSealPolicyComponent,
        this.seatBeltPolicyComponent,
        this.handheldDevicePolicyComponent,
        this.reeferPolicyComponent,
        this.pspAuthorizationComponent
      ];

      const file = await this.pdfService.generatePDF(
        components,
        { ...formValues, checkboxSignatures },
        `${formValues.driverName}_declaration_form.pdf`,
        signatureMap
      );
      //api integration
      this.uploadDocument(file);
    } else {
      let errorMessage = 'Please correct the following issues:';

      if (!this.employmentForm.valid) {
        errorMessage += '\n- Please fill out all required fields';
      }

      if (!this.allSignaturesPresent()) {
        errorMessage += '\n- All signatures are required';
      }

      alert(errorMessage);
    }
  }
  uploadDocument(file) {
    this.isContentReady = false;
    const model: any = {
      source: 'self',
      extension: 'pdf',
      generationDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      reportType: DOCUMENT_TYPE_SIGN,
      context: 'initialDQF',
      dqfId: this.dqfId
    };
    this.driverQualificationService
      .uploadDqfDocument(model, file, DISABLE_TOP_LOADING_BAR)
      .pipe(
        first(),
        switchMap(() => {
          const docModel = {
            attachedDocument: {
              type: DOCUMENT_TYPE_SIGN,
              status: DQF_DOC_STATUS.DRIVER_REVIEWED
            }
          };
          return this.updateDqfDocStatus(docModel);
        }),
        catchError((error: any) => {
          return of(false);
        }),
        finalize(() => {
          this.isContentReady = true;
          this.backAction();
        })
      )
      .subscribe(() => {
        this.toastService.showSuccess(UPLOADED_DOCUMENT);
        this.backAction();
      });
  }
  insertSignatureToAll() {
    const signatureData = this.thirdPartyAuthComponent.signaturePad.toDataURL();

    // Define all target signature pads and their canvas elements
    const signaturePadsWithElements = [
      {
        pad: this.applicantInfoComponent.signaturePad,
        element: this.applicantInfoComponent.signatureCanvas
      },
      {
        pad: this.toBeReadComponent.signaturePad,
        element: this.toBeReadComponent.signatureCanvas
      },
      {
        pad: this.drugAlcoholStatementComponent.signaturePad,
        element: this.drugAlcoholStatementComponent.signatureCanvas
      },
      {
        pad: this.driverCertificationComponent.signaturePad,
        element: this.driverCertificationComponent.signatureCanvas
      },
      {
        pad: this.medicalDeclarationComponent.signaturePad,
        element: this.medicalDeclarationComponent.signatureCanvas
      },
      {
        pad: this.hoursOfServiceComponent.signaturePad,
        element: this.hoursOfServiceComponent.signatureCanvas
      },
      {
        pad: this.vehicleMaintenanceComponent.signaturePad,
        element: this.vehicleMaintenanceComponent.signatureCanvas
      },
      {
        pad: this.truckTrailerInsuranceComponent.signaturePad,
        element: this.truckTrailerInsuranceComponent.signatureCanvas
      },
      {
        pad: this.healthSafetyComponent.signaturePad,
        element: this.healthSafetyComponent.signatureCanvas
      },
      {
        pad: this.trafficTicketsComponent.signaturePad,
        element: this.trafficTicketsComponent.signatureCanvas
      },
      {
        pad: this.inspectionsViolationsComponent.signaturePad,
        element: this.inspectionsViolationsComponent.signatureCanvas
      },
      {
        pad: this.licenseStatusComponent.signaturePad,
        element: this.licenseStatusComponent.signatureCanvas
      },
      {
        pad: this.confidentialityPolicyComponent.signaturePad,
        element: this.confidentialityPolicyComponent.signatureCanvas
      },
      {
        pad: this.safeDrivingPolicyComponent.signaturePad,
        element: this.safeDrivingPolicyComponent.signatureCanvas
      },
      {
        pad: this.entryNumbersSealPolicyComponent.signaturePad,
        element: this.entryNumbersSealPolicyComponent.signatureCanvas
      },
      {
        pad: this.seatBeltPolicyComponent.signaturePad,
        element: this.seatBeltPolicyComponent.signatureCanvas
      },
      {
        pad: this.handheldDevicePolicyComponent.signaturePad,
        element: this.handheldDevicePolicyComponent.signatureCanvas
      },
      {
        pad: this.reeferPolicyComponent.signaturePad,
        element: this.reeferPolicyComponent.signatureCanvas
      },
      {
        pad: this.pspAuthorizationComponent.signaturePad,
        element: this.pspAuthorizationComponent.signatureCanvas
      }
    ];
    this.applySignature(signaturePadsWithElements, signatureData);
  }
  insertInitialsToAll() {
    const signatureData = this.driverRequirementsComponent.licensing1Pad.toDataURL();
    const signaturePadsWithElements = [
      {
        pad: this.driverRequirementsComponent.licensing2Pad,
        element: this.driverRequirementsComponent.licensing2Canvas
      },
      {
        pad: this.driverRequirementsComponent.licensing3Pad,
        element: this.driverRequirementsComponent.licensing3Canvas
      },
      {
        pad: this.driverRequirementsComponent.hoursOfWork1Pad,
        element: this.driverRequirementsComponent.hoursOfWork1Canvas
      },
      {
        pad: this.driverRequirementsComponent.hoursOfWork2Pad,
        element: this.driverRequirementsComponent.hoursOfWork2Canvas
      },
      {
        pad: this.driverRequirementsComponent.hoursOfWork3Pad,
        element: this.driverRequirementsComponent.hoursOfWork3Canvas
      },
      {
        pad: this.driverRequirementsComponent.preTripInspection1Pad,
        element: this.driverRequirementsComponent.preTripInspection1Canvas
      },
      {
        pad: this.driverRequirementsComponent.loadSecurity1Pad,
        element: this.driverRequirementsComponent.loadSecurity1Canvas
      }
    ];
    this.applySignature(signaturePadsWithElements, signatureData);
  }
  applySignature(signaturePadsWithElements: { pad: any; element: any }[], signatureData) {
    signaturePadsWithElements.forEach(({ pad, element }) => {
      if (pad && element) {
        pad.clear();
        const canvas = element.nativeElement;
        pad.fromDataURL(signatureData, {
          ratio: 1,
          width: canvas.width,
          height: canvas.height
        });
      }
    });
  }

  allSignaturesPresent(): boolean {
    const signaturePads = [
      //signatures
      this.thirdPartyAuthComponent?.signaturePad,
      this.applicantInfoComponent?.signaturePad,
      this.toBeReadComponent?.signaturePad,
      this.drugAlcoholStatementComponent?.signaturePad,
      this.driverCertificationComponent?.signaturePad,
      this.medicalDeclarationComponent?.signaturePad,
      this.hoursOfServiceComponent?.signaturePad,
      this.vehicleMaintenanceComponent?.signaturePad,
      this.truckTrailerInsuranceComponent?.signaturePad,
      this.healthSafetyComponent?.signaturePad,
      this.trafficTicketsComponent?.signaturePad,
      this.inspectionsViolationsComponent?.signaturePad,
      this.licenseStatusComponent?.signaturePad,
      this.confidentialityPolicyComponent?.signaturePad,
      this.safeDrivingPolicyComponent?.signaturePad,
      this.entryNumbersSealPolicyComponent?.signaturePad,
      this.seatBeltPolicyComponent?.signaturePad,
      this.handheldDevicePolicyComponent?.signaturePad,
      this.reeferPolicyComponent?.signaturePad,
      this.pspAuthorizationComponent?.signaturePad,

      //initials
      this.driverRequirementsComponent.licensing1Pad,
      this.driverRequirementsComponent.licensing2Pad,
      this.driverRequirementsComponent.licensing3Pad,
      this.driverRequirementsComponent.hoursOfWork1Pad,
      this.driverRequirementsComponent.hoursOfWork2Pad,
      this.driverRequirementsComponent.hoursOfWork3Pad,
      this.driverRequirementsComponent.preTripInspection1Pad,
      this.driverRequirementsComponent.loadSecurity1Pad
    ];

    return signaturePads.every(pad => {
      if (!pad) {
        return false;
      }

      return !pad.isEmpty();
    });
  }
  getDqfData(): void {
    if (!this.driverQualificationService.dqfId || this.dqfId) {
      this.router.navigate([`${ROUTE_DQF}`]);
      return;
    }
    this.dqfId = this.driverQualificationService.dqfId;
    if (!this.driverQualificationService.dqfData) {
      this.driverQualificationService.getDqfById(this.dqfId).subscribe((response: DqfDocument) => {
        if (response) {
          this.dqfData = response;
          return of(this.dqfData);
        }
      });
    }
    this.dqfData = this.driverQualificationService.dqfData;
    this.employmentForm.patchValue(
      {
        carrierCompanyName: this.dqfData?.carrierCompanyName,
        prospectiveEmployerName: this.dqfData?.carrierCompanyName,
        managedUserCompanyName: this.dqfData?.managedUserCompanyName
      },
      { emitEvent: false }
    );
  }
  getDeclarationFormData(): void {
    if (this.dqfData) {
      let filterType = DOCUMENT_TYPE_SIGN;

      this.declarationFormDqfData = this.dqfData.attachedDocuments.filter(e => e.type === filterType);
      this.determineSignatureStatus();
    }
  }
  determineSignatureStatus(): void {
    if (this.declarationFormDqfData.length) {
      if (this.declarationFormDqfData[0].status === REJECTED_STATUS) {
        this.isRejected = true;
      }
    }
  }
  loadDocuments(turnOffSkeletonLoading?: boolean): void {
    if (!turnOffSkeletonLoading) {
      this.isContentReady = false;
    }
    this.registrationService
      .loadDriver(DISABLE_TOP_LOADING_BAR)

      .pipe(
        tap((driver: Driver) => {
          if (driver) {
            this.driver = driver;
            const driverName = this.driver?.firstName ? `${this.driver?.firstName} ${this.driver?.lastName}` : '';
            const licenseExpiryDate = this.driver?.expiryDate
              ? dayjs(this.driver?.expiryDate).format('YYYY-MM-DD')
              : null;
            this.employmentForm.patchValue({
              driverName: driverName,
              driverLicenseNo: this.driver?.licenseNumber,
              expiryDate: licenseExpiryDate
            });
          }
        }),
        map(() => this.store.selectSnapshot(RegistrationState.notification))
      )
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications.length) {
          const filterContent = DOCUMENT_TYPE_SIGN;

          const filteredValue = notificationStateModel.notifications.filter(
            notification =>
              notification.type === filterContent && notification?.dqfId === this.driverQualificationService.dqfId
          );
          if (!turnOffSkeletonLoading) {
            this.isContentReady = true;
          }

          if (filteredValue.length) {
            this.declarationFormDriverData = filteredValue[0];

            // Document is available
            if (this.declarationFormDriverData.status === UPLOADED_STATUS) {
              this.isSigning = false;
              // document is not available
            } else if (this.declarationFormDriverData.status === PENDING_STATUS.toLocaleLowerCase()) {
              this.isSigning = true;
            } else {
              this.isSigning = true;
            }
          } else {
            this.isSigning = true;
          }
        } else {
          this.isSigning = true;
        }
        if (!turnOffSkeletonLoading) {
          this.isContentReady = true;
        }
      });
  }
  viewDocument(): void {
    if (!this.declarationFormDriverData?.id) {
      return;
    }
    this.router.navigateByUrl(`${ROUTER_DQF_VIEW}/${this.declarationFormDriverData?.id}`);
  }
  deleteDocument(): void {
    if (!this.declarationFormDriverData?.id) {
      return;
    }
    this.isContentReady = false;
    this.driverQualificationService
      .deleteDqfDocument(this.declarationFormDriverData?.id, DISABLE_TOP_LOADING_BAR)
      .subscribe(
        () => {
          this.toastService.showSuccess(DELETED_DOCUMENT);
          this.backAction();
          this.isContentReady = true;
        },
        error => {
          this.isContentReady = true;

          return of(false);
        }
      );
  }
  updateDqfDocStatus(docModel: any): Observable<any> {
    return this.driverQualificationService.updateDqfStatus(this.driverQualificationService.dqfId, docModel);
  }
  backAction(): void {
    this.location.back();
  }
  scrollToPage(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }
}

import dayjs from 'dayjs';
export class MainTrafficConviction {
  id: string;
  noConvictions: boolean;
  constructor(data: MainTrafficConviction) {
    this.id = data?.id || null;
    this.noConvictions = data?.noConvictions || false;
  }
}

export class TrafficConviction {
  id: string;
  convictionDate: string | Date;
  violationType: string;
  otherViolationType: string;
  stateOfViolation: string;
  penalty: string;
  demeritPoints: number;
  notes: string | null;
  constructor(data: TrafficConviction) {
    this.id = data?.id || null;
    this.convictionDate = (dayjs(data?.convictionDate).format('YYYY-MM-DD') as string) || null;
    this.violationType = data?.otherViolationType ? data?.otherViolationType : data?.violationType || null;
    this.stateOfViolation = data?.stateOfViolation || null;
    this.penalty = data?.penalty || null;
    this.demeritPoints = data?.demeritPoints;
    this.notes = data?.notes?.trim() ? data.notes.trim() : '';
  }
}

<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground profile-license">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">License {{ title }} View</span>
  </div>
  <div *ngIf="!showUploadedPicture">
    <div class="button-box padding-top-lg" fxFlex *ngIf="!isApiLoading">
      <a mat-button [href]="previewImage || safeUrl" target="_blank" class="button"
        ><mat-icon svgIcon="arrow-down-icon" class="icon-color icon-size"></mat-icon> Download
      </a>
    </div>
    <div> 
      <p class="p-font">License {{ title }} Image</p>
    </div> 
    <div class="details">
      <p class="date" *ngIf="generatedDate"><span class="text-name">UPLOADED ON</span> {{ this.generatedDate }}</p>
      <div class="camera-container">
        <div class="add-new">
          <div class="replace">
            <button mat-button (click)="openGallery(galleryImageInput)">
              <span>
                <mat-icon svgIcon="replace"></mat-icon>
                <p class="replace-para">Replace image</p>
              </span>
            </button>
            <input
            #galleryImageInput
            type="file"
            name="image"
            (change)="onProcessPictureFile($event)"
            [accept]="acceptTypesPicture"
            data-test="add-photo"
            style="display: none;"
          />
          </div>
          <!-- <mat-menu #addmenu="matMenu" class="button-menu-container" xPosition="after">
            <button mat-menu-item class="menu-button" (click)="openGallery(galleryImageInput)">
              <mat-icon svgIcon="image-upload"></mat-icon>
              <span>Upload Image</span>
            </button>
            <input
              #galleryImageInput
              type="file"
              name="image"
              (change)="onProcessPictureFile($event)"
              [accept]="acceptTypesPicture"
              data-test="add-photo"
              style="display: none;"
            />
          </mat-menu> -->
        </div>
      </div>
    </div>
    <div class="disclaimer-text">Data extraction will be performed on the JPEG, JPG and PNG document types.</div>
    <img
      [src]="previewImage"
      *ngIf="previewImage && !showUploadedPicture && !isApiLoading"
      class="imagePreview padding-md margin-top-xlg"
    />
    <iframe [src]="safeUrl" class="safeUrl" *ngIf="safeUrl" frameborder="0" loading="lazy"></iframe>
  </div>
  <div *ngIf="showUploadedPicture">
    <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
      <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
        <button
          class="margin-bottom secondary-button"
          mat-raised-button
          (click)="onCancelCrop()"
          data-test="cancel-button"
          [disabled]="isApiLoading"
        >
          Cancel
        </button>
        <button mat-raised-button class="rotate-icons-1 resize" (click)="rotateLeft()">
          <mat-icon class="icon-resize">rotate_left</mat-icon>
        </button>
        <button mat-raised-button class="rotate-icons-1 resize" (click)="rotateRight()">
          <mat-icon class="icon-resize">rotate_right</mat-icon>
        </button>
        <button
          class="margin-bottom primary-button finish-crop-button"
          mat-raised-button
          (click)="onFinish()"
          data-test="finish-button"
        >
          Finish
        </button>
      </div>
    </div>
    <div class="content" *ngIf="showCrop">
      <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
        <image-cropper
          #previewImage
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [imageQuality]="100"
          cropperMinWidth="420"
          cropperMinHeight="230"
          [cropperStaticWidth]="420"
          [cropperStaticHeight]="240"
          [cropperPosition]="cropperPosition"  
          [aspectRatio]="4 / 4"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [format]="imageFormat"
          (imageCropped)="onImageCropped($event)"
          (imageLoaded)="onImageLoaded()"
          (cropperReady)="onCropperReady()"
          (loadImageFailed)="onLoadImageFailed()"
        >
        </image-cropper>
      </div>
    </div>
  </div>
</div>

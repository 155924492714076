import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFDocument, rgb } from 'pdf-lib';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFHelpers, PDFFonts } from '../../pdf-interfaces';

@Component({
  selector: 'app-page3-to-read-signed',
  templateUrl: './page3-to-read-signed.component.html',
  styleUrls: ['../shared.scss', './page3-to-read-signed.component.scss']
})
export class Page3ToReadSignedComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { x, pageWidth, pageHeight, margin } = position;
    const maxWidth = pageWidth - 2 * margin;

    // Always start page 3 on a new page to avoid overlap with page 2
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    let currentPage = newPage;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.pageCount + 1);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const checkPageBreak = (requiredSpace: number = margin * 2) => {
      if (currentY < requiredSpace) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);

        if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
          formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
          formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
        }

        currentY = position.headerOffset || pageHeight - margin;

        return true;
      }
      return false;
    };

    // Draw centered heading
    const headingText = 'To Be Read and Signed by Applicant';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Improved approach for the first paragraph with inline fields
    // Create a paragraph where we manually position each element

    // "I, " text
    currentPage.drawText('I,', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Draw the driver name field
    const driverNameOffset = x + fonts.regular.widthOfTextAtSize('I, ', 12);
    const driverNameValue = formValues.driverName || '';
    currentPage.drawText(driverNameValue, {
      x: driverNameOffset,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for driver name
    const driverNameWidth = fonts.bold.widthOfTextAtSize(driverNameValue, 12);
    currentPage.drawLine({
      start: { x: driverNameOffset, y: currentY - 3 },
      end: { x: driverNameOffset + Math.max(150, driverNameWidth), y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Move to next line
    currentY -= 20;

    // First part of paragraph text
    const certifyText =
      'certify that this application was completed by me, and that all entries on it and information in it are true and completed to the best of my knowledge. I authorize (';

    // Draw paragraph text with wrapping
    let lineStart = x;
    const words = certifyText.split(' ');
    let currentLine = '';

    for (const word of words) {
      const withWord = currentLine + word + ' ';
      const lineWidth = fonts.regular.widthOfTextAtSize(withWord, 12);

      if (lineWidth > maxWidth) {
        // Draw current line and move to next
        currentPage.drawText(currentLine, {
          x: lineStart,
          y: currentY,
          size: 12,
          font: fonts.regular
        });
        currentY -= 16;
        checkPageBreak();
        currentLine = word + ' ';
      } else {
        currentLine = withWord;
      }
    }

    // Draw the last line of first paragraph part
    currentPage.drawText(currentLine, {
      x: lineStart,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate end of text to position company name field
    const companyNameOffset = lineStart + fonts.regular.widthOfTextAtSize(currentLine, 12);

    // Draw the company name field
    const companyNameValue = formValues.carrierCompanyName || '';
    currentPage.drawText(companyNameValue, {
      x: companyNameOffset,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for company name
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyNameValue, 12);
    currentPage.drawLine({
      start: { x: companyNameOffset, y: currentY - 3 },
      end: { x: companyNameOffset + Math.max(150, companyNameWidth), y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Move to next line
    currentY -= 20;

    // Second part of paragraph text
    const investigationsText =
      ') and their assigned agents, to make such investigations and inquiries of my personal, employment, financial (credit bureau) information, criminal search, driving abstracts, drug results from previous employers or their consortium or their insurance carrier or Agent for my driving record, insurance history, medical history, and other related matters as may be necessary in arriving at an employment decision.';

    // Draw this text as a regular paragraph with wrapping
    const drawWrappedText = (text, startX, startY) => {
      const words = text.split(' ');
      let line = '';
      let lineX = startX;
      let lineY = startY;

      for (const word of words) {
        const testLine = line + word + ' ';
        const testWidth = fonts.regular.widthOfTextAtSize(testLine, 12);

        if (lineX + testWidth > startX + maxWidth) {
          currentPage.drawText(line, {
            x: lineX,
            y: lineY,
            size: 12,
            font: fonts.regular
          });
          lineY -= 16;
          checkPageBreak();
          line = word + ' ';
          lineX = startX;
        } else {
          line = testLine;
        }
      }

      if (line.trim()) {
        currentPage.drawText(line, {
          x: lineX,
          y: lineY,
          size: 12,
          font: fonts.regular
        });
        lineY -= 16;
      }

      return lineY;
    };

    currentY = drawWrappedText(investigationsText, x, currentY);
    currentY -= 15;

    // Additional paragraphs
    const paragraphs = [
      'If hired or contracted, this authorization shall remain on file and shall service as ongoing authorization to recheck or report as deemed necessary at any time throughout my employment or contract period or after such period. (Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended).',

      'I hereby release employers, schools, health care providers and other persons from all liability in responding to inquiries and releasing information in connection with my application. In the event of employment, I understand that false or misleading information given in my application or interview(s) may result in discharge.',

      `Furthermore, I understand that (${formValues.carrierCompanyName}) and/or their Agents may keep any information on file including work performance as related to my employment period and make it available to any second party only with my verbal or written consent. I understand, also, that I am required to abide by all rules and regulations of company.`,

      "For purposes of gathering this information, I have supplied information which may be required by law enforcement agencies and other entities for positive identification purposes when checking records, including Date of Birth, residence history and Driver's License Number/Province. It is confidential and will not be used for any other purpose."
    ];

    // Draw each paragraph with proper wrapping
    for (const paragraph of paragraphs) {
      currentY = drawWrappedText(paragraph, x, currentY);
      currentY -= 15;
      checkPageBreak();
    }

    // Driver name and signature section
    // Driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 100,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for name
    currentPage.drawLine({
      start: { x: x + 95, y: currentY - 3 },
      end: { x: x + maxWidth / 2, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    currentY -= 25;

    // Check for page break before signature
    if (currentY < margin + 70) {
      // Need at least 70 points for signature
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw signature
    const signature = formValues.signatures?.get('driverSignaturePage3');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    // Check for page break
    checkPageBreak();

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 50,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x: x + 45, y: currentY - 3 },
      end: { x: x + 200, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: pageHeight - margin - currentY,
      newPage: true,
      position: {
        ...position,
        y: currentY
      }
    };
  }
}

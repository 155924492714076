import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { DocumentsApiService } from 'src/app/shared/documents-data/documents-api.service';
import { map } from 'rxjs/operators';
import * as mime from 'mime';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseDataService {
  constructor(private readonly driverApiService: DriverApiService, private readonly fileDataService: FileDataService) {}
  uploadLicense(model: any): Observable<any> {
    return this.driverApiService.uploadLicense(model).pipe(map(response => response?.data));
  }
  updateLicense(model: any, licenseId: any): Observable<any> {
    return this.driverApiService.updateLicense(model, licenseId).pipe(map(response => response?.data));
  }
  sendImageFiles(frontLicense, backLicense, model: any): Observable<any> {
    if (model) {
      const frontLicenseUrl = model.frontScanImageUrl;
      const frontLicenseNewName = model.frontScanImageFile;
      const backLicenseUrl = model.backScanImageUrl;
      const backLicenseNewName = model.backScanImageFile;
      
      const newFrontFile = new File([frontLicense], frontLicenseNewName, { type: frontLicense.type });
      const frontFileName = `${newFrontFile.name}.${newFrontFile.type}`;
      const frontFileContentType = mime.getType(frontFileName) ?? 'image/png';
      
      const newBackFile = new File([backLicense], backLicenseNewName, { type: backLicense.type });
      const backFileName = `${newBackFile.name}.${newBackFile.type}`;
      const backFileContentType = mime.getType(backFileName) ?? 'image/png';

      return forkJoin([
        this.fileDataService.uploadFile(newFrontFile, frontLicenseUrl, frontFileContentType),
        this.fileDataService.uploadFile(newBackFile, backLicenseUrl, backFileContentType)
      ]).pipe(map(response => response));
    }
  }
  uploadFrontLicenseImage(frontLicense: File, model: any): Observable<any> {
    if (model && frontLicense) {
      const frontLicenseUrl = model.frontScanImageUrl;
      const frontLicenseNewName = model.frontScanImageFile;
  
      const newFrontFile = new File([frontLicense], frontLicenseNewName, { type: frontLicense.type });
      const frontFileName = `${newFrontFile.name}.${newFrontFile.type}`;
      const frontFileContentType = mime.getType(frontFileName) ?? 'image/png';
  
      return this.fileDataService.uploadFile(newFrontFile, frontLicenseUrl, frontFileContentType);
    }
  
    return of(null);
  }

  uploadBackLicenseImage(backLicense: File, model: any): Observable<any> {
    if (model && backLicense) {
      const backLicenseUrl = model.backScanImageUrl;
      const backLicenseNewName = model.backScanImageFile;
  
      const newBackFile = new File([backLicense], backLicenseNewName, { type: backLicense.type });
      const backFileName = `${newBackFile.name}.${newBackFile.type}`;
      const backFileContentType = mime.getType(backFileName) ?? 'image/png';
  
      return this.fileDataService.uploadFile(newBackFile, backLicenseUrl, backFileContentType);
    }
  
    return of(null);
  }
  
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/authentication.module';
import { FrameNavigationService } from '../shared/frame-navigation.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import {
  ROUTE_LOADS,
  ROUTE_DRIVER_HISTORY,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_REFER_A_FRIEND,
  ROUTE_TELL_A_FRIEND,
  ROUTE_ADD_TO_HOME_SCREEN,
  ROUTE_DQF
} from 'src/app/shared/routes';
import { PROFILE_INCOMPLETE_STATUS } from 'src/app/shared/services/driver-api.service';
import { SHIPMENTS_HISTORY } from 'src/app/shipments/shared/routes';

class TabNavLink {
  title: string;
  link: string;
  clickHandler: () => void;
}

@Component({
  selector: 'app-overflow-menu',
  templateUrl: './overflow-menu.component.html',
  styleUrls: ['./overflow-menu.component.scss']
})
export class OverflowMenuComponent implements OnInit {
  links: TabNavLink[] = [];

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<OverflowMenuComponent>,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly frameNavigationService: FrameNavigationService,
    @Inject('environmentData') public environment: any
  ) {}

  ngOnInit() {
    if (this.environment?.featureFlags?.isDQFEnable) {
      this.links.push({
        title: 'Driver Qualification File',
        link: `/${ROUTE_DQF}`,
        clickHandler: () => this.navigateToDriverQualification()
      } as TabNavLink);
    }

    this.links.push({
      title: 'Account settings',
      link: `/${ROUTE_ACCOUNT_SETTINGS}`,
      clickHandler: () => this.navigateToAccountSettings()
    } as TabNavLink);
    this.links.push({
      title: 'Refer a friend',
      link: `/${ROUTE_REFER_A_FRIEND}`,
      clickHandler: () => this.navigateToReferFriend()
    } as TabNavLink);
    this.links.push({
      title: 'Add to Home Screen',
      link: `/${ROUTE_ADD_TO_HOME_SCREEN}`,
      clickHandler: () => this.navigateToAddToHomeScreen()
    } as TabNavLink);
    if (
      this.registrationService.getDriverIdentityStatus() !== PROFILE_INCOMPLETE_STATUS &&
      !this.registrationService.getDriver()?.companyAssociatedId
    ) {
      this.links.push({
        title: 'History',
        link: `/${ROUTE_DRIVER_HISTORY}`,
        clickHandler: () => this.navigateToDriverHistory()
      } as TabNavLink);
    }
    this.links.push({
      title: 'Shipments history',
      link: `/${SHIPMENTS_HISTORY}`,
      clickHandler: () => this.navigateToShipmentsHistory()
    } as TabNavLink);
    this.links.push({ title: 'Logout', link: undefined, clickHandler: () => this.logout() } as TabNavLink);
  }

  onOpenLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
  private navigateToDriverQualification() {
    this.frameNavigationService.navigateToDriverQualificaton();
  }
  private navigateToAccountSettings() {
    this.frameNavigationService.navigateToAccountSettings();
  }

  private navigateToReferFriend() {
    this.frameNavigationService.navigateToReferFriend();
  }

  private navigateToAddToHomeScreen() {
    this.frameNavigationService.navigateToAddToHomeScreen();
  }

  private navigateToDriverHistory() {
    this.frameNavigationService.navigateToDriverHistory();
  }

  private navigateToShipmentsHistory(){
    this.frameNavigationService.navigateToShipmentsHistory()
  }

  private logout(): void {
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }
}

import { Injectable } from '@angular/core';
import { ImageCompressModule } from './image-compress.module';

@Injectable({
  providedIn: ImageCompressModule
})
export class ImageCompressService {
  compressImage(file: File, maxSizeMB: number = 1): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result as string;
        img.onload = () => {
          let quality = 0.6; // Start with 60% quality
          const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

          // Function to compress with specific quality
          const compress = (q: number): Promise<File> => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            // Calculate new dimensions while maintaining aspect ratio
            const maxDimension = 1920;
            if (width > height && width > maxDimension) {
              height = (height * maxDimension) / width;
              width = maxDimension;
            } else if (height > maxDimension) {
              width = (width * maxDimension) / height;
              height = maxDimension;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            return new Promise(resolve => {
              canvas.toBlob(
                blob => {
                  const compressedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: Date.now()
                  });
                  resolve(compressedFile);
                },
                file.type,
                q
              );
            });
          };

          // Try to compress until file size is under maxSizeBytes
          const attemptCompress = async () => {
            let compressedFile = await compress(quality);

            // If file is still too large, reduce quality and try again
            while (compressedFile.size > maxSizeBytes && quality > 0.1) {
              quality -= 0.1;
              compressedFile = await compress(quality);
            }

            resolve(compressedFile);
          };

          attemptCompress().catch(reject);
        };
        img.onerror = reject;
      };
      reader.onerror = reject;
    });
  }
}

<div class="page gray-font-color font-family bodyBackground residence-history">
  <div class="header">
    Add Previous Address
  </div>
  <app-skeleton-emp-residence-history
    *ngIf="!isContentReady"
    isResidenceHistory="true"
  ></app-skeleton-emp-residence-history>
  <div class="address-fields" [formGroup]="previousAddressForm" fxLayout="column" *ngIf="isContentReady">
    <div fxLayout="column" *ngIf="!turnOffAddressLookup; else manualAddress">
      <div fxFlex="15" class="turnoff-text" (click)="onTurnOffAddressAutoLookup()">Turn OFF address lookup</div>
      <div fxFlex="15">
        <mat-label>ADDRESS</mat-label>
        <mat-form-field class="full-width">
          <input matInput id="previousAddressText" formControlName="fullAddress" #previousAddressText />
          <mat-error *ngIf="fullAddress.touched && fullAddress.invalid">
            {{ getFullAddressErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Address Lookup turned Off -->
    <ng-template #manualAddress>
      <div fxLayout="column" *ngIf="turnOffAddressLookup">
        <div fxFlex="15" class="turnon-text" (click)="onTurnOnAddressAutoLookup()">Turn ON address lookup</div>
          <mat-label>ADDRESS</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="previousAddress" />
            <mat-error *ngIf="previousAddress.touched && previousAddress.invalid">
              {{ getPreviousAddressErrorMessage() }}
            </mat-error>
          </mat-form-field>
        <div fxFlex="15">
          <mat-label>COUNTRY</mat-label>
          <mat-form-field class="full-width">
            <mat-select
              formControlName="previousAddressCountry"
              (selectionChange)="onCountryChange()"
              data-test="pre-select-country"
            >
              <mat-form-field class="dropdown-search full-width" appearance="outline">
                <input
                  matInput
                  placeholder="Search..."
                  (keydown)="$event.stopPropagation()"
                  (keyup)="onCertnCountrySearch($event)"
                  data-test="pre-txt-country"
                />
              </mat-form-field>

              <mat-option *ngFor="let country of countries" [value]="country.value" data-test="pre-country-name">
                {{ country.key }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="previousAddressCountry.touched && previousAddressCountry.invalid">
              {{ getPreviousAddressCountryErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>STATE / PROVINCE </mat-label>
          <mat-form-field class="full-width">
            <mat-select
              formControlName="previousAddressState"
              *ngIf="
                previousAddressCountry &&
                  previousAddressCountry.value &&
                  ['US', 'CA'].includes(previousAddressCountry.value.toUpperCase());
                else PreviousAddressStateInput
              "
            >
              <mat-option value="">Select province</mat-option>
              <ng-container
                *ngIf="
                  (previousAddressCountry &&
                    previousAddressCountry.value &&
                    previousAddressCountry.value == 'Canada') ||
                    previousAddressCountry.value == 'CA';
                  else usaOptions
                "
              >
                <mat-option *ngFor="let province of canadaProvinces" [value]="province.value">{{
                  province.name
                }}</mat-option>
              </ng-container>
              <ng-template #usaOptions>
                <mat-option *ngFor="let province of usaProvinces" [value]="province.value">{{
                  province.name
                }}</mat-option>
              </ng-template>
            </mat-select>
            <mat-error *ngIf="previousAddressCity.touched && previousAddressCity.invalid">
              {{ getPreviousAddressCityErrorMessage() }}
            </mat-error>
            <ng-template #PreviousAddressStateInput>
              <input matInput formControlName="previousAddressState" />
            </ng-template>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>CITY</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="previousAddressCity" />
            <mat-error *ngIf="previousAddressCity.touched && previousAddressCity.invalid">
              {{ getPreviousAddressCityErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-label>ZIPCODE / POSTAL CODE</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="previousAddressZipCode" />
            <mat-error *ngIf="previousAddressZipCode.touched && previousAddressZipCode.invalid">
              {{ getPreviousAddressZipCodeErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
    <div fxFlex="15" *ngIf="minFromDate">
      <mat-label>FROM (MONTH , YEAR)</mat-label>
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="I have lived here since"
          formControlName="previousAddressFromDate"
          [matDatepicker]="fromPreviousAddressPicker"
          [max]="previousAddressForm.get('previousAddressToDate').value || maxFromDate"
          [min]="minFromDate"
          required
          (click)="onDatePickerClick(fromPreviousAddressPicker)"
          onkeydown="return false;"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromPreviousAddressPicker"
          data-test="previous-from-date"
        ></mat-datepicker-toggle>
        <mat-datepicker
          touchUi="true"
          startView="multi-year"
          #fromPreviousAddressPicker
          (monthSelected)="chosenMonthHandler($event, fromPreviousAddressPicker, 'previous')"
        >
        </mat-datepicker>
        <!-- <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{ getPreviousAddressFromDate() }}
            </mat-error> -->
      </mat-form-field>
    </div>
    <div fxFlex="15" *ngIf="maxFromDate">
      <mat-label>TO (MONTH , YEAR)</mat-label>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="previousAddressToDate"
          [matDatepicker]="previousAddressToDatePicker"
          [max]="maxFromDate"
          [min]="previousAddressForm.get('previousAddressFromDate').value || minFromDate"
          (click)="onDatePickerClick(previousAddressToDatePicker)"
          onkeydown="return false;"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="previousAddressToDatePicker"
          data-test="previous-to-date"
        ></mat-datepicker-toggle>
        <mat-datepicker
          touchUi="true"
          startView="multi-year"
          (monthSelected)="chosenMonthHandler($event, previousAddressToDatePicker, 'toDateControl')"
          #previousAddressToDatePicker
        >
        </mat-datepicker>
        <!-- <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{ getPreviousAddressToDate() }}
            </mat-error> -->
      </mat-form-field>
    </div>
  </div>
  <div class="fixed-footer">
    <button mat-button class="btn-cancel" (click)="navigateToListPage()" [disabled]="!isContentReady">Cancel</button>
    <button
      mat-raised-button
      class="btn-save margin-right-sm"
      (click)="onSubmit()"
      [disabled]="!previousAddressForm.valid || !isContentReady"
      data-test="save-button"
    >
      Save
    </button>
  </div>
</div>

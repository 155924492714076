<h2>Truck and Trailer Insurance</h2>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  maintains appropriate coverage on all equipment.
</p>
<p>
  The deductible amount is stated on the Owner Operator agreement. Owner/Operators are encouraged to consider our
  "Buy-Down" coverage to mitigate unexpected costs in the case of a major mechanical breakdown or an accident.
</p>
<p>
  The Safety Department will review your Permit Book with you so that you can locate the Insurance Certificate and "pink
  slip" should the need arise.
</p>
<p>Driver must check certificate of insurance and pick slip for vehicle in truck binder.</p>
<h2>PERSONAL PROTECTIVE EQUIPMENT</h2>
<p>
  All drivers are required to have with them at all times: safety vest, safety glasses, work boots, gloves and hard hat.
  It is the responsibility of each employee to utilize such safety equipment when on the premises of customers as may be
  required by such in the course of their duties on behalf of
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  Any notification received by company in regards to an employee breach of our customers rules and regulations will
  result in disciplinary action.
</p>
<h2>NO PASSENGERS POLICY</h2>
<p>
  This policy extends to any "ride along" family or friends of our drivers. Anyone who is not an employee or contractor
  of
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  or assigned to the trip by Dispatch or the Safety Department are prohibited from riding in our equipment, or any
  equipment running under the authority of company. In addition, pets are prohibited from riding in any company-owned
  equipment without Management Approval and a signed damages waiver. Damages from pets WILL BE CHARGED to the
  responsible driver including cleaning, replacement of carpeting, etc. Due to liability and security concerns, Company
  will strictly enforce this No Passengers or Pets Policy. Any violation is also in violation of our C-TPAT policies. No
  family, friends, or other passengers are to be transported. Liability lays solely with the driver for legal
  responsibilities, costs, and damages.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 17 of 30</p>

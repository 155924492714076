import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { catchError } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { AccidentRecord } from 'src/app/shared/models/accident-record.model';
import { DatePipe } from '@angular/common';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { environment } from 'src/environments/environment.dev';
import { ACCIDENT_NATURE } from '../shared/data-const/accidentNature';
import dayjs from 'dayjs';
import { Location } from '@angular/common';
import { of } from 'rxjs';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const ACTION_SAVE_ACCIDENT_RECORD = 'Save accident record';
const ACTION_UPDATE_ACCIDENT_RECORD = 'Update accident record';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-accident-records',
  templateUrl: './accident-records.component.html',
  styleUrls: ['./accident-records.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AccidentRecordsComponent implements OnInit {
  openAccidentForm = false;
  allowEdit = false;
  isNoAccidentPresent: boolean = false;
  errorTimeout = null;
  errorMessage: string;
  accidentRecords: AccidentRecord[];
  noAccidentRecord: AccidentRecord;
  noAccidentRecordsForm: FormGroup;
  accidentForm: FormGroup;
  disableNoAccidents: boolean;
  maxDate: Date = new Date();
  enableSave: boolean = false;
  accidentNatures = ACCIDENT_NATURE;
  isContentReady: boolean = false;
  numInvolved = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'];

  private subs = new SubSink();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly driverApiService: DriverApiService,
    private readonly dateTimeService: DateTimeService
  ) {
    this.errorMessage = undefined;
    this.accidentForm = this.createForm();
    this.noAccidentRecordsForm = this.createNoAccidentRecordsForm();
  }

  ngOnInit(): void {
    this.loadAccidentRecords();
    this.noAccidentRecordsForm.get('noAccidents').valueChanges.subscribe(value => {
      if (value != this.noAccidentRecord?.noAccidents) {
        this.enableSave = true;
      } else {
        this.enableSave = false;
      }
      if (!this.noAccidentRecord?.noAccidents) {
        if (value) {
          this.allowEdit = true;
        } else {
          this.allowEdit = false;
        }
      }
    });
  }

  loadAccidentRecords(): void {
    this.accidentRecords = [];
    this.isContentReady = false;
    this.registrationService.getAccidentRecords().subscribe(records => {
      const sortedRecords = records.sort(
        (a, b) => new Date(b.accidentDate).getTime() - new Date(a.accidentDate).getTime()
      );
      sortedRecords.forEach(record => {
        if (record.hasOwnProperty('noAccidents')) {
          this.noAccidentRecord = record;
        } else {
          this.accidentRecords.push(record);
        }
        record.accidentDate = dayjs(record.accidentDate, 'YYYYMMDD').toDate();
      });
      if (this.noAccidentRecord) {
        this.allowEdit = this.noAccidentRecord?.noAccidents && this.noAccidentRecord.noAccidents ? true : false;
        this.populateNoAccidentForm(this.noAccidentRecord);
      }
      if (this.accidentRecords && this.accidentRecords.length > 0) {
        this.disableNoAccidents = true;
      } else {
        this.disableNoAccidents = false;
      }
      this.isContentReady = true;
    });
  }

  onAddNewAccidentRecord(): void {
    this.openAccidentForm = true;
    this.allowEdit = true;
    this.disableNoAccidents = true;
  }

  onCancel(): void {
    this.openAccidentForm = false;
    this.allowEdit = false;
    this.disableNoAccidents = false;
    this.accidentForm.reset();
  }

  goBack(): void {
    this.location.back();
  }

  onSubmitAccidentRecord(formType: String): void {
    if (!this.accidentForm.valid && !this.noAccidentRecordsForm.valid) {
      return;
    }

    let model;
    if (formType == 'addNewRecord') {
      model = !model && this.accidentForm.dirty ? this.prepareAccidentSaveModel() : model;
    } else if (formType == 'noAccident') {
      model = this.noAccidentRecordsForm.dirty ? this.prepareNoAccidentRecordsSaveModel() : undefined;
    }
    if (model && model.id) {
      let id = model.id;
      delete model.id;
      this.onAccidentRecordUpdated(model, id);
    } else if (model) {
      if (model.id == '' || model.id == null) {
        delete model.id;
      }
      this.onAccidentRecordAdded(model);
    } else {
      console.log(`Error: There is no data to save.`);
    }
  }
  changeNoAccident() {
    const model = this.prepareNoAccidentRecordsSaveModel();
    if (model && model.id) {
      let id = model.id;
      delete model.id;
      this.onAccidentRecordUpdated(model, id);
    } else if (model) {
      if (model.id == '' || model.id == null) {
        delete model.id;
      }
      this.onAccidentRecordAdded(model);
    } else {
      console.log(`Error: There is no data to save.`);
    }
  }
  onUpdateAccidentPressed($event): void {
    this.allowEdit = true;
    this.openAccidentForm = true;
    this.disableNoAccidents = true;

    if ($event) {
      this.populateForm($event);
    }
  }

  onDeleteAccidentPressed($event: AccidentRecord): void {
    this.isContentReady = false;
    this.subs.add(
      this.registrationService.deleteAccidentRecord($event.id).subscribe(
        () => {
          this.errorMessage = undefined;
          this.allowEdit = false;
          this.loadAccidentRecords();
        },
        () => {
          this.isContentReady = true;
          this.setErrorMessage(environment.errorMessage);
        }
      )
    );
  }

  get accidentDate() {
    return this.accidentForm.get('accidentDate');
  }

  get natureOfAccident() {
    return this.accidentForm.get('natureOfAccident');
  }

  get numOfFatalities() {
    return this.accidentForm.get('numOfFatalities');
  }

  get numOfInjuries() {
    return this.accidentForm.get('numOfInjuries');
  }

  get chemicalsSpilt() {
    return this.accidentForm.get('chemicalsSpilt');
  }

  getAccidentDateErrorMessage(): string {
    return this.accidentDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getNatureOfAccidentErrorMessage(): string {
    return this.natureOfAccident.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getNumOfFatalitiesErrorMessage(): string {
    return this.numOfFatalities.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getNumOfInjuriesErrorMessage(): string {
    return this.numOfInjuries.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getChemicalsSpiltErrorMessage(): string {
    return this.chemicalsSpilt.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  private onAccidentRecordAdded(model: AccidentRecord): void {
    this.isContentReady = false;
    this.subs.add(
      this.registrationService
        .saveAccidentRecord(model)
        .pipe(
          catchError((error: any) => {
            this.setErrorMessage(error.message);

            this.isContentReady = true;
            return of(false);
          })
        )
        .subscribe(() => {
          this.setErrorMessage(this.errorMessage ? this.errorMessage : undefined);
          this.accidentForm.reset();
          this.resetFlags();

          if (model.noAccidents) {
            this.location.back();
          }
          if (!this.errorMessage) {
            this.loadAccidentRecords();
          }
        })
    );
  }

  private onAccidentRecordUpdated(model: AccidentRecord, id: string): void {
    this.isContentReady = false;
    this.subs.add(
      this.registrationService
        .updateAccidentRecord(model, id)
        .pipe(
          catchError((error: any) => {
            this.setErrorMessage(error.message);

            this.isContentReady = false;
            return of(false);
          })
        )
        .subscribe(() => {
          this.setErrorMessage(this.errorMessage ? this.errorMessage : undefined);
          this.accidentForm.reset();
          this.resetFlags();
          if (model.noAccidents) {
            this.location.back();
          }

          if (!this.errorMessage) {
            this.loadAccidentRecords();
          }
        })
    );
  }

  private resetFlags(): void {
    this.allowEdit = false;
    this.openAccidentForm = false;
    this.disableNoAccidents = false;
  }

  private prepareAccidentSaveModel(): AccidentRecord {
    const formModel = this.accidentForm.value;

    const model = {
      id: formModel.id as string,
      accidentDate: this.datePipe.transform(formModel.accidentDate, 'yyyy-MM-dd') as string,
      natureOfAccident: formModel.natureOfAccident as string,
      numOfFatalities: formModel.numOfFatalities as string,
      numOfInjuries: formModel.numOfInjuries as string,
      chemicalsSpilt: formModel.chemicalsSpilt === 'true',
      notes: formModel.notes?.trim() || ''
    };

    return model as AccidentRecord;
  }

  private prepareNoAccidentRecordsSaveModel(): AccidentRecord {
    const formModel = this.noAccidentRecordsForm.value;
    const model = {
      id: formModel.id as string,
      noAccidents: formModel.noAccidents as boolean
    } as AccidentRecord;

    return model as AccidentRecord;
  }

  private resetNoAccidentRecordsForm(accidentRecord?: AccidentRecord): void {
    this.noAccidentRecordsForm.reset({
      id: accidentRecord ? accidentRecord.id : '',
      noAccidents: accidentRecord ? accidentRecord.noAccidents : false
    });
  }

  private createNoAccidentRecordsForm(): FormGroup {
    const form = this.formBuilder.group({
      id: [''],
      noAccidents: [false]
    });
    return form;
  }

  private createForm(): FormGroup {
    const form = this.formBuilder.group({
      id: [''],
      accidentDate: ['', Validators.required],
      natureOfAccident: ['', Validators.required],
      numOfFatalities: ['', Validators.required],
      numOfInjuries: ['', Validators.required],
      chemicalsSpilt: ['', Validators.required],
      notes: [
        '',
        [Validators.maxLength(140), Validators.pattern(/^[a-zA-Z0-9*&().,;#@!$-\s]*$/)]
      ]
    });

    return form;
  }

  private populateForm(accident: AccidentRecord): void {
    this.accidentForm.setValue({
      id: accident.id,
      accidentDate: accident.accidentDate,
      natureOfAccident: accident.natureOfAccident,
      numOfFatalities: accident.numOfFatalities,
      numOfInjuries: accident.numOfInjuries,
      chemicalsSpilt: accident.chemicalsSpilt ? 'true' : 'false',
      notes: accident.notes || ''
    });
  }
  private populateNoAccidentForm(accident: AccidentRecord) {
    this.noAccidentRecordsForm.setValue({
      id: accident.id,
      noAccidents: accident.noAccidents
    });
  }

  private setErrorMessage(message: string): void {
    this.errorMessage = message;

    if (this.errorMessage) {
      clearTimeout(this.errorTimeout);
      this.errorTimeout = setTimeout(() => {
        this.errorMessage = undefined;
      }, 7000);
    }
  }
}

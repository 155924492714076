import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import dayjs from 'dayjs';
import { LOAD_SIZE_FTL, LOAD_SIZE_LTL, STATUS_ASSIGNED, STATUS_CONFIRMATION_PENDING, STATUS_DELIVERED } from '../shared/constants';
import { SHIPMENTS_HISTORY, SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_SHIPMENTS } from '../shared/routes';
import { ShipmentsActionSheetComponent } from '../shipments-action-sheet/shipments-action-sheet.component';


const TYPE_PICKUP = 'pickUp';
const TYPE_DROP = 'dropOff';
const MM_DD_YYYY = 'MM/DD/YYYY';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-filter-shipments',
  templateUrl: './filter-shipments.component.html',
  styleUrls: ['./filter-shipments.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FilterShipmentsComponent implements OnInit {
  filterForm: FormGroup;
  loadSizes: string[] = [LOAD_SIZE_FTL, LOAD_SIZE_LTL];
  statusList: string[] = [STATUS_CONFIRMATION_PENDING, STATUS_ASSIGNED];
  historyStatusList: string[] = [STATUS_DELIVERED]
  countryMap = { Canada: 'CA', USA: 'US' };

  @ViewChild('pickUpText', { static: false }) pickUpText: any;
  @ViewChild('dropText', { static: false }) dropText: any;
  pageType: string = '';
  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly bottomSheet: MatBottomSheet,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.filterForm = this.createForm();
    let filters:any = window.history.state?.filter || {};
    if(filters) {
      this.filterForm.patchValue({
        ...filters
      });
    }
    this.statusList = this.statusList.map(el => this.convertToTitleCase(el))
    this.historyStatusList = this.historyStatusList.map(el => this.convertToTitleCase(el))
    this.pageType = window.history.state?.pageType
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete(this.pickUpText, TYPE_PICKUP);
    this.getPlaceAutocomplete(this.dropText, TYPE_DROP);
  }

  private convertToTitleCase(str: string): string {
    return str.replace(/\w\S*/g, t => t.charAt(0).toUpperCase() + t.substr(1));
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      fromDate:  [''],
      toDate:  [''],
      pickUp:  [''],
      pickUpCity: [''],
      pickUpState: [''],
      dropOff:  [''],
      dropOffCity: [''],
      dropOffState: [''],
      loadSize:  [''],
      status:  [''],
    })
    return form;
  }

  get fromDate() {
    return this.filterForm.get('fromDate');
  }
  get toDate() {
    return this.filterForm.get('toDate');
  }

  get pickUp() {
    return this.filterForm.get('pickUp');
  }

  get dropOff() {
    return this.filterForm.get('dropOff');
  }

  get loadSize() {
    return this.filterForm.get('loadSize');
  }

  get status() {
    return this.filterForm.get('status');
  }

  onNavigateBack(): void {
    this.navigateToShipmentsList();
  }

  onOpenLoadSizeActionSheet(): void {
    this.openActionSheet("Load Size",this.loadSizes,this.loadSize.value,'loadSize');
  }
  onOpenStatusActionSheet(): void {
    if(this.pageType === 'history') {
      this.openActionSheet("Status",this.historyStatusList,this.status.value,'status');
    } else {
      this.openActionSheet("Status",this.statusList,this.status.value,'status');
    }
  }

  openActionSheet(title: string, options: string[], selectedValue: string, field: string): void {
    this.bottomSheet.open(ShipmentsActionSheetComponent, {
      data: { 
        type: 'filter',
        title: title,
        options: options,
        selectedValue: selectedValue,
        onConfirm: (val: string) => {
         this.filterForm.patchValue({
          [field]: val
         })
        }
       }
    });
  }

  onApplyFilters() {
    this.navigateToShipmentsList();
  }

  onClearFilters() {
    this.filterForm.reset();
  }

  navigateToShipmentsList() {
    if(this.pageType === 'history'){
      this.router.navigate([`/${SHIPMENTS_HISTORY}`], { state: { filter: this.filterForm.value } });
    } else {
      this.router.navigate([`/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`], { state: { filter: this.filterForm.value } });
    }
  }

  formatAddressAndAlign(formattedAddess: string, formControlName: string, type: string): void {
    const address = formattedAddess?.split(',');
    const countrySuffix = this.countryMap[address?.[2]?.trim()];
    const addressTxt = `${address?.[0]},${address?.[1]?.trim().split(" ")[0]}, ${countrySuffix}`;
    if(type === TYPE_PICKUP) {
      this.filterForm.patchValue({
        [formControlName]: addressTxt,
        pickUpCity: address?.[0],
        pickUpState: address?.[1]?.trim().split(" ")[0]
      });
    } else {
      this.filterForm.patchValue({
        [formControlName]: addressTxt,
        dropOffCity: address?.[0],
        dropOffState: address?.[1]?.trim().split(" ")[0]
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  private getPlaceAutocomplete(addressText: any, type: string): void {
    const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement, {
      types: ['(cities)'],
      componentRestrictions: {
        country: [this.countryMap.Canada, this.countryMap.USA],
      },
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      autocomplete.setFields(['formatted_address']);
      const place = autocomplete.getPlace();

      if (place) {
        const formattedAddress = place?.formatted_address;
        this.formatAddressAndAlign(formattedAddress, addressText.nativeElement.name, type);
      }
    });

    return addressText;
  }

  onDateChangeEvent(event: MatDatepickerInputEvent<Date>, field: string) {
   this.filterForm.patchValue({
    [field]: dayjs(new Date(event.value)).format(MM_DD_YYYY)
   })
  }

  onAddressChange(event: MatDatepickerInputEvent<Date>, field: string) {
    if(!event.value && field == TYPE_PICKUP) {
      this.filterForm.patchValue({
        pickUp: '',
        pickUpCity: '',
        pickUpState: ''
      });
    } else if(!event.value && field == TYPE_DROP) {
      this.filterForm.patchValue({
        dropOff: '',
        dropOffCity: '',
        dropOffState: ''
      });
    }
  }

}

<h2>Driver Responsibility</h2>
<ul>
  <li>
    All motor vehicle violations and accidents in company and personal vehicles, must be reported to management as soon
    after the incident as practical. Failure to do so may result in disciplinary action up to and including termination
    of employment.
  </li>
  <li>
    It is every driver's responsibility to drive defensively to avoid accidents, and safely maintain each vehicle under
    the driver's control. Defensive Driving is defined as "Driving to avoid accidents in spite of the incorrect actions
    of others, and the adverse conditions of weather, visibility, light, and traffic that the driver may encounter on
    the road". Failure to operate a vehicle safely may result in a suspension of driving duties or termination of
    employment.
  </li>
  <li>
    The Accident Review Committee will review each vehicle accident and a determination of preventability made. Drivers
    with unacceptable driving records will be subject to a progressive disciplinary procedure. Poor driving behavior can
    result in remedial training, days off without pay, reassignment to a non-driving job or termination of employment.
  </li>
</ul>
<h2>CELL PHONE USAGE POLICY</h2>
<p>
  Driving is a serious responsibility that demands and deserves your full attention. Driver distractions may occur
  anytime, anywhere. A distraction is anything that takes your attention away from driving, such as cell phone use.
</p>
<ul>
  <li>
    The use of COMPANY issued cell phones or radios should be used on an as needed basis, and only when it is safe to do
    so. Personal use of these units is prohibited.
  </li>
  <li>
    <strong
      >The use of PERSONAL cell phones while on duty is strictly prohibited while driving a company vehicle and/or any
      other safety sensitive position.</strong
    >
  </li>
  <li>
    <strong
      >If a driver is found to be using cell phone while driving or pulled by the authorities, driver will be
      responsible to pay all fines/violations/ticket and may be termination will be applied.</strong
    >
  </li>
</ul>
<div class="form-group">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 13 of 30</p>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { RegistrationService } from '../shared/registration.service';
import { catchError, map, tap } from 'rxjs/operators';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { SubSink } from 'subsink';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { ActivatedRoute, Router } from '@angular/router';
import { License } from 'src/app/shared/models/license.model';
import { of } from 'rxjs';

const ERROR_MESSAGE = 'There was an error, please try again later.';
const SUCCESS_MESSAGE = 'Your document was successfully uploaded';
const SOURCE_TEXT = 'self';
const LICENSE_DOCUMENT_TYPE = 'DriverLicense';
const PRCARD_DOCUMENT_TYPE = 'PRCard';
const TRAINING_CERTI_DOCUMENT = 'TrainingCertificate';
const PASSPORT_DOCUMENT_TYPE = 'Passport';
const CANADAIAN_VISA_DOCUMENT = 'CanadaVisa';
const US_VISA_DOCUMENT = 'USVisa';
const WORK_PERMIT_DOCUMENT = 'WorkPermit';
const FAST_CARD = 'FastCard';
const HEALTH_CARD = 'HealthCard';
const DOCUMENT_TYPES = [
  PRCARD_DOCUMENT_TYPE,
  // TRAINING_CERTI_DOCUMENT,
  PASSPORT_DOCUMENT_TYPE,
  CANADAIAN_VISA_DOCUMENT,
  US_VISA_DOCUMENT,
  WORK_PERMIT_DOCUMENT,
  FAST_CARD,
  HEALTH_CARD
];
const UPLOADED_STATUS = 'uploaded';
const PENDING_STATUS = 'pending';
const ROUTE_PROFILE_DOCUMENT = 'profile/document/';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';
const ROUTE_DOCUMENT_UPLOAD = 'document-upload/';

export interface driverDocument {
  generationDate: string;
  typeDisplayName: string;
  isPresent: boolean;
  type: string;
}
@Component({
  selector: 'app-additional-documents',
  templateUrl: './additional-documents.component.html',
  styleUrls: ['./additional-documents.component.scss']
})
export class AdditionalDocumentsComponent implements OnInit, OnDestroy {
  loading: boolean;
  file: any;
  errorMessage: string;
  successMessage: string;
  presentDate: Date = new Date();
  selectedDocumentType: string;
  passportDocument: driverDocument;
  PRCardDocument: driverDocument;
  TrainingCertiDocument: driverDocument;
  WorkPermit: driverDocument;
  CanadianVisa: driverDocument;
  USVisa: driverDocument;
  isUSDriver: boolean = false;

  private subs = new SubSink();
  fastCard: driverDocument;
  healthCard: { generationDate: string; typeDisplayName: string; type: string; isPresent: boolean; };
  constructor(
    private readonly location: Location,
    private readonly activedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly registrationService: RegistrationService,
    private readonly dateTimeService: DateTimeService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.loadDriverDocuments();
    this.getLicense();
  }
  backAction() {
    this.location.back();
  }
  uploadFile(type: string, isDocumentPresent: boolean) {
    this.selectedDocumentType = type;
    if (DOCUMENT_TYPES.includes(this.selectedDocumentType) && isDocumentPresent) {
      this.registrationService.navigateToDocumentType(this.selectedDocumentType, this.activedRoute);
    } else {
      this.router.navigate([ROUTE_DOCUMENT_UPLOAD + this.selectedDocumentType]);
    }
  }
  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
      this.onSubmit();
    };
  }
  onSubmit(): void {
    const model = this.prepareSaveModel();

    if (model === undefined) {
      return;
    }

    this.registrationService
      .saveDocument(model, this.file)
      .pipe(
        tap(() => {
          this.setSuccessMessage(SUCCESS_MESSAGE);
          this.setErrorMessage(undefined);
        }),
        catchError((error: string) => {
          this.setErrorMessage(ERROR_MESSAGE);
          this.setSuccessMessage(undefined);
          throw error;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.loadDriverDocuments();
      });
  }
  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }
  loadDriverDocuments(): void {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            notificationStateModel.notifications.forEach(notifincation => {
              if (
                notifincation.type == PASSPORT_DOCUMENT_TYPE &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.passportDocument = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == PRCARD_DOCUMENT_TYPE &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.PRCardDocument = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == TRAINING_CERTI_DOCUMENT &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.TrainingCertiDocument = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == CANADAIAN_VISA_DOCUMENT &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.CanadianVisa = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == US_VISA_DOCUMENT &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.USVisa = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == WORK_PERMIT_DOCUMENT &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.WorkPermit = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == FAST_CARD &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.fastCard = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
              if (
                notifincation.type == HEALTH_CARD &&
                (notifincation.status == UPLOADED_STATUS || notifincation.status == PENDING_STATUS)
              ) {
                this.healthCard = {
                  generationDate: notifincation.createdDate,
                  typeDisplayName: notifincation.name,
                  type: notifincation.type,
                  isPresent: true
                };
              }
            });
          }
        })
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private getLicense(): void {
    const license = this.registrationService.getLicenseStore();
    if (license?.issuingCountry === 'us') {
      this.isUSDriver = true;
    }
  }

  private prepareSaveModel(): DocumentRequest {
    const extension = this.file.name
      .split('.')
      .pop()
      .toLowerCase();

    const model = {
      extension: extension,
      source: SOURCE_TEXT,
      generationDate: this.dateTimeService.formatDateTime(this.presentDate.toUTCString(), ISO_8601),
      reportType: this.selectedDocumentType
    };

    return model as DocumentRequest;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, first } from 'rxjs/operators';
import { Action } from 'src/app/loads-shared/shipment-data/models/action';
import { Quote } from 'src/app/loads-shared/shipment-data/models/quote';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ShipmentChangeStatusActionSheetComponent } from '../shipment-change-status-action-sheet/shipment-change-status-action-sheet.component';
import { DEFAULT_ERROR_MESSAGE, STATUS_ASSIGNED, STATUS_CONFIRMATION_PENDING } from './constants';
import { ShipmentsConfirmDialogComponent } from './shipments-confirm-dialog/shipments-confirm-dialog.component';
import { ShipmentsDataService } from './shipments-data.service';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_SHIPMENTS, SHIPMENTS_ROUTE_DETAILS } from './routes';
import { ShipmentAddPhotoActionSheetComponent } from '../shipment-add-photo-action-sheet/shipment-add-photo-action-sheet.component';
import { ShipmentAddCommentActionSheetComponent } from '../shipment-add-comment-action-sheet/shipment-add-comment-action-sheet.component';

const dialogWidth = '400px';
@Injectable({
  providedIn: 'root'
})
export class ShipmentsActionsService {
  enableDriverConfirmation: any;
  constructor(
    private readonly dialog: MatDialog,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly bottomSheet: MatBottomSheet,
    @Inject('environmentData') private environment: any
  ) {
    this.enableDriverConfirmation = this.environment?.featureFlags?.enableDriverConfirmation;
  }

  private createAcceptDialogActions(): Action[] {
    const actions: Action[] = [];

    const NoAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false
    } as Action;
    actions.push(NoAction);

    const OkayAction = {
      name: 'Yes',
      action: (data: any) => {},
      disabled: false,
      color: 'primary'
    } as Action;
    actions.push(OkayAction);

    return actions;
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }

  confirmShipment(shipment: Shipment, driverId: string, callback?: Function): void {
    if (!shipment?.status) {
      return;
    }

    let driverQuote: Quote;
    if (shipment?.quotes?.length) {
      driverQuote = shipment.quotes.find((quote: Quote) => quote.actorID === driverId);
    }

    this.showConfirmShipmentConfirmation(shipment, driverQuote?.id, callback);
  }

  openChangeShipmentStatusBottomSheet(
    shipment: Shipment,
    includeNextSteps?: boolean,
    callback?: Function,
    isPodUploaded: boolean = true
  ): MatBottomSheetRef {
    return this.bottomSheet.open(ShipmentChangeStatusActionSheetComponent, {
      data: {
        shipment: shipment,
        callback: callback,
        nextSheetActions: includeNextSteps
          ? [ShipmentAddCommentActionSheetComponent, ShipmentAddPhotoActionSheetComponent]
          : [],
        isPodUploaded: isPodUploaded
      }
    });
  }

  openAddShipmentCommentBottomSheet(
    shipment: Shipment,
    includeNextSteps?: boolean,
    callback?: Function
  ): MatBottomSheetRef {
    return this.bottomSheet.open(ShipmentAddCommentActionSheetComponent, {
      data: {
        shipment: shipment,
        callback: callback,
        nextSheetActions: includeNextSteps ? [ShipmentAddPhotoActionSheetComponent] : []
      }
    });
  }

  openAddShipmentPhotoBottomSheet(shipment: Shipment, callback?: Function): MatBottomSheetRef {
    return this.bottomSheet.open(ShipmentAddPhotoActionSheetComponent, {
      data: { shipment: shipment, callback: callback }
    });
  }

  navigateToShipmentDetails(shipmentId: string, queryParams?: any): void {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${shipmentId}`;

    this.router.navigate([path], { queryParams: queryParams });
  }

  private showConfirmShipmentConfirmation(shipment: Shipment, quoteId: string, callback?: Function): void {
    const actions = this.createAcceptDialogActions();
    const dialogRef = this.dialog.open(ShipmentsConfirmDialogComponent, {
      width: dialogWidth,
      data: { title: 'Are you sure you want to confirm this shipment?', actions },
      disableClose: true
    });
    const confirmAction = actions[1];
    const cancelAction = actions[0];
    confirmAction.action = () => this.confirmQuote(dialogRef, confirmAction, cancelAction, shipment.id, quoteId, shipment);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  private confirmQuote(
    dialogRef: MatDialogRef<ShipmentsConfirmDialogComponent>,
    confirmAction: Action,
    cancelAction: Action,
    shipmentID: string,
    quoteId: string,
    shipment?: any
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    if (quoteId) {
      this.shipmentsDataService
        .confirmBid(quoteId)
        .pipe(
          first(),
          catchError((error: any) => {
            this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
            throw error;
          }),
          finalize(() => {
            dialogRef.close();
            confirmAction.disabled = false;
            cancelAction.disabled = false;
          })
        )
        .subscribe(() => {
          if(this.enableDriverConfirmation){
            if(shipment.status === STATUS_ASSIGNED || shipment.status === STATUS_CONFIRMATION_PENDING){
              let path = `/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`;
              this.router.navigate([path], { queryParams: { section: 'upcoming' } });
            } else {
              let path = `/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`;
             this.router.navigate([path], { queryParams: { section: 'ongoing' } });
            }
          } else {
            let path = `/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`;
            this.router.navigate([path], { queryParams: { section: 'upcoming' } });
          }      
        });
      return;
    }
  }
}

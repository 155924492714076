import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ShipmentsListComponent } from './shipments-list/shipments-list.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { ShipmentsRoutingModule } from './shipments-routing.module';
import { LoadsSharedModule } from '../loads-shared/loads-shared.module';
import { ShipmentsItemComponent } from './shipments-item/shipments-item.component';
import { ShipmentDetailComponent } from './shipment-detail/shipment-detail.component';
import { ShipmentSubDetailComponent } from './shipment-detail/shipment-sub-detail/shipment-sub-detail.component';
import { FilterShipmentsComponent } from './filter-shipments/filter-shipments.component';
import { ShipmentsActionSheetComponent } from './shipments-action-sheet/shipments-action-sheet.component';
import { ShipmentsConfirmDialogComponent } from './shared/shipments-confirm-dialog/shipments-confirm-dialog.component';
import { ShipmentTimelineComponent } from './shipment-detail/shipment-timeline/shipment-timeline.component';
import { ShipmentDocumentViewComponent } from './shared/shipment-document-view/shipment-document-view.component';
import { ShipmentDocumentFileComponent } from './shipment-detail/shipment-sub-documents/shipment-document-file/shipment-document-file.component';
import { ShipmentChangeStatusActionSheetComponent } from './shipment-change-status-action-sheet/shipment-change-status-action-sheet.component';
import { ShipmentAddCommentActionSheetComponent } from './shipment-add-comment-action-sheet/shipment-add-comment-action-sheet.component';
import { ShipmentAddPhotoActionSheetComponent } from './shipment-add-photo-action-sheet/shipment-add-photo-action-sheet.component';
import { PhotoPickerActionSheetComponent } from '../shared/components/photo-picker-action-sheet/photo-picker-action-sheet.component';
import { ShipmentSubDocumentsComponent } from './shipment-detail/shipment-sub-documents/shipment-sub-documents.component';
import { ShipmentsFeedbackDialogComponent } from './shipments-feedback-dialog/shipments-feedback-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShipmentsDashboardComponent } from './shipments-dashboard/shipments-dashboard.component';
import { ShipmentsDocumentComponent } from './shipments-document/shipments-document.component';
import { DocumentTileBoxComponent } from './shipments-document/document-tile-box/document-tile-box.component';
import { DocumentUploadComponent } from './shipments-document/document-upload/document-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoadingGifModule } from '../shared/loading-gif/loading-gif.module';
import { ShipmentsStopsComponent } from './shipments-stops/shipments-stops.component';
import { ShipmentsStopsActionSheetComponent } from './shipments-stops-action-sheet/shipments-stops-action-sheet.component';
import { ShipmentsHistoryComponent } from './shipments-history/shipments-history.component';

@NgModule({
  declarations: [
    ShipmentsListComponent,
    ShipmentsComponent,
    ShipmentsItemComponent,
    FilterShipmentsComponent,
    ShipmentsActionSheetComponent,
    ShipmentsConfirmDialogComponent,
    ShipmentDetailComponent,
    ShipmentSubDetailComponent,
    ShipmentTimelineComponent,
    ShipmentDocumentViewComponent,
    ShipmentSubDocumentsComponent,
    ShipmentDocumentFileComponent,
    ShipmentChangeStatusActionSheetComponent,
    ShipmentAddCommentActionSheetComponent,
    ShipmentAddPhotoActionSheetComponent,
    PhotoPickerActionSheetComponent,
    ShipmentsFeedbackDialogComponent,
    ShipmentsDashboardComponent,
    ShipmentsDocumentComponent,
    DocumentTileBoxComponent,
    DocumentUploadComponent,
    ShipmentsStopsComponent,
    ShipmentsStopsActionSheetComponent,
    ShipmentsHistoryComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ShipmentsRoutingModule,
    LoadsSharedModule,
    NgxSkeletonLoaderModule.forRoot(),
    MatProgressSpinnerModule,
    ImageCropperModule,
    LoadingGifModule
  ]
})
export class ShipmentsModule {}

<h2>Responsibility & Accountability for Health & Safety</h2>
<p>
  Workers employed by
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  are responsible for:
</p>
<ul>
  <li>
    Reporting for work "fit for duty" and/or notifying their direct supervisor of any mental or physical conditions that
    may impact the performance of their assigned work tasks.
  </li>
  <li>
    Adhering to regulations, guidelines and safety standards as required by government regulatory agencies and those
    communicated by management and supervisors (in particular, applying all hazard controls as specified by applicable
    hazard assessments).
  </li>
  <li>
    Following all appropriate codes of Practice, Safe Operating Procedures, associated Safe Work Practices and Safety
    Rules contained in the Company's Health and Safety Manual.
  </li>
  <li>
    Reporting any hazardous or unsafe working conditions to their immediate supervisor and, if possible, correcting the
    unsafe condition.
  </li>
  <li>
    Observing activities of fellow employees and contractors to ensure their safety and the safety of those around them
    and correcting unsafe acts in a proactive, positive manner to prevent an incident from occurring.
  </li>
  <li>
    Refusing to perform work that:
    <ul>
      <li>
        He or she believes presents an imminent danger to the health or safety of themselves or their fellow workers,
        and
      </li>
      <li>They are not competent to perform.</li>
    </ul>
  </li>
  <li>Reporting all incidents, injuries, and illness to their supervisor.</li>
  <li>Participating in, and using, all training offered by the Company, and</li>
  <li>Using the required personal protective equipment (PPE).</li>
</ul>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 18 of 30</p>

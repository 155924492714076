<h2>Confidentiality Policy</h2>
<p>
  In consideration of the foregoing, I
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  hereby agree to the following terms of "Confidentiality" with continuing my employment with
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  .
</p>
<div class="pl-1">
  <p>
    That I shall not use or disclose, without consent of
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    any trade secrets, confidential proprietary information of or concerning
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    's affiliates, clients, suppliers or personnel.
  </p>
  <p>
    That I shall refrain from any conduct or statements inimical to the best interests of
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    and particularly, shall make no adverse or unfavorable public statements concerning
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    or my relationship with it.
  </p>
  <p>
    That I shall return all property of
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    and all records and copies of records of the Company dealing with the operations and activities of
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required /> </mat-form-field
    >, affiliates, clients, suppliers or personnel.
  </p>
  <p>
    I understand that all information and happenings within
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
    are to be kept strictly confidential and that any breach of the above will be subject to disciplinary action up to
    and including termination of employment.
  </p>
  <p>
    I understand that all information is private and is legally protected by law, as well as Company Policy of
    <mat-form-field appearance="outline" class="inline-field">
      <mat-label>Carrier Company Name</mat-label>
      <input matInput [formControl]="carrierCompanyNameCtrl" required />
    </mat-form-field>
  </p>
</div>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 22 of 30</p>

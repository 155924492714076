import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFFonts } from '../../pdf-interfaces';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page24-entry-numbers-seal-policy',
  templateUrl: './page24-entry-numbers-seal-policy.component.html',
  styleUrls: ['../shared.scss', './page24-entry-numbers-seal-policy.component.scss']
})
export class Page24EntryNumbersSealPolicyComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() carrierCompanyNameCtrl: FormControl;

  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    if (this.signatureCanvas) {
      this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)'
      });
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }
  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { pageWidth, pageHeight, margin } = position;

    // Create first page
    let currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
    const x = margin;
    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
    }
    let currentY = position.headerOffset || pageHeight - margin;

    const maxWidth = pageWidth - 2 * margin;

    // Draw heading
    const headingText = 'Entry Numbers/Seal Policy';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Draw driver's name line
    currentPage.drawText("Driver's Name:", {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of the label
    const labelWidth = fonts.regular.widthOfTextAtSize("Driver's Name: ", 12);

    // Add driver name
    const driverName = formValues.driverName || '';
    currentPage.drawText(driverName, {
      x: x + labelWidth,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 30;

    // Draw first paragraph with bold "WILL NOT"
    const paragraph1Part1 = 'By signing below, I hereby acknowledge that I ';
    currentPage.drawText(paragraph1Part1, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    // Calculate width of paragraph1Part1
    const paragraph1Part1Width = fonts.regular.widthOfTextAtSize(paragraph1Part1, 12);

    // Add "WILL NOT" in bold
    const willNotText = 'WILL NOT';
    currentPage.drawText(willNotText, {
      x: x + paragraph1Part1Width,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Move to next line to start the rest of the paragraph to avoid right-aligned text
    currentY -= 20;

    // Add the rest of paragraph on a new line to avoid alignment issues
    const paragraph1Part2 =
      'arrive at any border without an entry number or multiple entry numbers. I am responsible to make sure that prior to arriving at a border I have an entry number or multiple entry numbers either from Dispatch or I have looked them up myself. An entry number or multiple entry numbers are needed for all shipments.';

    currentY = this.drawWrappedText(currentPage, paragraph1Part2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth
    });

    currentY -= 20;

    // Draw second paragraph
    const paragraph2 = 'Remember if you have multiple shipments, you will need multiple entry numbers.';
    currentY = this.drawWrappedText(currentPage, paragraph2, x, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth
    });

    currentY -= 20;

    // Draw third paragraph with company name
    const companyName = formValues.carrierCompanyName || '';
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Calculate width of company name
    const companyNameWidth = fonts.bold.widthOfTextAtSize(companyName, 12);

    // Add the rest of paragraph3
    const paragraph3 =
      ' and I are responsible for getting an entry number or entry numbers prior to arrival at any border.';
    currentY = this.drawWrappedText(currentPage, paragraph3, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });

    currentY -= 20;

    // Add "Seals - Policy" in bold
    const sealsPolicy = 'Seals - Policy';
    currentPage.drawText(sealsPolicy, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    currentY -= 20;

    // Draw bullet points for seal policy
    const bulletPoints = [
      {
        text: 'All trailers in transit to the US or returning to Canada MUST have a ',
        companyName: true,
        companyText: ' C-TPAT certified security seal securing the trailer'
      },
      {
        text: "Seals are to be applied to the right trailer door prior to leaving the customer's facility",
        companyName: false
      },
      {
        text: 'If the customer provides a seal, it must be used, as well as a ',
        companyName: true,
        companyText: ' seal'
      },
      {
        text: 'The Driver is not to leave the shipper until he communicates with Dispatch the seal number',
        companyName: false
      },
      {
        text: 'All loaded trailers dropped at any of our terminals must be sealed',
        companyName: false
      },
      {
        text:
          'Any time that a loaded trailer is left unattended (regardless of the amount of time involved) the door seal(s) must be checked for tampering prior to departing',
        companyName: false
      },
      {
        text:
          'If a seal has to be broken for any reason the driver MUST contact Operations first. Never break a seal without Operations instruction.',
        companyName: false
      },
      {
        text: 'Seals that are found tampered with must be reported immediately to Operations and take a picture',
        companyName: false
      },
      {
        text: 'Seals that have been removed must be destroyed so they are not able to be reused.',
        companyName: false
      }
    ];

    // Draw each bullet point
    for (const bulletPoint of bulletPoints) {
      // Check if we need a new page
      if (currentY < margin + 100) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
        currentY = pageHeight - margin;
      }

      // Draw bullet
      currentPage.drawText('•', {
        x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // For bullet points with company name
      if (bulletPoint.companyName) {
        // Draw the first part of text
        currentPage.drawText(bulletPoint.text, {
          x: x + 15,
          y: currentY,
          size: 12,
          font: fonts.regular
        });

        // Calculate width of text
        const bulletTextWidth = fonts.regular.widthOfTextAtSize(bulletPoint.text, 12);

        // Add company name
        currentPage.drawText(companyName, {
          x: x + 15 + bulletTextWidth,
          y: currentY,
          size: 12,
          font: fonts.bold
        });

        // Add the remainder of text if any
        if (bulletPoint.companyText) {
          currentPage.drawText(bulletPoint.companyText, {
            x: x + 15 + bulletTextWidth + companyNameWidth,
            y: currentY,
            size: 12,
            font: fonts.regular
          });
        }

        currentY -= 20;
      } else {
        // Draw regular bullet point text
        currentY = this.drawWrappedText(currentPage, bulletPoint.text, x + 15, currentY, {
          font: fonts.regular,
          fontSize: 12,
          lineHeight: 16,
          maxWidth: maxWidth - 15
        });

        currentY -= 15;
      }
    }

    currentY -= 10;

    // Draw final paragraph with company name
    currentPage.drawText(companyName, {
      x,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Add the remainder of final paragraph
    const finalParagraph =
      ' will establish remedial training, corrective action, and discipline procedures for drivers who arrive at any ' +
      'border without an entry number or multiple entry numbers/unsealed trailers. Drivers will be responsible for cost ' +
      'of the fines for crossing the border without an entry number or multiple entry numbers/unsealed trailers. Fines ' +
      'start at $5000.00';

    currentY = this.drawWrappedText(currentPage, finalParagraph, x + companyNameWidth, currentY, {
      font: fonts.regular,
      fontSize: 12,
      lineHeight: 16,
      maxWidth: maxWidth - companyNameWidth
    });

    currentY -= 30;

    // Check if we need a new page for signature
    if (currentY < margin + 120) {
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw driver name field
    currentPage.drawText('Driver Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + 5,
      y: currentY - 15,
      size: 12,
      font: fonts.bold
    });

    currentPage.drawLine({
      start: { x, y: currentY - 18 },
      end: { x: x + 150, y: currentY - 18 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });
    currentY -= 40;

    const signature = formValues.signatures?.get('driverSignaturePage24');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    // Return result with newPage flag since we created a new page
    return {
      height: 0,
      newPage: true,
      position: {
        ...position,
        y: currentY - 50
      }
    };
  }

  // Helper method to draw wrapped text
  private drawWrappedText(
    page: any,
    text: string,
    x: number,
    y: number,
    options: {
      font?: any;
      fontSize?: number;
      lineHeight?: number;
      maxWidth?: number;
    }
  ): number {
    const { font, fontSize = 12, lineHeight = 16, maxWidth = 500 } = options;
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (const word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (testWidth > maxWidth) {
        page.drawText(line, { x, y: currentY, size: fontSize, font });
        currentY -= lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line, { x, y: currentY, size: fontSize, font });
      currentY -= lineHeight;
    }

    return currentY;
  }
}

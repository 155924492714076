import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivateChild } from '@angular/router';
import { FrameComponent } from './frame/frame/frame.component';
import {
  ROUTE_ROOT,
  ROUTE_NOTIFICATIONS,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_DRIVER_HISTORY,
  ROUTE_LOADS,
  ROUTE_UNSUBSCRIBE_EMAIL,
  ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL,
  ROUTE_FAILED_UNSUBSCRIBE_EMAIL,
  ROUTE_JOBS,
  ROUTE_REFER_A_FRIEND,
  ROUTE_ADD_TO_HOME_SCREEN,
  ROUTE_TRIPS,
  ROUTE_SHIPMENTS,
  ROUTE_DRIVER_CONSENT
} from './shared/routes';
import { NotificationsComponent } from './notifications/notifications.module';
import { AccountSettingsComponent } from './account-settings/account-settings.module';
import { profileRoutes, ROUTE_PROFILE } from './profile/profile-routing.module';
import { ProfileOutletComponent } from './profile/profile-outlet/profile-outlet.component';
import { DriverGuardService } from './core/guards/driver-guard.service';
import { ConfirmUnsubscribeEmailComponent } from './unsubscribe-email/feature-unsubscribe-email/confirm-unsubscribe-email/confirm-unsubscribe-email.component';
import { SuccessfulUnsubscribeEmailComponent } from './unsubscribe-email/feature-unsubscribe-email/sucessful-unsubscribe-email/successful-unsubscribe-email.component';
import { FailureUnsubscribeEmailComponent } from './unsubscribe-email/feature-unsubscribe-email/failure-unsubscribe-email/failure-unsubscribe-email.component';
import { ReferFriendComponent } from './refer-a-friend/refer-a-friend.module';
import { dqfRouting, ROUTE_DQF } from './driver-qualification-files/driverQualification-routing.module';
import { DqfOutletComponent } from './driver-qualification-files/dqf-outlet/dqf-outlet.component';
import { LoginErrorComponent } from './authentication/login-error/login-error.component';
import { ROUTE_LOGIN_ERROR } from './authentication/shared/routes';
import { ProfileAccessConsentComponent } from './profile/profile-access-consent/profile-access-consent';
import { SHIPMENTS_ROUTE_MY_SHIPMENTS } from './shipments/shared/routes';
import { ShipmentsListComponent } from './shipments/shipments-list/shipments-list.component';

const routes: Routes = [
  {
    path: ROUTE_ROOT,
    component: FrameComponent,
    children: [
      {
        path: '',
        component: ProfileOutletComponent,
        children: [...profileRoutes],
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_PROFILE,
        component: ProfileOutletComponent,
        children: [...profileRoutes],
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_NOTIFICATIONS,
        component: NotificationsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_ACCOUNT_SETTINGS,
        component: AccountSettingsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_REFER_A_FRIEND,
        component: ReferFriendComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_DQF,
        component: DqfOutletComponent,
        children: [...dqfRouting],
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_ADD_TO_HOME_SCREEN,
        loadChildren: () => import('./add-to-home-screen/add-to-home-screen.module').then(m => m.AddToHomeScreenModule),
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_DRIVER_HISTORY,
        loadChildren: () => import('./driver-history/driver-history.module').then(m => m.DriverHistoryModule),
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_LOADS,
        loadChildren: () => import('./loads/loads.module').then(m => m.LoadsModule),
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_JOBS,
        loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_TRIPS,
        loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule),
        canActivate: [DriverGuardService]
      },
      {
        path: ROUTE_SHIPMENTS,
        loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule),
        canActivate: [DriverGuardService]
      },
      {
        path: SHIPMENTS_ROUTE_MY_SHIPMENTS,
        component: ShipmentsListComponent,
        canActivate: [DriverGuardService]
      }
    ],
    canActivate: [DriverGuardService]
  },
  {
    path: ROUTE_UNSUBSCRIBE_EMAIL,
    component: ConfirmUnsubscribeEmailComponent
  },
  {
    path: ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL,
    component: SuccessfulUnsubscribeEmailComponent
  },
  {
    path: ROUTE_FAILED_UNSUBSCRIBE_EMAIL,
    component: FailureUnsubscribeEmailComponent
  },
  { path: ROUTE_LOGIN_ERROR, component: LoginErrorComponent },
  { path: ROUTE_DRIVER_CONSENT, component: ProfileAccessConsentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

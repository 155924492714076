import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '../shared/registration.service';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

const ERROR_MESSAGE = 'There was an error, please contact us for help';
const SUCCESS_MESSAGE = 'Your document was successfully uploaded';
const SOURCE_TEXT = 'self';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';
const LICENSE_DOCUMENT_TYPE = 'DriverLicense';
const PRCARD_DOCUMENT_TYPE = 'PRCard';
const PASSPORT_DOCUMENT_TYPE = 'Passport';
const CANADAIAN_VISA_DOCUMENT = 'CanadaVisa';
const US_VISA_DOCUMENT = 'USVisa';
const WORK_PERMIT_DOCUMENT = 'WorkPermit';
const FAST_CARD = 'FastCard';
const HEALTH_CARD = 'HealthCard';
const DOCUMENT_TYPES = [LICENSE_DOCUMENT_TYPE, PRCARD_DOCUMENT_TYPE, PASSPORT_DOCUMENT_TYPE, CANADAIAN_VISA_DOCUMENT, US_VISA_DOCUMENT, WORK_PERMIT_DOCUMENT, FAST_CARD, HEALTH_CARD];
const ISSUE_DATE_REQUIRED = [FAST_CARD];
const DOC_TYPE_HEALTH_CARD = [HEALTH_CARD];

export interface driverDocument {
  generationDate: string;
  validToDate: string;
  typeDisplayName: string;
  isPresent: boolean;
  type: string;
}
@Component({
  selector: 'app-additional-document-upload',
  templateUrl: './additional-document-upload.component.html',
  styleUrls: ['./additional-document-upload.component.scss']
})
export class AdditionalDocumentUploadComponent implements OnInit {
  loading: boolean;
  form: FormGroup;
  formUploadFiles = { form: null };
  fileInfo: { id: string; fileName: string; generationDate: string; validToDate: string };
  documentType: string;
  isContentReady: boolean = false;
  file: any;
  title: string;
  errorMessage: string;
  successMessage: string;
  

  private subs = new SubSink();
  constructor(
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dateTimeService: DateTimeService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      generationDate: ['', [Validators.required]],  
      file: ['', [Validators.required]],
    });
    if (DOCUMENT_TYPES.includes(this.activatedRoute.snapshot.params.type)) {
      this.form.addControl('validToDate', new FormControl('', [Validators.required]));
    }
    if (ISSUE_DATE_REQUIRED.includes(this.activatedRoute.snapshot.params.type)) {
      this.form.addControl('issueDate', new FormControl('', [Validators.required]));
      this.form.addControl('fastCardNumber', new FormControl('', [Validators.required, Validators.pattern('^[0-9A-Za-z-]{1,40}$')]));
    }
    if (DOC_TYPE_HEALTH_CARD.includes(this.activatedRoute.snapshot.params.type)) {
      this.form.addControl('issueDate', new FormControl('', [Validators.required]));
      this.form.addControl('healthCardNumber', new FormControl('', [Validators.required, Validators.pattern('^[0-9A-Za-z-]{1,40}$')]));
    }
    
    this.formUploadFiles = {
      form: this.form
    };
    this.title = this.activatedRoute.snapshot.params.type.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
  }

  backAction(): void {
    this.location.back();
  }

  uploadDocument(): void {
    this.isContentReady = false;
    const model = this.prepareSaveModel(this.form.value);
    if (model === undefined) {
      return;
    }
    this.registrationService
      .saveDocument(model, this.form.value.file)
      .pipe(
        tap(response => {
          this.setSuccessMessage(SUCCESS_MESSAGE);
          this.toastService.showSuccess(this.successMessage);
        }),
        catchError((error: string) => {
          this.setErrorMessage(ERROR_MESSAGE);
          this.toastService.showError(this.errorMessage);
          throw error;
        }),
        finalize(() => {
          this.loading = false;
          this.backAction();
        })
      )
      .subscribe(() => {});
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private prepareSaveModel(formValue: any): DocumentRequest {
    const extension = formValue.file.name
      .split('.')
      .pop()
      .toLowerCase();
    const formModel = formValue;

    if (['pdf', 'jpeg', 'jpg', 'png'].find(element => element == extension) == undefined) {
      return undefined;
    }

    let model: any = {
      source: SOURCE_TEXT,
      extension: extension,
      generationDate: this.dateTimeService.formatDateTime(formModel.generationDate, ISO_8601),   
      reportType: this.activatedRoute.snapshot.params.type
    };
    if (DOCUMENT_TYPES.includes(this.activatedRoute.snapshot.params.type)) {
      model['validToDate'] = this.dateTimeService.formatDateTime(formModel.validToDate, ISO_8601);
    }
    if (ISSUE_DATE_REQUIRED.includes(this.activatedRoute.snapshot.params.type)) {
      model['issueDate'] = this.dateTimeService.formatDateTime(formModel.issueDate, ISO_8601);
      model['fastCardNumber'] = formModel.fastCardNumber;
    }
    if (DOC_TYPE_HEALTH_CARD.includes(this.activatedRoute.snapshot.params.type)) {
      model['issueDate'] = this.dateTimeService.formatDateTime(formModel.issueDate, ISO_8601);
      model['healthCardNumber'] = formModel.healthCardNumber;
    }
    if (this.activatedRoute.snapshot.params.type === 'FastCard' || this.activatedRoute.snapshot.params.type === 'HealthCard') {
      delete model.generationDate;
    }
    return model as DocumentRequest;
  }
}

<h2 id="driver-requirement">Driver Requirements Rules</h2>
<p>
  In order to ensure safe operation of the company's fleet vehicles, all drivers must be aware of and comply with all
  regulations governing their conduct.
</p>
<div class="checkbox-table">
  <div class="checkbox-row"><span>LICENSING</span></div>
  <div class="checkbox-row">
    <span>I know that I must have a valid commercial driver's license.</span>
    <div class="canvas-outline">
      <canvas #licensing1Canvas width="360" height="100" [ngClass]="{ error: licensing1Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('licensing1')">Clear</button>
      <button type="button" mat-raised-button color="accent" (click)="insertInitialsToAll()">
        Insert Everywhere
      </button>
    </div>
  </div>
  <div class="checkbox-row">
    <span>I agree to report all traffic violations to my employer in writing.</span>
    <div class="canvas-outline">
      <canvas #licensing2Canvas width="360" height="100" [ngClass]="{ error: licensing2Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('licensing2')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row">
    <span>I understand that I must not operate a vehicle while under the influence of drugs or alcohol.</span>
    <div class="canvas-outline">
      <canvas #licensing3Canvas width="360" height="100" [ngClass]="{ error: licensing3Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('licensing3')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row"><span>HOURS OF WORK</span></div>
  <div class="checkbox-row">
    <span>I have been informed of and understand the hours of work regulations.</span>
    <div class="canvas-outline">
      <canvas #hoursOfWork1Canvas width="360" height="100" [ngClass]="{ error: hoursOfWork1Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('hoursOfWork1')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row">
    <span>I am aware that I must arrange my work schedule to comply with these regulations.</span>
    <div class="canvas-outline">
      <canvas #hoursOfWork2Canvas width="360" height="100" [ngClass]="{ error: hoursOfWork2Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('hoursOfWork2')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row">
    <span>I agree to submit a record of all on-duty hours accumulated while working for other operators.</span>
    <div class="canvas-outline">
      <canvas #hoursOfWork3Canvas width="360" height="100" [ngClass]="{ error: hoursOfWork3Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('hoursOfWork3')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row"><span>PRE-TRIP INSPECTIONS</span></div>
  <div class="checkbox-row">
    <span>I am aware of the pre-trip inspection requirements and understand them.</span>
    <div class="canvas-outline">
      <canvas
        #preTripInspection1Canvas
        width="360"
        height="100"
        [ngClass]="{ error: preTripInspection1Pad?.isEmpty() }"
      ></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('preTripInspection1')">Clear</button>
    </div>
  </div>
  <div class="checkbox-row"><span>LOAD SECURITY</span></div>
  <div class="checkbox-row">
    <span
      >I have been informed of and understand the load security regulations. (i.e. Ensure that the load is tarped as
      required).</span
    >
    <div class="canvas-outline">
      <canvas #loadSecurity1Canvas width="360" height="100" [ngClass]="{ error: loadSecurity1Pad?.isEmpty() }"></canvas>
      <button mat-raised-button type="button" (click)="clearSignature('loadSecurity1')">Clear</button>
    </div>
  </div>
</div>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 12 of 30</p>

export const environment = {
  // apiUrl: 'http://fleetoperate.mockable.io',
  webcamDefaultWidth: '360',
  webcamDefaultHeight: '640',
  errorMessage: 'There was an error. Please try again.',
  apiUrl: '//dev-api.fleetoperate.com',
  //ribbonText: 'Trucking - Dev',
  ribbonColour: 'orange',
  recaptchaSiteKey: '6Ld-rD8eAAAAAOXDdpb9Rk-sXhtypMr-rK-xSH7Z',
  googleTagManagerId: 'GTM-MZRS467',
  xsrfCookieName: 'XSRF-TOKEN-DEV',
  xsrfHeadername: 'X-XSRF-TOKEN-DEV',
  webQrCode: 'assets/images/DevQR.png',
  iOSQrCode: 'assets/images/iOSAppQR.png',
  appStoreIcon: 'assets/images/Download-on-appstore-icon.svg',
  helloSignClientId: 'd414bdfbab9f2ee16397aed122f4b25c',
  sentryDSNKey: 'https://229205da3f684831ad228463a99e85b0@o4504934071926784.ingest.sentry.io/4504961783365632',
  driverUrl: 'https://dev-driver.fleetoperate.com/#/',
  featureFlags: {
    production: false,
    isDQFEnable: true,
    isCERTNEnableForPoliceClearance: true,
    isCERTNEnableForAbstract: false,
    isShipmentEnable: true,
    enableSentry: false,
    enableSearchByIDonShipments: true,
    showTrips: false,
    showDriverDashboard: false,
    enableDQFDocImageCropFeature: true,
    enableLicenseScan: true,
    displayStopsFeatureButton: true,
    enableReportIncident: true,
    enableDriverConfirmation: true,
  }
};
//added xsrf token name

<h2>Hours of Service</h2>
<p>
  <mat-form-field appearance="outline" class="inline-field">
    <mat-label>Carrier Company Name</mat-label>
    <input matInput [formControl]="carrierCompanyNameCtrl" required />
  </mat-form-field>
  is committed to following the Department of Transportation (DOT) hours of service regulations. DOT regulations require
  all motor carriers and drivers to follow the hours-of-service requirements. Our hours-of-service procedures will help
  avoid DOT penalties and reduce driver fatigue. Hours of Service Procedures requires that all new drivers be trained in
  the hours-of-service regulations as part of the company's driver orientation program. In addition, all current drivers
  are required to be retrained in the hours-of-service regulations annually. Driver supervisors are responsible to
  ensure all current drivers are recertified annually in hours-of-service regulations training. All training is to be
  documented and put in the driver's file. Drivers will be notified in advance of scheduled refresher sessions.
</p>
<p>Drivers will receive warnings if any of the following violations are found:</p>
<ul>
  <li>Missing information (trailer number, driver name, truck number)</li>
  <li>Not showing 15-minute pre-trip.</li>
  <li>Not showing 10 hours off-duty</li>
  <li>Showing sleeper berth when you don't have a sleeper</li>
  <li>Not showing a safety check within the first 100 miles or 2 hours of driving</li>
  <li>Not showing BOL number or shipper and commodity on your logs</li>
  <li>
    Supporting document dates do not match log dates and times of Shipment paperwork (bills of lading, shipment
    invoices, delivery receipts, etc.) Dispatch and trip records, or equivalent documents o Expense receipts related to
    any on-duty non-driving time (fuel receipts, lumper receipts, etc.)
  </li>
</ul>
<div class="form-group">
  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="date" [formControl]="dateOfApplicationCtrl" required />
  </mat-form-field>
</div>
<p class="page-number">Page 15 of 30</p>

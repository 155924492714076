import { Routes } from '@angular/router';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import { DqfListComponent } from './dqf-list/dqf-list.component';
import { DqfDocumentUploadComponent } from './dqf-document-upload/dqf-document-upload.component';
import {
  ROUTE_DQF_DOCUMENT_UPLOAD,
  ROUTE_DQF_DRIVING_EXPERIENCE,
  ROUTE_DQF_APPLICATION_EMPLOYMENT,
  ROUTE_DQF_ABSTRACT,
  ROUTE_PERSONAL_INFO,
  ROUTE_PROFILE_IMAGE,
  ROUTE_LICENSE,
  ROUTE_RESIDENCE_HISTORY,
  ROUTE_EMPLOYMENT_HISTORY,
  ROUTE_ACCIDENT_RECORDS,
  ROUTE_TRAFFIC_CONVICTIONS,
  ROUTE_ON_DUTY_HOURS,
  ROUTE_DQF_REVIEW,
  ROUTE_DQF_DOCUMENT_VIEW,
  ROUTE_DQF_DECLARE,
  ROUTE_DQF_UPLOADS,
  ROUTE_DQF_POLICE_CERTIFICATE,
  ROUTE_DQF_PRE_EMPLOYMENT_CERTIFICATE,
  ROUTE_DQF_HOUSE_CLEAR_REPORT,
  ROUTE_DQF_SIGN_DECLARATION,
  ROUTE_DQF_BIRTH_LOCATION,
  ROUTE_DQF_CRIMINAL_CONVICTION,
  ROUTE_DQF_BACKGROUND_CHECK_PROCESSING,
  ROUTE_DQF_ONTARIO_CVOR_ABSTRACT,
  ROUTE_EMPLOYED_FORM,
  ROUTE_UNEMPLOYED_FORM,
  ROUTE_ADD_RESIDENCE_HISTORY,
  ROUTE_REPORT_INCIDENT,
  ROUTE_DQF_DECLARATION_SIGN
} from 'src/app/shared/routes';
import { DrivingExperienceComponent } from '../registration/driving-experience/driving-experience.component';
import { DqfApplicationEmployementComponent } from './dqf-application-employement/dqf-application-employement.component';
import { DqfAbstractComponent } from './dqf-abstract/dqf-abstract.component';
import { PersonalInformationComponent } from '../registration/personal-information/personal-information.component';
import { ProfilePictureComponent } from '../registration/profile-picture/profile-picture.component';
import { ProfileLicenseInformationComponent } from '../profile/profile-license-information/profile-license-information.component';
import { ResidenceHistoryComponent } from '../registration/residence-history/residence-history.component';
import { EmploymentHistoryComponent } from '../registration/employment-history/employment-history.component';
import { AccidentRecordsComponent } from '../registration/accident-records/accident-records.component';
import { TrafficConvictionsComponent } from '../registration/traffic-convictions/traffic-convictions.component';
import { OnDutyHourComponent } from '../registration/on-duty-hour/on-duty-hour.component';
import { DqfReviewComponent } from './dqf-review/dqf-review.component';
import { DqfViewDocumentComponent } from './dqf-view-document/dqf-view-document.component';
import { DqfDeclarationComponent } from './dqf-declaration/dqf-declaration.component';
import { DqfUploadComponent } from './dqf-upload/dqf-upload.component';
import { DqfPoliceCertificateComponent } from './dqf-police-certificate/dqf-police-certificate.component';
import { DqfPreEmploymentComponent } from './dqf-pre-employment/dqf-pre-employment.component';
import { DqfClearHouseComponent } from './dqf-clear-house/dqf-clear-house.component';
import { DqfSignDeclarationComponent } from './dqf-sign-declaration/dqf-sign-declaration.component';
import { DqfBirthLocationComponent } from './dqf-birth-location/dqf-birth-location.component';
import { DqfCriminalConvictionsComponent } from './dqf-criminal-convictions/dqf-criminal-convictions.component';
import { DqfProcessingBackgroundRequestComponent } from './dqf-processing-background-request/dqf-processing-background-request.component';
import { DqfOntarioCvorAbstractComponent } from './dqf-ontario-cvor-abstract/dqf-ontario-cvor-abstract.component';
import { EmploymentHistoryFormEmployedComponent } from '../registration/employment-history/employment-history-form-employed/employment-history-form-employed.component';
import { EmploymentHistoryFormUnemployedComponent } from '../registration/employment-history/employment-history-form-unemployed/employment-history-form-unemployed.component';
import { ResidenceHistoryFormComponent } from '../registration/residence-history/residence-history-form/residence-history-form.component';
import { ReportIncidentComponent } from './dqf-review/report-incident/report-incident.component';

import { DeclarationFormComponent } from './native-sign-declaration/declaration-form/declaration-form.component';
export const dqfRouting: Routes = [
  { path: '', component: DqfListComponent, canActivate: [DriverGuardService] },
  {
    path: `${ROUTE_DQF_DECLARATION_SIGN}`,
    component: DeclarationFormComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_DOCUMENT_UPLOAD}/:id`,
    component: DqfDocumentUploadComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_DRIVING_EXPERIENCE}`,
    component: DrivingExperienceComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_APPLICATION_EMPLOYMENT}`,
    component: DqfApplicationEmployementComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_ABSTRACT}`,
    component: DqfAbstractComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_PERSONAL_INFO}`,
    component: PersonalInformationComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_PROFILE_IMAGE}`,
    component: ProfilePictureComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_LICENSE}`,
    component: ProfileLicenseInformationComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_RESIDENCE_HISTORY}`,
    canActivate: [DriverGuardService],
    children: [
      { path: '', component: ResidenceHistoryComponent, canActivate: [DriverGuardService] },
      {
        path: `${ROUTE_ADD_RESIDENCE_HISTORY}`,
        component: ResidenceHistoryFormComponent,
        canActivate: [DriverGuardService]
      }
    ]
  },
  {
    path: `${ROUTE_EMPLOYMENT_HISTORY}`,
    canActivate: [DriverGuardService],
    children: [
      { path: '', component: EmploymentHistoryComponent, canActivate: [DriverGuardService] },
      {
        path: `${ROUTE_EMPLOYED_FORM}`,
        component: EmploymentHistoryFormEmployedComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${ROUTE_EMPLOYED_FORM}/:id`,
        component: EmploymentHistoryFormEmployedComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${ROUTE_UNEMPLOYED_FORM}`,
        component: EmploymentHistoryFormUnemployedComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${ROUTE_UNEMPLOYED_FORM}/:id`,
        component: EmploymentHistoryFormUnemployedComponent,
        canActivate: [DriverGuardService]
      }
    ]
  },
  {
    path: `${ROUTE_TRAFFIC_CONVICTIONS}`,
    component: TrafficConvictionsComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_ACCIDENT_RECORDS}`,
    component: AccidentRecordsComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_ON_DUTY_HOURS}`,
    component: OnDutyHourComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_REVIEW}/:id`,
    component: DqfReviewComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_DOCUMENT_VIEW}/:document-id`,
    component: DqfViewDocumentComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_DECLARE}/:id`,
    component: DqfDeclarationComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_UPLOADS}`,
    component: DqfUploadComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_POLICE_CERTIFICATE}`,
    component: DqfPoliceCertificateComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_PRE_EMPLOYMENT_CERTIFICATE}`,
    component: DqfPreEmploymentComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_HOUSE_CLEAR_REPORT}`,
    component: DqfClearHouseComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_SIGN_DECLARATION}`,
    component: DqfSignDeclarationComponent,
    canActivate: [DriverGuardService]
  },

  {
    path: `${ROUTE_DQF_BIRTH_LOCATION}`,
    component: DqfBirthLocationComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_CRIMINAL_CONVICTION}`,
    component: DqfCriminalConvictionsComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_BACKGROUND_CHECK_PROCESSING}`,
    component: DqfProcessingBackgroundRequestComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_DQF_ONTARIO_CVOR_ABSTRACT}`,
    component: DqfOntarioCvorAbstractComponent,
    canActivate: [DriverGuardService]
  },
  {
    path: `${ROUTE_REPORT_INCIDENT}`,
    component: ReportIncidentComponent,
    canActivate: [DriverGuardService]
  }
];
export { ROUTE_DQF } from '../shared/routes';

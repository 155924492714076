import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFHelpers, PDFFonts } from '../../pdf-interfaces';
import { FormControl } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { rgb } from 'pdf-lib';

@Component({
  selector: 'app-page2-application-information',
  templateUrl: './page2-application-information.component.html',
  styleUrls: ['../shared.scss', './page2-application-information.component.scss']
})
export class Page2ApplicationInformationComponent implements OnInit, PDFCapableComponent, AfterViewInit {
  @Input() driverNameCtrl: FormControl;
  @Input() dateOfApplicationCtrl: FormControl;
  @Input() dateEmployedCtrl: FormControl;
  @Input() reasonRejectionCtrl: FormControl;
  @Input() dateTerminationCtrl: FormControl;
  @Input() reasonTerminationCtrl: FormControl;
  @Input() safetyOfficerNameCtrl: FormControl;
  @Input() safetyOfficerDateCtrl: FormControl;
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;

  signaturePad: SignaturePad;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.signaturePad?.clear();
  }

  getSignatureDataUrl(): string {
    return this.signaturePad?.isEmpty() ? null : this.signaturePad.toDataURL();
  }

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { x, pageWidth, pageHeight, margin } = position;
    const maxWidth = pageWidth - 2 * margin;

    // Always start page 2 on a new page to avoid overlap with page 1
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    let currentPage = newPage;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.pageCount + 1);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const checkPageBreak = (requiredSpace: number = margin * 2) => {
      if (currentY < requiredSpace) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);

        if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
          formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
          formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
        }

        currentY = position.headerOffset || pageHeight - margin;

        return true;
      }
      return false;
    };

    // Draw centered heading
    const headingText = 'Applicant Information';
    const headingWidth = fonts.bold.widthOfTextAtSize(headingText, 16);
    const centerX = x + (maxWidth - headingWidth) / 2;

    currentPage.drawText(headingText, {
      x: centerX,
      y: currentY,
      size: 16,
      font: fonts.bold
    });
    currentY -= 30;

    // Applicant name
    const nameText = 'Applicant Name:';
    currentPage.drawText(nameText, {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.driverName || '', {
      x: x + fonts.regular.widthOfTextAtSize(nameText, 12) + 10,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for name
    currentPage.drawLine({
      start: { x: x + fonts.regular.widthOfTextAtSize(nameText, 12) + 5, y: currentY - 3 },
      end: { x: x + maxWidth / 2 - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Date of application (on same line as name)
    const dateText = 'Date of Application:';
    const dateX = x + maxWidth / 2;

    currentPage.drawText(dateText, {
      x: dateX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: dateX + fonts.regular.widthOfTextAtSize(dateText, 12) + 10,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x: dateX + fonts.regular.widthOfTextAtSize(dateText, 12) + 5, y: currentY - 3 },
      end: { x: x + maxWidth, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    currentY -= 30;

    // Draw paragraphs - these are the legal text paragraphs
    const paragraphs = [
      'In compliance with Federal and Provincial equal employment opportunity laws, qualified applicants are considered for all positions without regard to race, colour, religion, sex, national origin, age, marital status or the presence of a non-job-related medical condition or handicap.',

      'I authorize you to make such investigations and inquiries of my personal, employment, financial or medical history and other related matters as may be necessary in arriving at an employment decision. (Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended.) I hereby release employers, schools, health care providers and other persons from all liability in responding to inquiries and releasing information in connection with my application. In the event of employment, I understand that false or misleading information given in my application or interview(s) may result in termination. I understand, also, that I am required to abide by all rules and regulations of the Company.',

      'I understand that information I provide regarding current and/or previous employers may be used, and those employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49 CFR 391.23(d) and (e). I understand that I have the right to: Review information provided by previous employers;',

      'Have errors in the information corrected by previous employers and for those previous employers to re-send the corrected information to the prospective employer; and Have a rebuttal statement attached to the alleged erroneous information if the previous employer(s) and I cannot agree on the accuracy of the information.'
    ];

    // Draw each paragraph with proper wrapping and page break handling
    for (const paragraph of paragraphs) {
      // Split paragraph into words
      const words = paragraph.split(' ');
      let line = '';
      let lineX = x;

      for (const word of words) {
        const testLine = line + word + ' ';
        const testWidth = fonts.regular.widthOfTextAtSize(testLine, 12);

        if (lineX + testWidth > x + maxWidth) {
          currentPage.drawText(line, {
            x: lineX,
            y: currentY,
            size: 12,
            font: fonts.regular
          });
          currentY -= 16; // Line height

          // Check if we need a new page
          if (checkPageBreak()) {
            // We switched to a new page, reset line position
            lineX = x;
          } else {
            lineX = x;
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      // Draw remaining line
      if (line) {
        currentPage.drawText(line, {
          x: lineX,
          y: currentY,
          size: 12,
          font: fonts.regular
        });
        currentY -= 16;

        // Check for page break
        checkPageBreak();
      }

      // Add space between paragraphs
      currentY -= 10;

      // Check for page break after paragraph spacing
      checkPageBreak();
    }

    // Signature section
    currentY -= 10;

    // Check for page break before signature
    if (currentY < margin + 70) {
      // Need at least 70 points for signature
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Draw signature
    const signature = formValues.signatures?.get('applicantSignaturePage2');
    if (signature) {
      currentPage.drawImage(signature, {
        x,
        y: currentY - 50,
        width: 200,
        height: 50
      });
    }

    // Draw signature box if no signature
    currentPage.drawRectangle({
      x,
      y: currentY - 50,
      width: 200,
      height: 50,
      borderColor: rgb(0.7, 0.7, 0.7),
      borderWidth: 1,
      opacity: 0
    });

    currentY -= 70;

    // Check for page break
    checkPageBreak();

    // Draw date field
    currentPage.drawText('Date:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateOfApplication || '', {
      x: x + 50,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline for date
    currentPage.drawLine({
      start: { x: x + 45, y: currentY - 3 },
      end: { x: x + 200, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    currentY -= 40;

    // Check for page break before company use section
    if (currentY < margin + 100) {
      // Need at least 100 points for company section
      currentPage = pdfDoc.addPage([pageWidth, pageHeight]);
      currentY = pageHeight - margin;
    }

    // Company use section
    // Draw subheading
    currentPage.drawText('For Company Use', {
      x,
      y: currentY,
      size: 14,
      font: fonts.bold
    });
    currentY -= 25;

    // Company use form fields - first row
    const fieldWidth = maxWidth / 2 - 20;

    // Date Employed field
    currentPage.drawText('Date Employed:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateEmployed || '', {
      x: x + 100,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: x + 95, y: currentY - 3 },
      end: { x: x + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Reason Rejection field
    const secondColX = x + fieldWidth + 20;
    currentPage.drawText('Reason Rejection:', {
      x: secondColX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.reasonRejection || '', {
      x: secondColX + 120,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: secondColX + 115, y: currentY - 3 },
      end: { x: secondColX + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    currentY -= 25;

    // Check for page break
    checkPageBreak();

    // Second row - Date Termination and Reason Termination
    currentPage.drawText('Date Termination:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.dateTermination || '', {
      x: x + 115,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: x + 110, y: currentY - 3 },
      end: { x: x + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Reason Termination field
    currentPage.drawText('Reason Termination:', {
      x: secondColX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.reasonTermination || '', {
      x: secondColX + 130,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: secondColX + 125, y: currentY - 3 },
      end: { x: secondColX + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    currentY -= 25;

    // Check for page break
    checkPageBreak();

    // Third row - Safety Officer fields
    currentPage.drawText('Safety Officer Name:', {
      x,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.safetyOfficerName || '', {
      x: x + 135,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: x + 130, y: currentY - 3 },
      end: { x: x + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Safety Officer Date field
    currentPage.drawText('Safety Officer Date:', {
      x: secondColX,
      y: currentY,
      size: 12,
      font: fonts.regular
    });

    currentPage.drawText(formValues.safetyOfficerDate || '', {
      x: secondColX + 130,
      y: currentY,
      size: 12,
      font: fonts.bold
    });

    // Draw underline
    currentPage.drawLine({
      start: { x: secondColX + 125, y: currentY - 3 },
      end: { x: secondColX + fieldWidth - 10, y: currentY - 3 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    // Return result with newPage flag since we created a new page
    return {
      height: pageHeight - margin - currentY,
      newPage: true,
      position: {
        ...position,
        y: currentY
      }
    };
  }

  copySignatureFrom(dataUrl: string): void {
    if (!dataUrl || !this.signaturePad) return;

    // Clear the current signature
    this.signaturePad.clear();

    // Create a temporary image to get the source dimensions
    const img = new Image();
    img.onload = () => {
      // Get the canvas from this component
      const canvas = this.signatureCanvas.nativeElement;

      // Calculate aspect ratios
      const imgRatio = img.width / img.height;
      const canvasRatio = canvas.width / canvas.height;

      // Scale parameters for best fit while maintaining aspect ratio
      let width, height;
      if (imgRatio > canvasRatio) {
        // Image is wider than canvas (relatively)
        width = canvas.width;
        height = width / imgRatio;
      } else {
        // Image is taller than canvas (relatively)
        height = canvas.height;
        width = height * imgRatio;
      }

      // Center the signature in the canvas
      const offsetX = (canvas.width - width) / 2;
      const offsetY = (canvas.height - height) / 2;

      // Draw directly to the canvas
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, offsetX, offsetY, width, height);

      // Update the signature pad from the canvas content
      this.signaturePad.fromDataURL(canvas.toDataURL());
    };
    img.src = dataUrl;
  }
}

<h2>Inspections and Violations</h2>
<ul>
  <li>Drivers are to report immediately to Safety if they receive an "Out of Service" inspection.</li>
  <li>
    Documentation must be forwarded to the safety department within 24 hours of receiving an OOS (or upon request).
  </li>
  <li>
    Any driver who receives a violation/citation or a written warning while operating a commercial motor vehicle must
    notify the safety department within one (1) hour of receiving the violation/citation/warning.
  </li>
  <li>Submit a copy of the document to the Safety Department within 24 hours</li>
  <li>
    In the event a repair was needed, a copy of the repair invoice MUST accompany the inspection report before it can be
    forwarded to the State Patrol that issued the violation. Failure to do so could result in a license suspension in
    the issuing state.
  </li>
  <li>
    Submit a copy of any court ruling on the violation/citation/warning to the Safety Department within 24 hours of the
    court date.
  </li>
</ul>
<p>
  Clean Inspections Drivers who receive clean inspections (zero violations and no warnings) will be rewarded with an
  inspection bonus pending proof of inspection documentation.
</p>
<div class="form-group">
  <mat-form-field appearance="outline">
    <mat-label>Driver Name</mat-label>
    <input matInput [formControl]="driverNameCtrl" required />
  </mat-form-field>

  <div class="canvas-outline">
    <canvas #signatureCanvas width="360" height="100" [ngClass]="{ error: signaturePad?.isEmpty() }"></canvas>
    <button mat-raised-button type="button" (click)="clearSignature()">Clear</button>
  </div>
</div>
<p class="page-number">Page 20 of 30</p>

import { Component, OnInit } from '@angular/core';
import { rgb } from 'pdf-lib';
import { PDFCapableComponent, PDFPosition, PDFRenderResult, PDFHelpers, PDFFonts } from '../../pdf-interfaces';

@Component({
  selector: 'app-page4-cfr-info',
  templateUrl: './page4-cfr-info.component.html',
  styleUrls: ['../shared.scss', './page4-cfr-info.component.scss']
})
export class Page4CfrInfoComponent implements PDFCapableComponent {
  constructor() {}

  async renderToPDF(
    pdfDoc: any,
    page: any,
    position: PDFPosition,
    formValues: any,
    fonts: PDFFonts
  ): Promise<PDFRenderResult> {
    const { x, y, pageWidth, pageHeight, margin } = position;
    const maxWidth = pageWidth - 2 * margin;

    // Always start page 4 on a new page to avoid overlap with page 3
    const newPage = pdfDoc.addPage([pageWidth, pageHeight]);
    let currentPage = newPage;

    if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
      formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.pageCount + 1);
    }

    let currentY = position.headerOffset || pageHeight - margin;

    const checkPageBreak = (requiredSpace: number = margin * 2) => {
      if (currentY < requiredSpace) {
        currentPage = pdfDoc.addPage([pageWidth, pageHeight]);

        if (formValues.addHeaderToPage && typeof formValues.addHeaderToPage === 'function') {
          formValues.currentPageCount = (formValues.currentPageCount || formValues.pageCount) + 1;
          formValues.addHeaderToPage(currentPage, position, formValues, fonts, formValues.currentPageCount);
        }

        currentY = position.headerOffset || pageHeight - margin;

        return true;
      }
      return false;
    };

    // Function to draw wrapped text
    const drawWrappedText = (text: string, startX: number, startY: number, options: any = {}) => {
      const { fontSize = 12, font = fonts.regular, lineHeight = 16 } = options;
      const words = text.split(' ');
      let line = '';
      let lineX = startX;
      let currentYPos = startY;

      for (const word of words) {
        const testLine = line + word + ' ';
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);

        if (lineX + testWidth > startX + maxWidth) {
          currentPage.drawText(line, {
            x: lineX,
            y: currentYPos,
            size: fontSize,
            font
          });
          currentYPos -= lineHeight;

          // Check if we need a new page
          if (checkPageBreak()) {
            lineX = startX;
          } else {
            lineX = startX;
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      // Draw remaining line
      if (line) {
        currentPage.drawText(line, {
          x: lineX,
          y: currentYPos,
          size: fontSize,
          font
        });
        currentYPos -= lineHeight;
      }

      return currentYPos;
    };

    // Title - Centered
    const titleText = '49 CFR Part 382.413 and Part 40.25:';
    const titleWidth = fonts.bold.widthOfTextAtSize(titleText, 12);
    const centerX = x + (maxWidth - titleWidth) / 2;

    currentPage.drawText(titleText, {
      x: centerX,
      y: currentY,
      size: 12,
      font: fonts.bold
    });
    currentY -= 20;

    // Subtitle
    currentY = drawWrappedText(
      '§ 382.413 and 40.25 Must an employer check on the drug and alcohol testing record of employees it is intending to use to perform safety-sensitive duties',
      x,
      currentY
    );
    currentY -= 20;

    // Main list items
    const listItems = [
      "Yes, as an employer, you must, after obtaining an employee's written consent, request the information about the employee listed in paragraph (b) of this section. This requirement applies only to employees seeking to begin performing safety-sensitive duties for you for the first time (i.e., a new hire, an employee transfers into a safety sensitive position). If the employee refuses to provide this written consent, you must not permit the employee to perform safety-sensitive functions.",

      "You must request the information listed in this paragraph (b) from DOT-regulated employers who have employed the employee during any period during the two years before the date of the employee's application or transfer:",

      'The information obtained from a previous employer includes any drug or alcohol test information obtained from previous employers under this section or other applicable DOT agency regulations.',

      'If feasible, you must obtain and review this information before the employee first performs safetysensitive functions. If this is not feasible, you must obtain and review the information as soon as possible. However, you must not permit the employee to perform safety-sensitive functions after 30 days from the date on which the employee first performed safety-sensitive functions, unless you have obtained or made and documented a good faith effort to obtain this information.',

      'If you obtain information that the employee has violated a DOT agency drug and alcohol regulation, you must not use the employee to perform safety-sensitive functions unless you also obtain information that the employee has subsequently complied with the return-to-duty requirements of Subpart O of this part and DOT agency drug and alcohol regulations.',

      'You must provide to each of the employers from whom you request information under paragraph (b) of this section written consent for the release of the information cited in paragraph (a) of this section.',

      'The release of information under this section must be in any written form (e.g., fax, e-mail, letter) that ensures confidentiality. As the previous employer, you must maintain a written record of the information released, including the date, the party to whom it was released, and a summary of the information provided.'
    ];

    // Sublists
    const subListItems = [
      'Alcohol tests with a result of 0.04 or higher alcohol concentration.',
      'Verified positive drug tests.',
      'Refusals to be tested (including verified adulterated or substituted drug test results);',
      'Other violations of DOT agency drug and alcohol testing regulations; and',
      "With respect to any employee who violated a DOT drug and alcohol regulation, documentation of the employee's successful completion of DOT return-to-duty requirements (including follow-up tests). If the previous employer does not have information about the return-do-duty process (e.g., an employer who did not hire an employee who tested positive on a pre-employment test), you must seek to obtain this information from the employee."
    ];

    // Draw each list item with proper indentation and bullets
    for (let i = 0; i < listItems.length; i++) {
      // Draw bullet
      currentPage.drawText('•', {
        x: x,
        y: currentY,
        size: 12,
        font: fonts.regular
      });

      // Draw list item text
      const listItemX = x + 15;
      const itemText = listItems[i];

      currentY = drawWrappedText(itemText, listItemX, currentY);

      // Add sublists after the second item
      if (i === 1) {
        for (let j = 0; j < subListItems.length; j++) {
          currentY -= 5; // Extra space before sublist item

          // Draw number
          currentPage.drawText(`${j + 1}.`, {
            x: listItemX,
            y: currentY,
            size: 12,
            font: fonts.regular
          });

          // Draw sublist item text with indentation
          const subItemX = listItemX + 15;
          const subItemText = subListItems[j];

          currentY = drawWrappedText(subItemText, subItemX, currentY);
        }
      }

      currentY -= 10; // Space between list items

      // Check for page break between items
      checkPageBreak();
    }

    // Return result with newPage flag since we created a new page
    return {
      height: y - currentY,
      newPage: true,
      position: {
        ...position,
        y: currentY
      }
    };
  }
}
